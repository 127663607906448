<template>
    <div>
        <form @submit.prevent="submit" id="po-form" name="po-form" :ref="'po-form'">
            <div class="row col-md-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title"><p>Picking List</p></div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="table-responsive pt-3">
                                <div>
                                    <table class="table table-bordered table-hover date-columns" id="new-projects">
                                        <thead>
                                        <tr>
                                            <th style="width: 10%;">
                                                Product ID
                                            </th>
                                            <th>
                                                Product
                                            </th>
                                            <th>
                                                Description
                                            </th>
                                            <th>
                                                Zone
                                            </th>
                                            <th>
                                                Location
                                            </th>
                                            <th style="width: 10%;">
                                                Amount
                                            </th>
                                            <th>
                                                Quantity
                                            </th>
                                            <th>
                                                Comments
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(product,index) in form.products">
                                            <td style="width: 10%;">
                                                {{product.sku}}
                                            </td>
                                            <td style="width: 15%">
                                                {{product.name}}
                                            </td>
                                            <td style="width: 15%">
                                                {{product.description}}
                                            </td>
                                            <td style="width: 10%">
                                                {{product.pivot.zones}}
                                            </td>
                                            <td style="width: 10%">
                                                {{product.pivot.locations}}
                                            </td>
                                            <td style="width: 10%;">
                                                {{product.pivot.quantity}}
                                            </td>
                                            <td style="width: 10%;">
                                                <input type="number" oninput="validity.valid||(value='')" min="0" class="form-control" name="quantity"  placeholder="####" v-model="product.quantity">
                                            </td>
                                            <td style="width: 10%;">
                                                <input type="text" class="form-control" name="comment" placeholder="Comments" v-model="product.comments">
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-5" v-if="form.products.filter(product => product.use_serials).length > 0">
                            <h6>Serial Numbers</h6>
                        </div>
                        <div class="row mt-2" v-for="(product,index) in form.products.filter(product => product.use_serials)">
                            <p class="col-md-12">{{product.name}}</p>
                            <div class="col-md-12">
                                <p type="button" style="color: #007bff" @click="$modal.show(index+'-serial-codes-modal') ; $forceUpdate()">Add or Edit Codes</p>
                                <serial-codes-modal :ref="index+'serial-code-modal'" :product="product" :index="index" v-bind:quantity="product.quantity"></serial-codes-modal>
                            </div>
                        </div>

                        <div class="spinner-border text-primary my-3" v-if="submit_disabled" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="row mt-4">
                            <button :disabled="submit_disabled" type="submit" class="btn btn-primary mr-2">Submit</button>
                            <a href="/projects/picking" class="btn btn-light">Cancel</a>
                        </div>
                    </div>
                </div>
            </div>

        </form>
    </div>
</template>

<script>
    import Swal from "sweetalert2";

    export default {
        name: "ProjectPickingComponent",
        props: ['picking'],
        beforeMount(){
            console.log(this.$props.picking)
            this.form.products = this.$props.picking.project.bom.products
                .filter(product => product.pivot.warehouse_id == this.$props.picking.warehouse_id);

            this.form.products.forEach(product =>{
                product.quantity = product.pivot.quantity
                product.serial_codes = [];
                if(product.use_serials){
                    product['serial_codes'] = [];
                    product['qty_received'] = product.pivot.quantity;
                    product.quantity = product.pivot.quantity;
                }
                // this.check_serials(product.id)
            })
        },
        data(){
            return{
                form:{
                    products: []
                },
                submit_disabled: false
            }
        },
        methods:{
            submit(){
                let haveQuantity = true;
                let diffQuantityComment = false;
                let products = [];
                let serials_success = true;
                let bad_serials_list = '';

                this.submit_disabled = true;


                this.form.products.forEach(product => {
                    if(product.quantity == null || product.quantity == ""){
                        haveQuantity = false;
                    }
                    if((product.quantity != product.pivot.quantity) && (product.comments == null || product.comments == "")){
                        products.push(product.sku)
                        diffQuantityComment = true;
                    }
                    if(product.use_serials){
                        if(product.quantity != product.serial_codes.length){
                            serials_success = false;
                            bad_serials_list += '(' + product.name + ') ';
                        }
                    }
                })

                if(!haveQuantity){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Input the quantity for all the products.',
                    });
                    this.submit_disabled = false;

                } else if(diffQuantityComment){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: "Please provide comment for difference in Qty. Picked for there products: " + products.toString(),
                    });
                    this.submit_disabled = false;

                }else if(!serials_success){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Please scan products with Serial Numbers to complete Picking.' + bad_serials_list,
                    });
                    this.submit_disabled = false;

                }else{
                    axios.post('/projects/'+this.$props.picking.project.id+'/pickings/'+this.$props.picking.id,this.form).then(response => {
                        if(response.data.error == 404){
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: response.data.message,
                            });
                            this.submit_disabled = false;
                        }else{
                            location = response.data.message;
                        }
                    }).catch(error =>{
                        if(error.response.status === 403){
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'There was a problem.',
                            });
                        }
                        this.submit_disabled = false;
                    });
                }
            },
            check_serials(product_id){
                axios.get('/products/'+product_id+'/serials/').then(response => {
                    let product = this.form.products.find(product=> product.id == product_id)
                    product.use_serials = response.data.value;
                    if(product.use_serials){
                        product['serial_codes'] = [];
                        product['qty_received'] = product.pivot.quantity;
                        product.quantity = product.pivot.quantity;
                    }
                }).catch(error =>{
                    this.form.products.find(product=> product.id == product_id).use_serials = false

                });
            }
        }
    }
</script>

<style scoped>

</style>
