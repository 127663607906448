<template>
    <modal name="count-edit-modal"  height="auto" width="60%" :scrollable="true">
        <div class="container modal-xl modal-content border-bottom-0 h-100 w-100" role="document">
            <div class="modal-header">
                <div>
                    <div class="row justify-content-between">
                        <div class="modal-title">Edit</div>
                    </div>
                </div>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12 overflow-auto">
                        <table class="table table-bordered table-hover">
                            <thead>
                            <tr>
                                <th>Location</th>
                                <th>LPN</th>
                                <th>SKU</th>
                                <th style="min-width: 100px">UPC</th>
                                <th>Description</th>
                                <th style="min-width: 100px">Count</th>
                                <th style="min-width: 100px">UOM</th>
                                <th>User</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>{{$props.count.level_label}}</td>
                                <td>{{$props.count.lpn}}</td>
                                <td>{{$props.count.sku}}</td>
                                <td style="min-width: 100px">
                                    <input type="text"
                                           class="form-control"
                                           v-model="form.upc"
                                           placeholder="UPC"/>
                                </td>
                                <td>{{$props.count.description}}</td>
                                <td style="min-width: 100px">
                                    <input type="number"
                                           min="0"
                                           class="form-control"
                                           v-model="form.count"
                                           placeholder="Count"/>
                                </td>
                                <td style="min-width: 100px">
                                    <div>
                                        <dropbox :options="$props.count.count_data.measurement_options"
                                                 v-model="form.measurement_unit_id">
                                            <option></option>
                                        </dropbox>
                                    </div>
                                </td>
                                <td>{{$props.count.count_data.user.email}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="col-12 d-flex w-full justify-content-between">
                    <div>
                        <button type="button" class="btn btn-light" @click="$modal.hide('count-edit-modal')">Close</button>
                        <button type="button" class="btn btn-primary" v-on:click="submit">Submit</button>
                    </div>
<!--                    <div>-->
<!--                        <button type="button" class="btn btn-danger" v-on:click="deleteCount">Delete</button>-->
<!--                    </div>-->
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Swal from "sweetalert2";

export default {
    name: "InventoryCountTableEditModal",
    props: {
        count: Object,
        inventory_count: Object
    },
    watch:{
        count: {
            handler(newCount){
                this.form.upc = newCount.count_data.upc;
                this.form.count = newCount.count_data.count;
                this.form.measurement_unit_id = newCount.count_data.measurement_unit_id
            },
            deep: true
        }
    },
    data(){
        return {
            form:{
                upc: this.$props.count.count_data.upc,
                count: this.$props.count.count_data.count,
                measurement_unit_id: this.$props.count.count_data.measurement_unit_id
            }
        }
    },
    methods: {
        submit(){
            const data = this.form;

            let message = [];

            if(data['upc'] != null && data['upc'].length > 16){
                message.push('UPC length maximum is 16 characters');
            }

            if(data['count'] === '' || data['count'] == null) {
                message.push('Count is required');
            }

            if(data['count'] < 0){
                message.push('Quantity can not be a negative value');
            }

            if(data['measurement_unit_id'] === '' || data['measurement_unit_id'] == null){
                message.push('Measurement Unit field is required');
            }

            console.log(message.join());
            if(message.length !== 0){
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Line cannot be saved because: ' + message.join(' and '),
                });

                return;
            }


            axios.patch(
                '/inventory_count/' + this.$props.inventory_count.id + '/counts/' + this.$props.count.count_data.id,
                data
            ).then(response => {
                this.$props.count.count_data.upc = this.form.upc;
                this.$props.count.count_data.count = this.form.count;
                this.$props.count.count_data.measurement_unit_id = this.form.measurement_unit_id;

                this.$modal.hide('count-edit-modal');
            }).catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error.message,
                });
            });
        },
        deleteCount(){
            axios.delete('/inventory_count/' + this.$props.inventory_count.id + '/counts/' + this.$props.count.count_data.id)
                .then(response =>{
                    this.$emit('deleteCount', this.$props.count);
                    this.$modal.hide('count-edit-modal');
                })
                .catch(error => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.message
                    })
                })
        }
    }
}
</script>

<style scoped>

</style>
