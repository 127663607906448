<template>
    <div>
        <form @submit.prevent="submit" id="product-checkin-form" name="product-checkin-form" :ref="'product-checkin-form'">
            <div class="col-md-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h6 class="card-title">Damaged Returns Details</h6>

                        <div class="mb-4 row">
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-4">
                                        Product ID:
                                    </div>
                                    <div class="col-md-6">
                                        {{product.sku}}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        Product:
                                    </div>
                                    <div class="col-md-6">
                                        {{product.name}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="table-responsive pt-3">
                            <table class="table table-bordered table-hover data-table">
                                <thead>
                                <tr>
                                    <th>
                                        Add to list
                                    </th>
                                    <th>
                                        Project Number
                                    </th>
                                    <th>
                                        Serial Numbers
                                    </th>
                                    <th>
                                        Deal Name
                                    </th>
                                    <th>
                                        Finance Company
                                    </th>
                                    <th>
                                        Returnted Qty
                                    </th>
                                    <th>
                                        Return Reason
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(item,index) in this.product_out">
                                    <td>
                                        <button type="button" class="btn btn-primary btn-icon "  v-on:click="addModal(item.id,index)">
                                            <log-out-icon size="1.5x" style="color: white"></log-out-icon>
                                        </button>
                                    </td>
                                    <td>
                                        {{item.project.project_number}}
                                    </td>
                                    <td>
                                        {{item.serial_records.map(sr => sr['serial_number']).join()}}
                                    </td>
                                    <td>
                                        {{item.project.client_name}}
                                    </td>
                                    <td>
                                        {{item.project.financing_source_name}}
                                    </td>
                                    <td>
                                        {{item.returned_quantity}}
                                    </td>
                                    <td>
                                        {{item.reason}}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <br>
                        <h6 class="card-subtitle mb-2 text-muted">Products to Write Off</h6>
                        <div class="table-responsive pt-3">
                            <table class="table table-bordered table-hover">
                                <thead>
                                <tr>
                                    <th>
                                        Remove from list
                                    </th>
                                    <th>
                                        Project Number
                                    </th>
                                    <th>
                                        Serial Numbers
                                    </th>
                                    <th>
                                        Deal Name
                                    </th>
                                    <th>
                                        Finance Company
                                    </th>
                                    <th>
                                        Returned Qty
                                    </th>
                                    <th>
                                        Write Off Reason
                                    </th>
                                    <th>
                                        Write-off Date
                                    </th>
                                    <th>
                                        Comment
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(item,index) in product_in">
                                    <td>
                                        <button type="button" class="btn btn-primary btn-icon "  v-on:click="removeFromIn(item.id,index)">
                                            <log-in-icon size="1.5x" style="color: white"></log-in-icon>
                                        </button>
                                    </td>
                                    <td>
                                        {{item.project.project_number}}
                                    </td>
                                    <td>
                                        {{item.serial_records.map(sr => sr['serial_number']).join()}}
                                    </td>
                                    <td>
                                        {{item.project.client_name}}
                                    </td>
                                    <td>
                                        {{item.project.financing_source_name}}
                                    </td>
                                    <td>
                                        {{item.returned_quantity}}
                                    </td>
                                    <td>
                                        {{reasons.find(r => r.id = item.write_off_reason).name}}
                                    </td>
                                    <td>
                                        {{form.writeoff_date}}
                                    </td>
                                    <td>
                                        {{item.comment}}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="mt-5">
                            <button type="submit" class="btn btn-primary mr-2">Submit</button>
                            <a href="/accounting/products/damaged" class="btn btn-light">Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    import Swal from "sweetalert2";
    import 'jquery-validation';
    import {LogInIcon, LogOutIcon} from 'vue-feather-icons';
    import datePicker from 'vue-bootstrap-datetimepicker';
    import moment from "moment";

    export default {
        name: "DamagedProductComponent",
        props: ['product','inventory','reasons'],
        components:{
            LogOutIcon, LogInIcon,
            datePicker
        },
        data(){
            return{
                product_in: [],
                product_out:this.$props.inventory,
                form:{
                    products:[],
                    writeoff_date: moment().format('DD/MM/YYYY')
                },
                datePickerOptions:{
                    format: 'DD/MM/YYYY'
                },
                moment: moment
            }
        },
        mounted() {
            $("form[name='product-checkin-form']").validate({
                rules: {
                    check_in_date: {
                        required: true,
                    },
                },
                errorPlacement: function(label, element) {
                    if(['check_in_date'].includes(element[0].name)){
                        label.addClass('mt-2 text-danger');
                        label.insertAfter(element.next());
                    }else{
                        label.addClass('mt-2 text-danger');
                        label.insertAfter(element);
                    }
                },
                highlight: function(element, errorClass) {
                    $(element).parent().addClass('has-danger')
                    $(element).addClass('form-control-danger')
                },
                unhighlight: function(element, errorClass, validClass) {
                    $(element).parent().removeClass('has-danger')
                    $(element).removeClass('form-control-danger')
                },
                submitHandler: function(form){
                    this.submit()
                }
            });
        },
        methods:{
            submit(){
                this.form.products = this.product_in;
                let attributes = this.form;

                axios.post('/accounting/products/'+this.$props.product.id+'/damaged',attributes).then(response => {
                    location = response.data.message;
                });
            },
            addModal(id,index){
                let write_off_reason = '';
                let comment = '';
                let reasons_options = '';

                this.$props.reasons.forEach(reason => {
                    reasons_options += '<option value="'+reason.id+'">'+reason.name+'</option>'
                });

                Swal.fire({
                    title: 'Product to Write Off',
                    html:

                        '<label for="write_off_reason">Write-Off Reason<span class="required_asterisk">*</span></label>'+
                        '<select id="write_off_reason" class="swal2-input">'+
                        reasons_options+
                        '</select>'+
                        '<label for="comment">Comment<span class="required_asterisk">*</span></label>'+
                        '<input id="comment" class="swal2-input">'
                    ,
                    focusConfirm: false,
                    preConfirm: () => {
                        write_off_reason = document.getElementById('write_off_reason').value;
                        comment = document.getElementById('comment').value;

                        this.addToIn(id,index,write_off_reason,comment)
                    }
                });
            },
            addToIn(id,index,write_off_reason,comment){
                let productToIn = this.product_out.find(product=> product.id == id);

                productToIn.write_off_reason = write_off_reason;
                productToIn.comment = comment
                this.product_out.splice(index,1);

                this.product_in.push(productToIn);
            },
            removeFromIn(id,index){
                let productToOut = this.product_in.find(product=> product.id == id);

                this.product_in.splice(index,1);
                productToOut.return_reason = ''
                productToOut.comment = ''
                this.product_out.push(productToOut);
            },
        }
    }
</script>

<style scoped>

</style>
