<template>
    <modal :name="index+'-return-modal'" height="auto" :scrollable="true">
        <div class="modal-content border-bottom-0 h-100">
            <div class="modal-header">
                <div>
                    <div class="row justify-content-between">
                        <h5 class="modal-title">Product to Return</h5>
                    </div>
                </div>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label for="returned_qty">Returned Qty.<span class="required_asterisk">*</span></label>
                    <input type="number" oninput="validity.valid||(value=\'\')" min="1" :max="this.product.pivot.quantity_picked" class="form-control" name="returned_qty" placeholder="##" v-model="returned_qty" @change="change_qty()">
                </div>
                <div class="form-group">
                    <label for="warehouse">Warehouse to Return<span class="required_asterisk">*</span></label>
                    <dropbox :options="this.product.warehouses" v-model="warehouse_id">
                        <option></option>
                    </dropbox>
                </div>
                <div class="form-group">
                    <label for="quality_check">Quality Check<span class="required_asterisk">*</span></label>
                    <dropbox name="quality_check" id="quality_check" :options="this.$props.qualities" v-model="quality_check_id">
                        <option></option>
                    </dropbox>
                </div>
                <div class="form-group">
                    <label for="return_reason">Return Reason<span class="required_asterisk">*</span></label>
                    <input type="text" class="form-control" name="return_reason" placeholder="Return Reason" v-model="return_reason">
                </div>
                <div v-if="dispatched_record.length > 0" class="form-group">
                    <label for="serial_numbers">Serial Number<span class="required_asterisk">*</span></label>
                    <select-record name="serial_numbers" id="serial_numbers" :options="this.$props.dispatched_record" v-model="product.serial_numbers"></select-record>
                </div>
                <div class="form-group">
                    <label for="replacement">Replacement?<span class="required_asterisk">*</span></label>
                    <dropbox name="replacement" id="replacement" :options="this.replacement_options" v-model="product.replacement"></dropbox>
                </div>
                <div class="form-group">
                        <label for="returned_qty">Qty.<span class="required_asterisk">*</span></label>
                        <input type="number" disabled min="1" :max="this.product.pivot.quantity_picked" class="form-control" name="returned_qty" placeholder="##" v-model="returned_qty">
                </div>
                <div class="form-group">
                    <label for="serial_numbers">Serial Numbers</label>
                    <p type="button" style="color: #007bff" @click="$modal.show(index+'-serial-codes-modal')">Add or Edit Codes</p>
                </div>
                <serial-codes-modal :product="product" :index="index" :quantity="returned_qty"></serial-codes-modal>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light" @click="$modal.hide(index+'-return-modal')">Close</button>
                <button type="button" class="btn btn-primary mr-2" @click="addToIn()">Submit</button>
            </div>
        </div>
    </modal>
</template>

<script>
    import Swal from "sweetalert2";

    export default {
        name: "ProjectReturnModal",
        props: ['index','qualities','product_returns','dispatched_inventory','dispatched_record','product'],
        mounted() {
            this.$props.product['serial_numbers'] = []
        },
        data(){
            return{
                quality_check_id: '',
                return_reason: '',
                returned_qty: '',
                serial_number: '',
                warehouse_id: '',
                replacement_options: [
                    {
                        'id':'yes', 'name':'Yes'
                    },
                    {
                        'id':'no', 'name':'No'
                    },
                ]
            }
        },
        methods:{
            addToIn(){

                let serials_success = true;

                if(this.$props.dispatched_record.length > 0 && this.$props.product.serial_numbers.length != this.returned_qty){
                    serials_success = false;
                }

                if(this.returned_qty > this.$props.product.pivot.quantity_picked){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'You cannot return more than what was dispatched, please try again.',
                    });
                }
                else if(this.returned_qty == 0){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'You cannot return 0, try again.',
                    });
                }
                else if(!serials_success){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Returned Qty. and serial numbers selected does not match.',
                    });
                }
                else {
                    let toolToIn = this.$props.product;

                    toolToIn.return_reason = this.return_reason;
                    toolToIn.quality_check = this.quality_check_id;
                    toolToIn.returned_qty = this.returned_qty;
                    toolToIn.serial_number = this.serial_number;
                    toolToIn.warehouse_id = this.warehouse_id;
                    this.$props.dispatched_inventory.splice(this.$props.index,1);

                    this.$props.product_returns.push(toolToIn);

                    this.$modal.hide(this.$props.index+'-return-modal')
                }
            },
            change_qty(){

                this.$props.product['qty_received'] = this.returned_qty;
                this.$forceUpdate();
            }
        }
    }
</script>

<style scoped>

</style>

