<template>
    <modal :name="'dispatch-products-modal'" height="auto" :scrollable="true">
        <div class="modal-content border-bottom-0 h-100">
            <div class="modal-header">
                <div>
                    <div class="row justify-content-between">
                        <h5 class="modal-title">Product to Dispatch</h5>
                    </div>
                </div>
            </div>
            <div class="modal-body">
                <div class="mb-3">
                    <label for="quantity">Product</label>
                    <dropbox name="products" id="products" :options="this.products_bom" v-model="form.product.product_id" v-on:change="$forceUpdate()">
                        <option></option>
                    </dropbox>
                </div>
                <div class="form-group">
                    <label for="quantity">Quantity</label>
                    <input type="number" oninput="validity.valid||(value='')" min="0" class="form-control" name="quantity"  placeholder="####" v-model="form.product.quantity">
                </div>
                <div class="form-group">
                    <label for="comments">Comments</label>
                    <input type="text" class="form-control" name="comments"  placeholder="####" v-model="form.product.comments">
                </div>
                <div class="col-md-12" v-if="this.products_bom.find(product => product.id == form.product.product_id) != null && this.products_bom.find(product => product.id == form.product.product_id).use_serials">
                    <label>Serial Numbers</label>
                    <p type="button" style="color: #007bff" @click="$modal.show(1+'-serial-codes-modal') ; $forceUpdate()">Add or Edit Codes</p>
                    <serial-codes-modal  :product="form.product" :index="1" v-bind:quantity="form.product.quantity"></serial-codes-modal>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light" @click="$modal.hide('dispatch-products-modal')">Close</button>
                <button :disabled="submit_disabled" type="button" class="btn btn-primary mr-2" v-on:click="submit">Dispatch</button>
            </div>
        </div>
    </modal>
</template>

<script>
    import Swal from "sweetalert2";

    export default {
        name: "DispatchProductsModal",
        props: {
            products: Object,
            project: Object,
            picking: Object
        },
        mounted() {
        },
        data(){
            return{
                products_bom: Object.values(this.$props.products),
                form:{
                    product:{
                        product_id:'',
                        serial_codes: [],
                        comments: '',
                        quantity:''
                    },
                },
                submit_disabled : false

            }
        },
        methods: {
            submit(){
                let haveQuantity = true;
                let diffQuantityComment = false;
                let serials_success = true;
                let bad_serials_list = '';
                let product = this.products_bom.find(product => product.id == this.form.product.product_id)

                this.submit_disabled = true;

                if(this.form.product.quantity == null || this.form.product.quantity == ""){
                    haveQuantity = false;
                }
                if(product != null && product.user_serials){
                    if(this.form.product.quantity != this.form.product.serial_codes.length){
                        serials_success = false;
                    }
                }

                if(!haveQuantity){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Input the quantity for all the products.',
                    });
                    this.submit_disabled = false;
                }else if(!serials_success){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Please scan products with Serial Numbers to complete Picking.' + bad_serials_list,
                    });
                    this.submit_disabled = false;
                }else{
                    axios.post('/projects/'+this.$props.project.id+'/pickings/'+this.$props.picking.id +'/dispatch',this.form).then(response =>{
                        if(response.data.error == 404){
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: response.data.message,
                            });
                            this.submit_disabled = false;
                        }else{
                            location = response.data.message;
                        }
                    }).catch(error =>{
                        if(error.response.status === 403){
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'There was a problem.',
                            });
                        }
                        this.submit_disabled = false;
                    });
                }
            }
        }
    }
</script>

<style scoped>

</style>

