<template>
    <div>
        <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
                <div class="card-body">
                    <h6 class="card-title">Edit BOM</h6>
                    <form @submit.prevent="submit">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="bom_identifier">BOM ID<span class="required_asterisk">*</span></label>
                                    <input type="text" class="form-control" name="bom_identifier" id="bom_identifier" placeholder="Bom ID" v-model="form.bom_identifier">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="battery_type">Battery Type<span class="required_asterisk">*</span></label>
                                    <dropbox name="battery_type" id="battery_type" :options="this.$props.battery_types" v-model="form.battery_type_id">
                                        <option></option>
                                    </dropbox>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="system_size">System Size<span class="required_asterisk">*</span></label>
                                    <input type="text" class="form-control" name="system_size" id="system_size" placeholder="####" v-model="form.system_size">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="battery_qty">Battery Qty<span class="required_asterisk">*</span></label>
                                    <input type="number" oninput="validity.valid||(value='')" min="0" :disabled="'disabled'" class="form-control" name="battery_qty" id="battery_qty" placeholder="" v-model="form.battery_qty">
                                </div>
                            </div>
                        </div>
                        <div class="row">
<!--                            <div class="col-sm-6">-->
<!--                                <div class="form-group">-->
<!--                                    <label for="wh_product">Product Sold <span class="required_asterisk">*</span></label>-->
<!--                                    <dropbox name="wh_product" id="wh_product" :options="this.$props.wh_products" v-model="form.wh_product_id">-->
<!--                                        <option></option>-->
<!--                                    </dropbox>-->
<!--                                </div>-->
<!--                            </div>-->
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="description">Description</label>
                                    <input type="text" class="form-control" name="description" id="description" placeholder="" v-model="form.comment">
                                </div>
                            </div>
                        </div>
                        <br>

                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="category">Category<span class="required_asterisk">*</span></label>
                                    <dropbox name="category" id="category" :options="this.$props.categories" v-model="category_id"  @change="loadProducts()">
                                        <option></option>
                                    </dropbox>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="category">Product<span class="required_asterisk">*</span></label>
                                    <dropbox name="product" id="product" :options="this.product_options" v-model="product_id"  @change="loadProducts()">
                                        <option></option>
                                    </dropbox>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="quantity">Quantity</label>
                                    <input type="number" oninput="validity.valid||(value='')" min="0" class="form-control" name="quantity" id="quantity" placeholder="" v-model="quantity">
                                </div>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col-md-4">
                                <button type="button" class="btn btn-primary mr-2" v-on:click="addProduct">Add Product</button>
                            </div>
                        </div>
                        <div class="row col-md-12 mb-4">
                            <table class="table table-bordered table-hover date-columns" id="product-table">
                                <thead>
                                <tr>
                                    <th>
                                        Category
                                    </th>
                                    <th>
                                        Warehouse
                                    </th>
                                    <th>
                                        Product ID
                                    </th>
                                    <th>
                                        Product
                                    </th>
                                    <th>
                                        Description
                                    </th>
                                    <th>
                                        Amount
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <template v-for="(category,index_category) in categories">
                                    <tr>
                                        <td>{{category.name}}</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr v-for="(product,index_product) in form.products.filter(item => item.category.id == category.id)">
                                        <td>
                                            <div class="row justify-content-center">
                                                <div style="cursor: pointer" class="mr-2" v-on:click="editProduct(product.id)">
                                                    <edit-icon size="1.5x"></edit-icon>
                                                </div>
                                                <div style="cursor: pointer" v-on:click="deleteProduct(product)">
                                                    <trash2-icon size="1.5x"></trash2-icon>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div v-for="(warehouse,index) in product.warehouses">
                                                <p v-if="warehouse === product.warehouses[product.warehouses.length-1]">{{warehouse.name}}</p>
                                                <p v-else>{{warehouse.name + ','}}</p>
                                            </div>
                                        </td>
                                        <td>{{product.sku}}</td>
                                        <td>{{product.name}}</td>
                                        <td>{{product.description}}</td>
                                        <td>{{product.pivot.quantity}}</td>
                                    </tr>
                                </template>
                                </tbody>
                            </table>
                        </div>

                        <button type="submit" class="btn btn-primary mr-2">Submit</button>
                        <a href="/boms" class="btn btn-light">Cancel</a>
                    </form>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import Swal from "sweetalert2";
    import { EditIcon, Trash2Icon} from 'vue-feather-icons';

    export default {
        name: "BomUpdateForm",
        props: ['products','categories','bom','battery_types'],
        components:{
            EditIcon, Trash2Icon
        },
        mounted(){
            this.countBatteries();
        },
        data(){
            return {
                form:{
                    bom_identifier: this.$props.bom.bom_identifier,
                    system_size: this.$props.bom.system_size,
                    battery_type: this.$props.battery_types.find(bat => bat.id === this.$props.bom.battery_type.id).name,
                    battery_type_id: this.$props.battery_types.find(bat => bat.id === this.$props.bom.battery_type.id).id,
                    comment: this.$props.bom.comment,
                    products: this.$props.bom.products,
                    battery_qty: this.$props.bom.battery_qty
                },
                battery:this.$props.battery_types.find(bat => bat.id === this.$props.bom.battery_type.id).id,
                product_options:[],
                category_id: "",
                product_id: "",
                quantity: ""
            }
        },
        methods:{
            submit(){
                let battery_object = this.$props.battery_types.find(battery_type => battery_type.id == this.battery);
                this.form.battery_type = battery_object.name;

                axios.patch("/boms/" + this.$props.bom.id,this.form).then(response =>{
                    location = response.data.message;
                })
            },
            loadProducts(){
                if(this.category_id !== ""){
                    this.product_options = this.$props.categories.find(category => category.id == this.category_id).products
                }
            },
            addProduct(){


                let sameProduct = this.form.products.find(product => product.id == this.product_id);
                if(sameProduct == null){
                    if(this.product_id === ""){
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Please select a product.',
                        });

                    }else if(this.quantity == null || this.quantity === ''){
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Please enter a order quantity greater than 0.',
                        });
                    }else{
                        let vm = this;
                        let product = this.$props.products.find(product => product.id == this.product_id);
                        product['pivot'] = {};
                        product.pivot['quantity'] = this.quantity;

                        if(product.category.name === "Battery"){
                            if(this.form.battery_qty != "" && this.form.battery_qty != null){
                                console.log(this.form.battery_qty)
                                this.form.battery_qty = parseInt(this.form.battery_qty,10)
                                this.form.battery_qty += parseInt(this.quantity,10);
                            }else{
                                this.form.battery_qty = parseInt(this.quantity);
                            }
                        }

                        this.form.products.push(product)
                        this.category_id = "";
                        this.product_id = "";
                        this.quantity = "";
                    }
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Product already added. You can edit it in the table'
                    })
                }


            },
            editProduct(id){
                let component = this;
                let product_edit = this.form.products.find(product => product.id == id);

                Swal.fire({
                    title: product_edit.name,
                    html:
                        '<label for="product_quantity">Order Qty<span class="required_asterisk">*</span></label>'+
                        '<input type="number" oninput="validity.valid||(value=\'\')" min="0" id="product_quantity" class="swal2-input" value="' + product_edit.pivot.quantity + '">',
                    focusConfirm: false,
                    preConfirm() {
                        component.form.battery_qty = parseInt(component.form.battery_qty,10);
                        component.form.battery_qty -= parseInt(product_edit.pivot.quantity,10);
                        product_edit.pivot.quantity = document.getElementById('product_quantity').value;
                        component.form.battery_qty += parseInt(product_edit.pivot.quantity,10);
                    }
                })
            },
            deleteProduct(product){
                console.log(product);
                if(product.category.name === "Battery"){
                    this.form.battery_qty = parseInt(this.form.battery_qty,10);
                    this.form.battery_qty -= parseInt(product.pivot.quantity,10);
                }
                this.form.products.splice(this.form.products.indexOf(product),1);
            },
            countBatteries(){
                this.form.battery_qty = 0;
                this.form.products.forEach(pt => {
                    if(pt.category.name === "Battery"){
                        this.form.battery_qty += parseInt(pt.pivot['quantity'],10);
                    }
                })

            }
        }
    }
</script>

<style scoped>

</style>
