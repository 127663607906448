<template>
    <div>
        <div class="row">
            <div class="col-md-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <h6 class="card-title">Distributions</h6>
                            <div class="ml-5 row">
                                <div class="form-group">
                                    <input type="radio" id="distribution" value="distribution" v-model="form.distribution_type"/>
                                    <label for="distribution">Distribution</label>
                                </div>
                                <div class="form-group ml-2">
                                    <input type="radio" id="redistribution" value="redistribution" v-model="form.distribution_type"/>
                                    <label for="redistribution">Re-Distribution</label>
                                </div>
                            </div>
                        </div>
                        <div class="">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="row mb-2">
                                        <div class="col-md-8">
                                            <dropbox name="purchase_order" @optionsCallback="purchaseOrderCallback" :options="purchase_orders_options" v-model="selected_po_id" v-on:change="loadPO" :disabled="form.distribution_type == 'Re-Distribution'">
                                                <option></option>
                                            </dropbox>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="d-flex">
                                                <button type="button" class="btn btn-primary mr-2 align-self-center" v-on:click="openPoModal">Search PO</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <p class="align-self-center">Suppliers: {{selected_suppliers.toString()}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="row mb-2">
                                        <div class="col-md-8">
                                            <dropbox name="containers" @optionsCallback="containerCallback" :options="container_options" v-model="selected_container_id" v-on:change="loadContainer" >
                                                <option></option>
                                            </dropbox>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="d-flex">
                                                <button type="button" class="btn btn-primary mr-2 align-self-center" v-on:click="openContainerModal">Search Container</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <p class="align-self-center">Container: {{selected_container != null ? selected_container.container : ''}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-5">
                                    <div class="col-md-12 justify-content-end row">
                                        <div class="d-flex">
                                            <label class="align-self-center" for="searchText">Search Product:</label>
                                        </div>
                                        <div class="form-group d-flex mb-0 px-2">
                                            <input type="text" class="form-control align-self-center" name="searchText"  placeholder="Search Text" v-model="searchText">
                                        </div>
                                        <div class="d-flex">
                                            <label class="align-self-center" for="supplier">Supplier:</label>
                                        </div>
                                        <div class="form-group d-flex mb-0 px-2">
                                            <input type="text" class="form-control align-self-center" name="supplier"  placeholder="Supplier Name" v-model="supplierText">
                                        </div>
                                        <div class="d-flex">
                                            <button type="button" class="btn btn-primary mr-2 align-self-center" v-on:click="openSearchModal">Load Product</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4 mb-4" v-if="form.distribution_type=='distribution'">
                                <div class="col-12">
                                    <div class="table-wrapper overflow-auto" style="max-height: 700px">
                                        <div class="table-scroller overflow-auto" >
                                            <table class="table table-bordered table-hover date-columns w-100">
                                                <thead>
                                                <tr>
                                                    <th class="sticky-col-0 text-center">
                                                        <x-circle-icon size="1x" class=" cursor-pointer"></x-circle-icon>
                                                    </th>
                                                    <th class="sticky-col-1 text-center">Copy</th>
                                                    <th class="sticky-col-2 text-center">SKU</th>
                                                    <!--                                                    <th class="sticky-col-3 text-center">Supplier</th>-->
                                                    <th class="sticky-col-3 text-center">Desc.</th>
                                                    <!--                                                    <th class="sticky-col-5 text-center">Code</th>-->
<!--                                                    <th class="sticky-col-5 text-center">FOB</th>-->
                                                    <th class="sticky-col-4 text-center">Cont.</th>
                                                    <th class="sticky-col-5 text-center">In Cont.</th>
                                                    <th class="sticky-col-packaging text-center">Pkg.</th>
                                                    <th class="sticky-col-6 text-center">UOM</th>
                                                    <th class="sticky-col-7 text-center">On Hand</th>
                                                    <th class="sticky-col-8 text-center">Balance</th>
                                                    <th class="text-center">REG</th>
                                                    <th class="text-center">ESP</th>
                                                    <th v-for="(store,index) in stores" :key="index">
                                                        {{store.slug}}
                                                    </th>
                                                    <th class="sticky-col-12">Total</th>
                                                    <!--                                                    <th class="sticky-col-15">Margin REG</th>-->
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-if="order_products.length > 0" v-for="(product, index) in order_products" v-on:click="setHistory(product, index)" :key="index">
                                                    <td :class="'sticky-col-0 ' + manualTableStripe(index)" style="height: 5rem">
                                                        <x-circle-icon size="1x" :class="'cursor-pointer' + manualTableStripe(index)" v-on:click="removeProduct(index)"></x-circle-icon>
                                                    </td>
                                                    <td :class="'sticky-col-1 ' + manualTableStripe(index)" style="height: 5rem">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" @click="bulkSetValues(index)"  viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-copy cursor-pointer"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>
                                                    </td>
                                                    <td :class="'sticky-col-2 ' + manualTableStripe(index)" style="height: 5rem;">{{product.sku}}</td>
                                                    <!--                                                    <td class="sticky-col-3" style="height: 5rem;">{{product.suppliers.length > 0 ? product.suppliers[0].name : ''}}</td>-->
                                                    <td v-b-tooltip.hover :title=product.description :class="'sticky-col-3 ' + manualTableStripe(index)" style="height: 5rem;">{{product.description.substr(0,30)}}</td>
<!--                                                    <td v-b-tooltip.hover :title=product.description class="sticky-col-3" style="height: 5rem;">{{product.description}}</td>-->
                                                    <td :class="'sticky-col-4 ' + manualTableStripe(index)" style="height: 5rem;">
                                                        <p v-for="(container, index) in product.containers" :key="'container-'+index">{{container != null ? container.container : ''}}</p>
                                                    </td>
                                                    <td :class="'sticky-col-5 ' + manualTableStripe(index)" style="height: 5rem;">
                                                        <p v-for="(container, index) in product.containers" :key="'container-quantity-'+index">{{getConversionToMeasurementUnit(product, container.quantity)}}</p>
                                                    </td>
                                                    <td :class="'sticky-col-packaging ' + manualTableStripe(index)" style="height: 5rem;">
                                                        {{product.packaging}}
                                                    </td>
                                                    <!--                                                    <td class="sticky-col-5" style="height: 5rem;">{{product.product_code}}</td>-->
<!--                                                    <td class="sticky-col-5" style="height: 5rem;">-->
<!--                                                        <input type="text" v-model="product.unit_cost" @blur="productOnBlurDefault(product.unit_cost, '0.00')" v-on:change="sumTotal(product)" style="width: 50px">-->
<!--                                                    </td>-->
                                                    <td :class="'sticky-col-6 ' + manualTableStripe(index)" style="height: 5rem;">
                                                        <dropbox name="measurement_units" :options="product.measurement_units" v-model="product.measurement_unit">
                                                            <option></option>
                                                        </dropbox>
                                                    </td>
                                                    <td :class="'sticky-col-7 ' + manualTableStripe(index)" style="height: 5rem;">{{getConversionToMeasurementUnit(product, product.inventory.quantity)}}</td>
                                                    <td :class="'sticky-col-8 ' + manualTableStripe(index)" style="height: 5rem;">{{getConversionToMeasurementUnit(product, product.balance - product.total)}}</td>
                                                    <td :class="manualTableStripe(index)" style="height: 5rem;">
                                                        <input type="text" v-model="product.sales_price" @blur="productOnBlurDefault(product.sales_price, '0.00')" v-on:change="sumTotal(product)" style="width: 50px">
                                                    </td>
                                                    <td :class="manualTableStripe(index)" style="height: 5rem;">
                                                        <input type="text" v-model="product.sales_price_esp" @blur="productOnBlurDefault(product.sales_price_esp, '0.00')" v-on:change="sumTotal(product)" style="width: 50px">
                                                    </td>
                                                    <td :class="manualTableStripe(index)" v-for="(store,storeIndex)  in product.stores_quantities" :title="store.name" style="height: 5rem;">

                                                        <div :id="product.id + '_' + store.id">
                                                            <input type="text" :id="product.id + '_' + store.id + '_input'"
                                                                   v-model="store.quantity"
                                                                   @input="sumTotal(product)"
                                                                   style="width: 40px"
                                                                   @focus="showToolTip(product.id + '_' + store.id + '_input', product.id + '_' + store.id)"
                                                                   @blur="hideToolTip(product.id + '_' + store.id + '_input', product.id + '_' + store.id); storeQuantityOnBlurDefault(store, '0');" :title="store.name">
                                                        </div>

                                                    </td>
                                                    <td :class="'sticky-col-11 ' + manualTableStripe(index)" style="height: 5rem;">{{getConversionToMeasurementUnit(product, product.total)}}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

<!--                            Redistribution-->
                            <div class="row mt-4 mb-4" v-if="form.distribution_type=='redistribution'">

                                <div class="col-12">
                                    <div class="table-wrapper overflow-auto" style="max-height: 700px">
                                        <div class="redistribution-table-scroller overflow-auto" >
                                            <table class="table table-bordered table-hover date-columns w-100">
                                                <thead>
                                                <tr>
                                                    <th class="redistribution-sticky-col-0 text-center">
                                                        <x-circle-icon size="1x" class=" cursor-pointer"></x-circle-icon>
                                                    </th>
                                                    <th class="redistribution-sticky-col-1 text-center">Copy</th>
                                                    <th class="redistribution-sticky-col-2 text-center">SKU</th>
                                                    <!--                                                    <th class="sticky-col-3 text-center">Supplier</th>-->
                                                    <th class="redistribution-sticky-col-3 text-center">Desc.</th>
                                                    <th class="redistribution-sticky-col-4 text-center">On Hand</th>
                                                    <th class="redistribution-sticky-col-packaging text-center">Pkg.</th>
                                                    <!--                                                    <th class="sticky-col-5 text-center">Code</th>-->
                                                    <th class="redistribution-sticky-col-5 text-center">UOM</th>
                                                    <th v-for="(store,index) in stores" :key="index">
                                                        {{store.slug}}
                                                    </th>
                                                    <th class="sticky-col-12">Total</th>
                                                    <!--                                                    <th class="sticky-col-15">Margin REG</th>-->
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-if="order_products.length > 0" v-for="(product, index) in order_products" v-on:click="setHistory(product, index)" :key="index">
                                                    <td :class="'redistribution-sticky-col-0 ' + manualTableStripe(index)" style="height: 5rem">
                                                        <x-circle-icon size="1x" class="cursor-pointer" v-on:click="removeProduct(index)"></x-circle-icon>
                                                    </td>
                                                    <td :class="'redistribution-sticky-col-1 ' + manualTableStripe(index)" style="height: 5rem">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" @click="bulkSetValues(index)"  viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-copy cursor-pointer"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>
                                                    </td>
                                                    <td :class="'redistribution-sticky-col-2 ' + manualTableStripe(index)" style="height: 5rem;">{{product.sku}}</td>
                                                    <!--                                                    <td class="sticky-col-3" style="height: 5rem;">{{product.suppliers.length > 0 ? product.suppliers[0].name : ''}}</td>-->
                                                    <td :class="'redistribution-sticky-col-3 ' + manualTableStripe(index)" style="height: 5rem;">{{product.description}}</td>
                                                    <!--                                                    <td class="sticky-col-5" style="height: 5rem;">{{product.product_code}}</td>-->
                                                    <td :class="'redistribution-sticky-col-4 ' + manualTableStripe(index)" style="height: 5rem;">{{getConversionToMeasurementUnit(product, product.inventory.quantity)}}</td>
                                                    <td :class="'redistribution-sticky-col-packaging ' + manualTableStripe(index)" style="height: 5rem;">
                                                        {{product.packaging}}
                                                    </td>
                                                    <td :class="'redistribution-sticky-col-5 ' + manualTableStripe(index)" style="height: 5rem;">
                                                        <dropbox name="measurement_units" :options="product.measurement_units" v-model="product.measurement_unit">
                                                            <option></option>
                                                        </dropbox>
                                                    </td>

                                                    <td :class="manualTableStripe(index)" v-for="(store,storeIndex)  in product.stores_quantities" :title="store.name" style="height: 5rem;">

                                                        <div :id="product.id + '_' + store.id">
                                                            <input type="text" :id="product.id + '_' + store.id + '_input'"
                                                                   v-model="store.quantity"
                                                                   @input="sumTotal(product)"
                                                                   style="width: 40px"
                                                                   @focus="showToolTip(product.id + '_' + store.id + '_input', product.id + '_' + store.id)"
                                                                   @blur="hideToolTip(product.id + '_' + store.id + '_input', product.id + '_' + store.id); storeQuantityOnBlurDefault(store, '0');" :title="store.name">
                                                        </div>

                                                    </td>
                                                    <th :class="'sticky-col-12 ' + manualTableStripe(index)" style="height: 5rem;">{{getConversionToMeasurementUnit(product, product.total)}}</th>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--                            <div class="row mt-2">-->
                            <!--                                <button  type="button" class="btn btn-primary mr-2" @click="addProduct">Add Products</button>-->
                            <!--                            </div>-->

                            <div class="row mt-5">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="requested_delivery_date">Request Delivery Date<span class="required_asterisk">*</span></label>
                                        <div class="input-group date datepicker dashboard-date mr-2 mb-2 mb-md-0 d-xl-flex">
                                            <span class="input-group-addon bg-transparent"><i data-feather="calendar" class=" text-primary"></i></span>
                                            <datePicker autocomplete="off" v-model="form.requested_delivery_date" :config="datePickerOptions" name="requested_delivery_date"></datePicker>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <h6 class="col-md-12 text-center">Product Historical Information</h6>
                                    <h6 class="col-md-12 text-center mt-2"  v-if="selected_product != null && selected_product !== {}">{{selected_product.name}}</h6>
                                    <div class="first-row-table-container">
                                        <table class="table table-bordered table-hover date-columns first-col-table" v-if="product_stores.length > 0">
                                            <thead>
                                            <tr style="width: 250px;">
                                                <th style="width: 250px;" class="fixed-col">Store</th>
                                                <th style="width: 250px;" v-for="(product_store,index) in product_stores" :key="index">{{product_store.slug}}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr style="width: 250px;">
                                                <td style="width: 250px;" class="fixed-col">Previous Order Quantity</td>
<!--                                                <td style="width: 250px;" v-for="(product_store,index) in product_stores" :key="index">{{order_products[selected_product_history_index]}}</td>-->
                                                <td style="width: 250px;" v-for="(product_store,index) in product_stores" :key="index">{{getConversionToMeasurementUnit(order_products[selected_product_history_index], product_store.quantity)}}</td>
                                            </tr>
                                            <tr style="width: 250px;">
                                                <td style="width: 250px;" class="fixed-col">Last Date Sent</td>
                                                <td style="width: 250px;" v-for="(product_store,index) in product_stores" :key="index">{{product_store.requested_delivery_date}}</td>
                                            </tr>
                                            <tr style="width: 250px;" v-for="(productByMonth,dateAndYear,index) in productStoreByMonth" :key="index">
                                                <td style="width: 250px;" class="fixed-col">{{dateAndYear}}</td>
                                                <td style="width: 250px;" v-for="(storeQuantity,secondIndex) in productByMonth" :key="secondIndex">{{storeQuantity}}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button :disabled="submit_disabled" type="button" class="btn btn-primary mr-2" @click="submit">Submit</button>
                        <button v-on:click="cancel" class="btn btn-light">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        <!--        <button class="btn btn-primary" @click="$modal.show('search-products-modal')">Dispatch Additional Products</button>-->
        <search-products-modal :text="searchText" :supplierText="supplierText" @selectProducts="selectProducts" @loadProduct="selectProduct" @addProduct="addProduct" ref="searchProductsModal" :product="selected_product"></search-products-modal>
        <search-purchase-order-modal @setPO="setPO" ref="searchPurchaseOrderModal"></search-purchase-order-modal>
        <search-container-modal @setContainer="setContainer" ref="searchContainerModal"></search-container-modal>

    </div>
</template>

<script>
import 'bootstrap-datepicker';
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import {XCircleIcon} from 'vue-feather-icons';
import Swal from "sweetalert2";
import Toasted from 'vue-toasted';

export default {
    name: "DistributionOrderComponent",
    components:{
        datePicker,
        XCircleIcon
    },
    props: ["purchase_orders","stores", "temp_data"],
    beforeMount(){

        console.log("Keys", Object.keys(this.$props.temp_data).length);

        if(Object.keys(this.$props.temp_data).length === 0){
            this.$props.stores.forEach(store =>{
                this.form.product_orders.push({
                    id:store.id,
                    name:store.name,
                    slug:store.slug,
                    products:[]
                })
            });

            this.$props.purchase_orders.forEach(po =>{
                this.purchase_orders_options.push({
                    id: po.id,
                    name: po.po_number + ' - ' + po.supplier.name
                })
            });
        }else{
            console.log(this.$props.temp_data);

            this.updatingData = true;

            // Move all the temp data to the component variable, map by name
            for(const [key, value] of Object.entries(this.$props.temp_data)){
                this.$data[key] = value
            }

            this.updatingData = false;
        }
    },
    data(){
        return{
            form:{
                product_orders:[],
                requested_delivery_date: "",
                distribution_type: "distribution"
            },
            purchase_orders_options: [],
            selected_po_id: "",
            selected_po_temp_id: null,
            selected_po: {},
            sku_options: [],
            selected_product: {},
            selected_search_products: [],
            selected_sku_id: "",
            selected_suppliers: [],
            order_products: [],
            datePickerOptions:{
                format: 'MM/DD/YYYY',
                useCurrent: false,
            },
            product_stores : [],
            productStoreByMonth : [],
            submit_disabled: false,
            searchText: "",
            supplierText: "",
            container_options: [],
            selected_container_id: "",
            selected_container_temp_id: null,
            selected_container: {},
            updating_po: false,
            updating_container: false,
            container_purchase_orders: [],
            selected_product_history_index: null,
            timeoutSaveTemp: null,
            updatingData: false
        }
    },
    watch:{
        form: {
            handler (val){
                this.delaySaveTemp(3000)(this.saveTempData);
            },
            deep: true
        },
        order_products: {
            handler (val){
                this.delaySaveTemp(3000)(this.saveTempData);
            },
            deep: true
        },
        selected_container_id: {
            handler(val){
                this.delaySaveTemp(3000)(this.saveTempData);
            }
        },
        selected_po_id: {
            handler(val){
                this.delaySaveTemp(3000)(this.saveTempData);
            }
        }
    },
    methods:{
        manualTableStripe(index){
          if(index % 2 == 0){
              return 'gray-background'
          }

          return '';
        },
        cancel(){

            Swal.fire({
                title: 'Are you sure?',
                text: "You want to cancel the current distribution",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm'
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.delete("/distribution/tempdata").then(response => {
                        location = "/distribution";
                    })
                }
            })
        },
        containerCallback(){
            if(this.selected_container_temp_id != null){
                this.selected_container_id = this.selected_container_temp_id
                this.setContainerProductsCallback();
            }

            this.selected_container_temp_id = null;
        },
        async purchaseOrderCallback(){
            if(this.selected_po_temp_id != null){
                this.selected_po_id = this.selected_po_temp_id;

                this.selected_po = this.container_purchase_orders.find(cpo => cpo.id == this.selected_po_temp_id);
                this.selected_suppliers = [];
                this.selected_suppliers.push(this.selected_po.supplier.name);
            }

            this.selected_po_temp_id = null;
        },
        async setContainerProductsCallback(){
            const hasContainerId = (container) => {
                return container.id == this.selected_container_id
            };


            console.log("Selected Container 2", this.selected_container_id, this.selected_container);



            await this.loadProducts();
            console.log("Selected Container 3", this.selected_container_id, this.selected_container);

            this.order_products = this.order_products.filter(op => op.containers.some(hasContainerId));

            console.log("Order Products: ", this.order_products)

            this.updating_po = false;
            this.updating_container = false;
        },
        async loadContainer(){

            if(this.updatingData){
                return false;
            }

            if(this.updating_container){
                return false;
            }

            this.selected_container = this.selected_po.trafficcontainers.find(container => container.id == this.selected_container_id);

            this.loadProducts();

            const hasContainerId = (container) => {
                return container.id == this.selected_container_id
            };

            this.order_products = this.order_products.filter(op => op.containers.some(hasContainerId));
        },
        async loadProducts(){

            this.order_products = [];



            for (const product of this.selected_po.products) {

                let balance = 0;
                const containerProducts = this.selected_po.container_products.filter(cp => {
                    const productContainerRelease = cp.product_id === product.id && cp.container.port_release != null;

                    if(!this.selected_container_id){
                        return productContainerRelease;
                    }

                    return productContainerRelease && cp.container.id == this.selected_container_id
                });

                let productContainers = [];
                let container_quantity = 0;
                if(containerProducts != null){
                    productContainers = containerProducts.map(pc => {
                        container_quantity += pc.remaining_quantity;
                        return {
                            id: pc.container.id,
                            container: pc.container.container,
                            quantity: pc.remaining_quantity
                        }
                    })
                }



                if(productContainers.length > 0){
                    const response = await axios.get("/products/" + product.id + "/get_open_store_order_quantity");

                    const caseUnit = product.measurement_units.find(mu => mu.name == 'cases');
                    let unitId = 1;

                    if(caseUnit != null){
                        unitId = caseUnit.id;
                    }

                    console.log("Case Unit", caseUnit, product.measurement_units);

                    const packaging = product.measurement_units.find(mu => mu.name == 'cases')

                    console.log("Packaging", packaging)

                    let order_product = {
                        id:product.id,
                        name:product.name,
                        sku:product.sku,
                        description:product.description,
                        inventory:product.inventory,
                        stores_quantities:[],
                        total: "",
                        suppliers: product.suppliers,
                        image_path: product.image_path,
                        measurement_units: product.measurement_units,
                        measurement_unit: unitId,
                        packaging: packaging != null ? packaging.pivot.conversion_quantity : '',
                        // balance: product.inventory.quantity + container_quantity,
                        balance: (((container_quantity) + product.inventory.quantity) - parseInt(response.data.quantity)),
                        sales_price: product.sales_price.toFixed(2),
                        order_quantity: product.pivot.quantity,
                        sales_price_esp: product.sales_price_esp.toFixed(2),
                        unit_cost: product.unit_cost.toFixed(2),
                        product_code: product.product_code,
                        container_quantity: container_quantity,
                        containers: productContainers
                    };

                    this.$props.stores.forEach(store =>{
                        const productStoreDistribution = product.product_store_distributions.find(psd => psd.store_id == store.id);
                        let quantityToDistribute = 0;

                        if(productStoreDistribution != null){
                            quantityToDistribute = container_quantity * (productStoreDistribution.quantity_distributed / productStoreDistribution.order_qty);
                        }

                        order_product.stores_quantities.push({
                            id: store.id,
                            name: store.name,
                            quantity: this.getConversionToMeasurementUnit(order_product, quantityToDistribute)
                        })
                    })

                    this.order_products.push(order_product);
                }
            }

            this.order_products = this.order_products.sort(this.compareProduct);
        },
        compareProduct(a, b){
            if(a.description < b.description){
                return -1;
            }

            if(a.description > b.description){
                return 1;
            }

            return 0;
        },
        async setContainer(container){



            if(container == null){
                return false;
            }

            this.updating_container = true;
            this.updating_po = true;

            this.container_options = [];
            this.container_options.push({
                id: container.id,
                name: container.container
            })

            this.purchase_orders_options = container.purchase_order.map(po =>{
                return {
                    id: po.id,
                    name: po.po_number + ' - ' + po.supplier.name
                }
            })

            this.container_purchase_orders = container.purchase_order;



            this.selected_po_id = this.purchase_orders_options[0]['id'];
            this.selected_po_temp_id = this.purchase_orders_options[0]['id'];
            this.selected_po = this.$props.purchase_orders.find(po => po.id == this.selected_po_id);
            console.log("Selected PO", this.selected_po_id, this.selected_po)
            this.selected_container_id = container.id;
            this.selected_container_temp_id = container.id;
            this.selected_container = container;
            console.log("Selected Container", this.selected_container_id, this.selected_container);

        },
        async setPO(po){
            console.log("Help", po);

            if(po == null){
                return false;
            }

            const pos = this.purchase_orders_options.filter(purchase_order => purchase_order.id == po.id);

            if(pos.length <= 0) {
                this.purchase_orders_options.push({
                    id: po.id,
                    name: po.po_number + ' - ' + po.supplier.name
                })
            }
            this.updating_po = true;
            // this.selected_po_id = po.id;

            await this.assignPO(po, true);

        },
        async assignPO(po, resetContainer = true){

            if(resetContainer){
                console.log("Resetting COntainer");
                this.selected_container = {};
                this.selected_container_id = ""
            }

            this.selected_po = po;
            this.selected_suppliers = [];
            console.log("PO", this.selected_po, po);
            this.selected_suppliers.push(po.supplier.name);
            this.order_products = [];
            this.selected_product = null;
            this.product_stores = [];

            const port_release_containers = this.selected_po.trafficcontainers.filter(tc => tc.port_release != null);

            console.log("Port Release Containers", port_release_containers, this.selected_po.trafficcontainers);

            this.container_options = [];

            port_release_containers.forEach(tc =>{
                if(!this.container_options.some(container => container.id == tc.id)){
                    this.container_options.push({
                        id: tc.id,
                        name: tc.container
                    })
                }
            })


            this.loadProducts();

            this.$forceUpdate();

            this.updating_po = false;
        },
        async loadPO(resetContainer = true){

            console.log("Not Updating", this.updatingData);

            if(this.updatingData){
                console.log("UpadingData")
                return false;
            }

            if(this.updating_po){
                return false;
            }


            let po_id = this.selected_po_id
            console.log("PO ID", po_id, this.updating_po)
            this.selected_po = this.$props.purchase_orders.find(po => po.id == po_id);
            await this.assignPO(this.selected_po, resetContainer);
        },
        loadProduct(){


            axios.get('/products/get_products_by_text?text=' + this.searchText + '&supplier_text=' + this.supplierText ).then(response =>{
                this.selected_product = response.data.product


                let product = this.selected_product;
                this.product_stores = [];
                this.$props.stores.forEach(store =>{
                    let last_order = product.last_orders.find(order => order.store_id == store.id);

                    let quantity = 0;
                    let requested_delivery_date = ""

                    if(last_order != null){
                        quantity = last_order.qty;
                        requested_delivery_date = last_order.requested_delivery_date;
                    }

                    this.product_stores.push({
                        id: store.id,
                        slug: store.slug,
                        quantity: quantity,
                        requested_delivery_date: requested_delivery_date
                    })
                });
            }).catch(error =>{
                this.submit_disabled = false;
            })
        },
        addProduct(product){
            this.selected_product=product;
            if(this.selected_product != null && this.selected_product !== {} && this.order_products.find(product => product.id === this.selected_product.id) == null){
                axios.get('products/'+ product.id + "/get_open_store_order_quantity").then(response => {
                    console.log("order product", order_product, this.selected_product);
                    const caseUnit = product.measurement_units.find(mu => mu.name == 'cases');
                    let unitId = 1;

                    if(caseUnit != null){
                        unitId = caseUnit.id;
                    }

                    const packaging = product.measurement_units.find(mu => mu.name == 'cases')

                    let order_product = {
                        id:product.id,
                        name:product.name,
                        sku:product.sku,
                        description:product.description,
                        inventory:product.inventory,
                        stores_quantities:[],
                        total: "",
                        suppliers: product.suppliers,
                        image_path: product.image_path,
                        measurement_units: product.measurement_units,
                        measurement_unit: unitId,
                        packaging: packaging != null ? packaging.pivot.conversion_quantity : '',
                        balance: (product.inventory.quantity - parseInt(response.data.quantity)),
                        sales_price: product.sales_price.toFixed(2),
                        order_quantity: 0,
                        sales_price_esp: product.sales_price_esp.toFixed(2),
                        unit_cost: product.unit_cost.toFixed(2),
                        product_code: product.product_code,
                    };
                    this.$props.stores.forEach(store =>{
                        order_product.stores_quantities.push({
                            id: store.id,
                            name: store.name,
                            quantity: 0
                        })
                    })

                    this.addSupplier(product.suppliers[0].name)

                    console.log("order product", order_product);

                    this.order_products.push(order_product);

                    this.order_products = this.order_products.sort(this.compareProduct);
                });
            }else{
                console.log("Esto no está funcionando")
            }
        },
        addSupplier(supplier){
            let exists = false;

            this.selected_suppliers.forEach(s => {
                s === supplier ? exists = true : false;
            });

            if(exists == false)
                this.selected_suppliers.push(supplier);

        },
        sumTotal(product){

            product.total = 0;
            product.stores_quantities.forEach(store =>{
                product.total += this.getConversionToEaches(product, parseFloat(store.quantity));
            });
        },
        margin(reg, esp, cost){
            let marginReg = 0, marginEsp = 0;

            if(reg > 0)
                marginReg = ((reg-cost)/reg) * 100;
            if(esp > 0)
                marginEsp = ((esp-cost)/esp) *100;

            return marginReg > marginEsp ? marginEsp : marginReg;

        },
        resetQuantities(order_products){

            //TODO: Optimizar solo actualizar el producto que cambia
            order_products.forEach(order_product => {
                order_product.stores_quantities.forEach(store =>{
                    store.quantity = this.getConversionToMeasurementUnit(order_product, store.quantity);
                })
            })
        },
        convertQuantities(product){
            product.stores_quantities.forEach(store =>{
                store.quantity = this.getConversionToMeasurementUnit(product, store.quantity)
            })
        },
        setHistory(product, index){
            this.product_stores = [];
            this.selected_product_history_index = index;

            axios.get("/products/" + product.id + "/last_orders").then(response =>{
                console.log(response.data)
                this.selectProduct(response.data.product, product)
            })
        },

        removeProduct(index){
            Swal.fire({
                title: 'Are you sure?',
                text: "You want to remove " + this.order_products[index].name,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm'
                }).then((result) => {
                if (result.isConfirmed) {
                    this.order_products.splice(index,1);
                    this.selected_product = {};
                }
                })


        },
        openSearchModal(){
            this.$refs.searchProductsModal.resetFilter();
            this.$modal.show('search-products-modal');
        },
        openPoModal(){
            this.$refs.searchPurchaseOrderModal.resetFilter();
            this.$modal.show('search-purchase-order-modal');
        },
        openContainerModal(){
            this.$refs.searchContainerModal.resetFilter();
            this.$modal.show('search-container-modal');
        },
        async saveTempData(){
            const data = {'data':{}}
            data['data'] = this.$data;

            console.log("Saving data");

            axios.post('/distribution/tempdata',data).then(response =>{

                this.$toasted.show("Saved",{
                    theme: "toasted-primary",
                    position: "bottom-right",
                    duration: 1000
                })
            })
        },
        submit(){

            if(this.form.distribution_type == 'distribution' && (!this.selected_container_id)){
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Please select a container before continue',
                });

                return;
            }

            this.order_products.forEach(order_product => {
                order_product.stores_quantities.forEach(store =>{
                    store.quantity_eaches = this.getConversionToEaches(order_product, store.quantity);
                })
            });


            this.form.product_orders = this.order_products;
            this.form['purchase_order'] = this.selected_po;
            this.form['container'] = this.selected_container;
            let attributes = this.form;

            this.submit_disabled = true;

            axios.post('/distribution',attributes).then(response =>{
                location = response.data.message
            }).catch(error =>{
                this.submit_disabled = false;
                console.log(error);
            })
        },
        selectProducts(products){
            console.log("In the function", products)
            products.forEach(product =>{
                this.addProduct(product);
            })
        },
        selectProduct(product){
            this.selected_product = product;
            this.product_stores = [];
            this.productStoreByMonth = [];

            this.$props.stores.forEach(store =>{
                let last_order = product.last_orders.find(order => order.store_id === store.id);

                let quantity = 0;
                let requested_delivery_date = ""

                if(last_order != null && last_order.sku === product.sku){
                    quantity = last_order.qty;
                    requested_delivery_date = last_order.requested_delivery_date;
                }

                this.product_stores.push({
                    id: store.id,
                    slug: store.slug,
                    quantity: quantity,
                    requested_delivery_date: requested_delivery_date
                })
            });

            this.productStoreByMonth = product.orders_last_6_months;
        },
        getConversionToMeasurementUnit(product, quantity){
            let conversionQuantity = product.measurement_units.find(mu => mu.id == product.measurement_unit).pivot.conversion_quantity;

            return quantity/conversionQuantity;
        },
        getConversionToEaches(product, quantity){
            let conversionQuantity = product.measurement_units.find(mu => mu.id == product.measurement_unit).pivot.conversion_quantity;

            return quantity * conversionQuantity;
        },
        showToolTip(child,parent) {
            var inputField = document.getElementById(child);
            var parentElement = document.getElementById(parent);

            var toolTipItem = document.createElement('div');
            toolTipItem.textContent = inputField.title;
            toolTipItem.id = child + '_div';


            parentElement.insertBefore(toolTipItem,inputField);

        },
        hideToolTip(child,parent) {
            var inputField = document.getElementById(child);
            var parentElement = document.getElementById(parent);
            var elementToRemove = document.getElementById(child+'_div');

            parentElement.removeChild(elementToRemove);
        },
        bulkSetValues(rowPosition){
            function ifNullOrEmptyString(value,defaultValue) {
                if (value === null || value === '') {
                    value = defaultValue;
                }
                return value
            }


            function sumTotalBulk(product){
            product.total = 0;
            let conversionQuantity = product.measurement_units.find(mu => mu.id == product.measurement_unit).pivot.conversion_quantity;
            product.stores_quantities.forEach(store =>{
                product.total += (store.quantity * conversionQuantity);
            });
            }

            let setFOBBulkValue = ifNullOrEmptyString(this.order_products[rowPosition].unit_cost,'0.00');
            let setREGBulkValue = ifNullOrEmptyString(this.order_products[rowPosition].sales_price,'0.00');
            let setESPBulkValue = ifNullOrEmptyString(this.order_products[rowPosition].sales_price_esp, '0.00');
            //UOM
            let setCDBulkQuantity = ifNullOrEmptyString(this.order_products[rowPosition].stores_quantities[0].quantity, '0');
            let setMTBulkQuantity = ifNullOrEmptyString(this.order_products[rowPosition].stores_quantities[1].quantity, '0');
            let setCGBulkQuantity = ifNullOrEmptyString(this.order_products[rowPosition].stores_quantities[2].quantity, '0');
            let setCOBulkQuantity = ifNullOrEmptyString(this.order_products[rowPosition].stores_quantities[3].quantity, '0');
            let setSMBulkQuantity = ifNullOrEmptyString(this.order_products[rowPosition].stores_quantities[4].quantity, '0');
            let setJDBulkQuantity = ifNullOrEmptyString(this.order_products[rowPosition].stores_quantities[5].quantity, '0');
            let setCABulkQuantity = ifNullOrEmptyString(this.order_products[rowPosition].stores_quantities[6].quantity, '0');
            let setSJBulkQuantity = ifNullOrEmptyString(this.order_products[rowPosition].stores_quantities[7].quantity, '0');
            let setHTBulkQuantity = ifNullOrEmptyString(this.order_products[rowPosition].stores_quantities[8].quantity, '0');
            let setGYBulkQuantity = ifNullOrEmptyString(this.order_products[rowPosition].stores_quantities[9].quantity, '0');
            let set65BulkQuantity = ifNullOrEmptyString(this.order_products[rowPosition].stores_quantities[10].quantity, '0');
            let setMGBulkQuantity = ifNullOrEmptyString(this.order_products[rowPosition].stores_quantities[11].quantity, '0');
            let setN1BulkQuantity = ifNullOrEmptyString(this.order_products[rowPosition].stores_quantities[12].quantity, '0');
            let setN2BulkQuantity = ifNullOrEmptyString(this.order_products[rowPosition].stores_quantities[13].quantity, '0');
            let setOLBulkQuantity = ifNullOrEmptyString(this.order_products[rowPosition].stores_quantities[14].quantity, '0');


            Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to COPY this Row values to the Row Values Below?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm'
            }).then((result) => {
            if (result.isConfirmed) {
                this.order_products.forEach(function(product, index) {

                if (index < rowPosition) {
                    //Pass
                }else{
                    product.unit_cost = setFOBBulkValue;
                    product.sales_price = setREGBulkValue;
                    product.sales_price_esp = setESPBulkValue;
                    product.stores_quantities[0].quantity = setCDBulkQuantity;
                    product.stores_quantities[1].quantity = setMTBulkQuantity;
                    product.stores_quantities[2].quantity = setCGBulkQuantity;
                    product.stores_quantities[3].quantity = setCOBulkQuantity;
                    product.stores_quantities[4].quantity = setSMBulkQuantity;
                    product.stores_quantities[5].quantity = setJDBulkQuantity;
                    product.stores_quantities[6].quantity = setCABulkQuantity;
                    product.stores_quantities[7].quantity = setSJBulkQuantity;
                    product.stores_quantities[8].quantity = setHTBulkQuantity;
                    product.stores_quantities[9].quantity = setGYBulkQuantity;
                    product.stores_quantities[10].quantity = set65BulkQuantity;
                    product.stores_quantities[11].quantity = setMGBulkQuantity;
                    product.stores_quantities[12].quantity = setN1BulkQuantity;
                    product.stores_quantities[13].quantity = setN2BulkQuantity;
                    product.stores_quantities[14].quantity = setOLBulkQuantity;
                }
                sumTotalBulk(product);

            });
            }
            })
        },
        storeQuantityOnBlurDefault(store, defaultValue)
        {

            if (store.quantity === null || store.quantity === '') {

             store.quantity = String(defaultValue);

            }

        },
        productOnBlurDefault(productProp, defaultValue) {

            if (productProp === null || productProp === '') {

                productProp = String(defaultValue);

            }
        },
        delaySaveTemp(ms){

            console.log("Running this function")
            clearTimeout(this.timeoutSaveTemp)
            let vm = this;

            return function(callback){
                vm.timeoutSaveTemp = setTimeout(callback, ms)
            }
        }



    }
}
</script>

<style scoped>
.table-wrapper{
    position: relative;
}
.table-scroller{
    margin-left: 53%;
    overflow-x: scroll;
    overflow-y: visible;
    padding-bottom: 5px;
    width: 47%
}

/*table .table>thead>tr>th {*/
/*    background-color: white;*/
/*    position: sticky;*/
/*    position: -webkit-sticky;*/
/*    top: 0;*/
/*    z-index: 2;*/
/*}*/

/*Delete*/
table .sticky-col-0{
    position: absolute;
    top: auto;
    left: 0;
    width: 2%;
    overflow: hidden;
    white-space: normal;
}

/*COPY*/
table .sticky-col-1{
    position: absolute;
    top: auto;
    left: 2%;
    width: 4%;
    overflow: hidden;
    white-space: normal;
}

/*SKU*/
table .sticky-col-2{
    position: absolute;
    top:auto;
    left: 6%;
    width: 4%;
    overflow: hidden;
    white-space: normal;
}

/*Description*/
table .sticky-col-3{
    position: absolute;
    top:auto;
    left: 10%;
    width: 9%;
    overflow: hidden;
    white-space: normal;
}

/*Container*/
table .sticky-col-4{
    position: absolute;
    top:auto;
    left: 19%;
    width: 5%;
    overflow: hidden;
    white-space: normal;
}

/*In Container*/
table .sticky-col-5{
    position: absolute;
    top:auto;
    left: 24%;
    width: 4%;
    overflow: hidden;
    white-space: normal;
}

table .sticky-col-packaging{
    position: absolute;
    top:auto;
    left: 28%;
    width: 7%;
    overflow: hidden;
    white-space: normal;
}

/*UOM*/
table .sticky-col-6{
    position: absolute;
    top:auto;
    left: 35%;
    width: 8%;

}

/*Inventory*/
table .sticky-col-7{
    position: absolute;
    top:auto;
    left: 43%;
    width:5%;

}

/*Balance*/
table .sticky-col-8{
    position: absolute;
    top:auto;
    left: 48%;
    width: 5%;

}


/*Redistribution*/
/*Delete*/
table .redistribution-sticky-col-0{
    position: absolute;
    top: auto;
    left: 0;
    width: 2%;
    overflow: hidden;
    white-space: normal;
}

/*COPY*/
table .redistribution-sticky-col-1{
    position: absolute;
    top: auto;
    left: 2%;
    width: 4%;
    overflow: hidden;
    white-space: normal;
}

/*SKU*/
table .redistribution-sticky-col-2{
    position: absolute;
    top:auto;
    left: 6%;
    width: 5%;
    overflow: hidden;
    white-space: normal;
}

/*Description*/
table .redistribution-sticky-col-3{
    position: absolute;
    top:auto;
    left: 11%;
    width: 9%;
    overflow: hidden;
    white-space: normal;
}

/*On Hand*/
table .redistribution-sticky-col-4{
    position: absolute;
    top:auto;
    left: 20%;
    width: 5%;
    overflow: hidden;
    white-space: normal;
}

table .redistribution-sticky-col-packaging{
    position: absolute;
    top:auto;
    left: 25%;
    width: 7%;
    overflow: hidden;
    white-space: normal;
}

/*UOM*/
table .redistribution-sticky-col-5{
    position: absolute;
    top:auto;
    left: 32%;
    width: 8%;
    overflow: hidden;
    white-space: normal;
}

.redistribution-table-scroller{
    margin-left: 40%;
    overflow-x: scroll;
    overflow-y: visible;
    padding-bottom: 5px;
    width: 60%
}
/*table .sticky-col-9{*/
/*    position: absolute;*/
/*    top:auto;*/
/*    left: 42%;*/
/*    width: 5%;*/

/*}*/


/*table .sticky-col-10{*/
/*    position: absolute;*/
/*    top:auto;*/
/*    left: 47%;*/
/*    width: 4%;*/

/*}*/

.first-col-table .fixed-col{
    position: sticky;
    left: 0;
    z-index: 1;
}

.first-row-table-container{
    width: 100%;
    overflow: auto;
}

.gray-background{
    background-color: #eee;
}

</style>
