<template>
    <div>
        <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
                <div class="card-body">
                    <div class="card-title">
                        Findings
                    </div>
                    <div class="my-2">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="my-2">
                                    <label for="location">Location</label>
                                    <input type="text"
                                           class="form-control mb-2"
                                           v-model="location"
                                           placeholder="Location"
                                    />
                                    <span style="color: blue" class="cursor-pointer my-2"
                                            v-on:click="$modal.show('location-object-selection-modal')"
                                    >Select Location</span>
                                    <object-selection-modal
                                        :path="main_path + '/locations'"
                                        filters=""
                                        modal_name="location"
                                        variable_name="level_label"
                                        @selectObject="selectLocation"
                                        title="Select Location"

                                    />
                                </div>
                                <div class="my-2">
                                    <label for="sku">SKU</label>
                                    <input type="text"
                                           class="form-control mb-2"
                                           v-model="sku"
                                           v-on:blur="setSelectedProduct"
                                           placeholder="SKU"
                                    />
                                    <span style="color: blue" class="cursor-pointer my-2"
                                            v-on:click="$modal.show('sku-object-selection-modal')"
                                    >Select SKU</span>
                                    <object-selection-modal
                                        :path="main_path + '/skus'"
                                        filters=""
                                        modal_name="sku"
                                        variable_name="sku"
                                        @selectObject="selectSku"
                                        title="Select SKU"
                                    />
                                </div>
                                <div class="my-2">
                                    <label for="lpn">LPN</label>

                                    <input type="text"
                                           class="form-control mb-2"
                                           v-model="lpn"
                                           placeholder="LPN"
                                    />
                                    <span style="color: blue" class="cursor-pointer my-2"
                                            v-on:click="$modal.show('lpn-object-selection-modal')"
                                    >Select LPN</span>
                                    <object-selection-modal
                                        :path="main_path + '/lpns'"
                                        filters=""
                                        modal_name="lpn"
                                        variable_name="lpn"
                                        @selectObject="selectLpn"
                                        title="Select LPN"
                                    />
                                </div>
                                <div class="d-flex ml-4">
                                    <input type="checkbox" v-model="new_lpn"><span class="ml-2">New</span>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="mb-2">
                                    <label for="upc">UPC</label>
                                    <input type="text"
                                           class="form-control"
                                           id="upc"
                                           v-model="upc"
                                           placeholder="UPC"
                                   />
                                </div>
                                <div class="mb-2">
                                    <label for="quantity">Quantity</label>
                                    <input type="number"
                                           min="0"
                                           class="form-control"
                                           id="quantity"
                                           v-model="count"
                                           placeholder="Quantity"
                                    />
                                </div>
                                <div class="mb-2">
                                    <label for="measurement_unit_id">UOM</label>
                                    <dropbox :options="measurement_unit_options"
                                             v-model="measurement_unit_id"
                                             class="custom-select custom-select-sm form-control"/>
                                    <div class="d-flex ml-4">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <h6>Product Detail</h6>
                                <div class="table-responsive pt-3">
                                    <table class="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>Product</th>
                                                <th>Description</th>
                                                <th>Supplier</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{{selected_product ? selected_product.sku : ''}}</td>
                                                <td>{{selected_product ? selected_product.description : ''}}</td>
                                                <td>{{selected_product ? selected_product.suppliers[0].name : ''}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-md-6">
                                    <div>
                                        <button class="btn btn-primary" v-on:click="addFinding">Add Finding</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
    name: "InventoryCountAddFinding",
    props:{
        inventory_count: Object
    },
    data(){
        return {
            location: "",
            sku: "",
            lpn: "",
            upc: "",
            count: "",
            measurement_unit_id: "",
            new_lpn: false,
            main_path: '/inventory_count/' + this.$props.inventory_count.id,
            selected_product: "",
            measurement_unit_options: []
        }
    },
    methods: {
        selectLpn(data){
            this.lpn = data.lpn;
        },
        selectSku(data){
            this.sku = data.sku;
            this.setSelectedProduct();
        },
        selectLocation(data){
            this.location = data.level_label;
        },
        setSelectedProduct(){
            axios.get('/products/getDataBySku',{params:{sku: this.sku}}).then(response =>{
                this.selected_product = response.data.product;
                this.upc = response.data.product.upc;
                console.log("El UPC: ",response.data.product.upc);
                this.measurement_unit_options = response.data.product.measurement_units;
            })
            .catch(error =>{
                console.log("Show error");
            })
        },
        addFinding(){
            const attributes = {
                location: this.location,
                sku: this.sku,
                lpn: this.lpn,
                upc: this.upc,
                count: this.count,
                measurement_unit_id: this.measurement_unit_id,
                new_lpn: this.new_lpn
            };

            if((attributes['lpn'] === '' || attributes['lpn'] == null) && (!attributes['new_lpn'] || attributes['new_lpn'] == null)){
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'LPN field is required if New is not checked.',
                });

                return;
            }

            if(attributes['sku'] === '' || attributes['sku'] == null){
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'SKU field is required.',
                });

                return;
            }


            if(attributes['location'] === '' || attributes['location'] == null){
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Location field is required.',
                });

                return;
            }

            if(attributes['upc'] != null && attributes['upc'].length > 16){
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'UPC length maximum is 16.',
                });

                return;
            }

            if(attributes['count'] === '' || attributes['count'] == null){
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Quantity field is required',
                });

                return;
            }

            if(attributes['count'] < 0){
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Quantity can not be a negative value.'
                });

                return;
            }

            if(attributes['measurement_unit_id'] === '' || attributes['measurement_unit_id'] == null){
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Measurement Unit field is required',
                });

                return;
            }

            axios.post(this.main_path + '/addFinding',attributes).then(response =>{
                console.log(response.data);
                this.$emit('findingSaved', response.data.data);
                this.clearFields();
            });
        },
        clearFields(){
            this.upc = "";
            this.location = "";
            this.sku = "";
            this.lpn = "";
            this.count = "";
            this.measurement_unit_id = "";
            this.measurement_unit_options = [];
            this.selected_product = "";
        }
    }
}
</script>

<style scoped>

</style>
