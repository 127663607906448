<template>
    <div>
        <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
                <div class="card-body">
                    <div class="card-title">Findings Count</div>
                    <div class="my-2">
                        <div class="row justify-content-between align-items-end mt-3">
                            <div>
                                <label for="paginate">Show</label>
                                <dropbox name="paginate"
                                         id="paginate"
                                         :options="pagination_options"
                                         v-model="pagination"
                                         class="custom-select custom-select-sm form-control"/>
                            </div>
                            <div class="d-flex align-items-end">
                                <div class="mr-2">
                                    <label for="filter">Search</label>
                                    <input type="text"
                                           autocomplete="off"
                                           class="form-control"
                                           id="filter"
                                           name="filter"
                                           v-model="filter"
                                           placeholder="Search"
                                    />
                                </div>
                                <div>
                                    <button type="button" class="btn btn-primary" v-on:click="getData(path + '?' + getFilter())">Filter</button>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive pt-3">
                            <table class="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>Location</th>
                                        <th>LPN</th>
                                        <th>SKU</th>
                                        <th>UPC</th>
                                        <th>Description</th>
                                        <th>Count</th>
                                        <th>UOM</th>
                                        <th>Action</th>
                                        <th>Print LPN</th>
                                        <th>User</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="finding in filtered_findings">
                                        <td>{{finding.level_label}}</td>
                                        <td>{{finding.license_plate.lpn}}</td>
                                        <td>{{finding.product.sku}}</td>
                                        <td>{{finding.upc}}</td>
                                        <td>{{finding.product.description}}</td>
                                        <td>{{finding.count}}</td>
                                        <td>{{finding.measurement_unit.name}}</td>
                                        <td>
                                            <button v-on:click="selectEdit(finding)" class="btn btn-primary">Edit</button>
                                        </td>
                                        <td>
                                            <a :href="'/lpn/' + finding.license_plate.id + '/generate_lpn'" class="btn" style="background-color: orangered; color: white" target="_blank">Print LPN</a>
                                        </td>
                                        <td>{{finding.user.email}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="d-flex justify-content-between mt-3">
                            <div>
                                Displaying {{filtered_findings.length}} of {{total}} location(s).
                            </div>
                            <div>
                                <nav>
                                    <ul class="pagination">
                                        <li :class="prevButtonClass">
                                            <span v-if="prev_page_url == '' || prev_page_url == null" class="page-link">{{'<'}}</span>
                                            <button type="button" v-else class="page-link" v-on:click="getData(prev_page_url)">{{'<'}}</button>
                                        </li>
                                        <li v-for="(button,index) in page_buttons" :class="button.class">
                                            <span v-if="button.isActualPage" class="page-link">{{button.pageNumber}}</span>
                                            <span v-else-if="button.disabledButton" class="page-link">...</span>
                                            <button type="button" v-else class="page-link" v-on:click="getData(button.path)">{{button.pageNumber}}</button>
                                        </li>
                                        <li :class="nextButtonClass">
                                            <span v-if="next_page_url == '' || next_page_url == null" class="page-link">{{'>'}}</span>
                                            <button type="button" v-else class="page-link" v-on:click="getData(next_page_url)">{{'>'}}</button>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <findings-count-edit-modal :finding="selected_edit" @deleteFinding="deleteFinding"/>
    </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
    name: "FindingsCount",
    props:{
        inventory_count: Object
    },
    mounted(){
        this.getData(this.path);
    },
    data(){
        return {
            pagination_options:[{id: "10", name:"10"}, {id: "30", name:"30"}, {id: "50", name:"50"}],
            filter: "",
            finding_count: 0,
            current_page: 1,
            first_page_url: "",
            last_page: 1,
            last_page_url: "",
            next_page_url: "",
            path: "/inventory_count/" + this.$props.inventory_count.id + "/findings",
            per_page: 1,
            to: 1,
            total: 1,
            pagination: 10,
            prevButtonClass: 'page-item ' + (this.prev_page_url !== null && this.prev_page_url !== '' ? '' : 'disabled'),
            nextButtonClass: 'page-item ' + (this.next_page_url !== null && this.next_page_url !== '' ? '' : 'disabled'),
            page_buttons: [],
            filtered_findings: [],
            from: '',
            selected_edit: {
                level_label: '',
                license_plate: {id: '', lpn: ''},
                product: {sku: '', upc: '', description: '', measurement_units: []},
                user: {email: ''}
            }
        }
    },
    methods: {
        selectEdit(finding){
          this.selected_edit = finding;
          this.$modal.show('finding-edit-modal');
        },
        getData(path) {
            axios.get(path).then(response => {
                console.log(response);
                this.setFindingsPaginationData(response.data)
            });
        },
        resetFilter() {
            this.filter = '';
            this.getData(this.path + '?' + this.getFilter());
        },
        setFindingsPaginationData(data) {
            let filterPath = this.getFilter();

            if (filterPath === '') {
                filterPath = filterPath + '&';
            }



            this.filtered_findings =  data.findings.data;
            this.current_page = data.findings.current_page;
            this.first_page_url = data.findings.first_page_url;
            this.from = data.findings.from;
            this.last_page = data.findings.last_page;
            this.last_page_url = data.findings.last_page_url == null ? null : this.path + '?' + filterPath + 'page=' + (this.current_page + 1);
            this.next_page_url = data.findings.next_page_url;
            this.per_page = data.findings.per_page;
            this.prev_page_url = data.findings.prev_page_url == null ? null : this.path + '?' + filterPath + "page=" + (this.current_page - 1);
            this.to = data.findings.to;
            this.total = data.findings.total;
            this.filter = data.filter;
            this.paginate = data.paginate;
            this.prevButtonClass = 'page-item ' + (this.prev_page_url !== null && this.prev_page_url !== '' ? '' : 'disabled');
            this.nextButtonClass = 'page-item ' + (this.next_page_url !== null && this.next_page_url !== '' ? '' : 'disabled');

            this.setButtons();
        },
        getFilter() {
            return (this.filter != null && this.filter !== '' ? 'filter=' + this.filter : '');
        },
        setButtons() {
            this.page_buttons = [];

            console.log(this.last_page, this.current_page);

            if (this.last_page > 13) {
                if (this.current_page < 8) {
                    this.setLeftSideButtons();
                } else if (this.last_page - this.current_page < 7) {
                    this.setRightSideButtons();
                } else {
                    this.setMiddleButtons();
                }
            } else {
                this.setNormalButtons();
            }
        },
        setNormalButtons() {
            for (let i = 1; i <= this.last_page; i++) {
                let button = this.createButton(i);

                this.page_buttons.push(button);
            }
        },
        setLeftSideButtons() {
            for (let i = 1; i <= 10; i++) {
                let button = this.createButton(i);

                this.page_buttons.push(button)
            }

            let button = this.createButton(0, false);
            this.page_buttons.push(button);

            button = this.createButton(this.last_page - 1);
            this.page_buttons.push(button);

            button = this.createButton(this.last_page);
            this.page_buttons.push(button);
        },
        setRightSideButtons() {
            let button = this.createButton(1);
            this.page_buttons.push(button);

            button = this.createButton(2);
            this.page_buttons.push(button);

            button = this.createButton(0, false);
            this.page_buttons.push(button);

            for (let i = this.last_page; i <= this.last_page; i++) {
                button = this.createButton(i);

                this.page_buttons.push(button);
            }
        },
        setMiddleButtons(){
            let button = this.createButton(1);
            this.page_buttons.push(button);

            button = this.createButton(2);
            this.page_buttons.push(button);

            button = this.createButton(0, false);
            this.page_buttons.push(button);

            for(let i = this.current_page -3; i <= this.current_page + 3; i++){
                button = this.createButton(i);

                this.page_buttons.push(button);
            }

            button = this.createButton(0, false);
            this.page_buttons.push(button);

            button = this.createButton(this.last_page - 1);
            this.page_buttons.push(button);

            button = this.createButton(this.last_page);
            this.page_buttons.push(button);
        },
        createButton(pageNumber, normalButton=true){
            let filterPath = this.getFilter();

            if(filterPath !== ''){
                filterPath = filterPath + '&';
            }

            return {
                'path': this.path + '?' + filterPath + 'page=' + pageNumber,
                'pageNumber': pageNumber,
                'isActualPage': this.current_page == pageNumber,
                'class': 'page-item' + (this.current_page == pageNumber ? ' active': '') + (!normalButton ? ' disabled' : ''),
                'disabledButton': !normalButton
            }
        },
        saveCount(finding, index){
            const data = {
                upc: finding.count_data.upc,
                count: finding.count_data.count,
                measurement_unit_id: finding.count_data.measurement_unit_id
            }

            let message = [];

            if(data['upc'] != null && data['upc'].length > 16){
                message.push('UPC length maximum is 16 characters');
            }

            if(data['count'] === '' || data['count'] == null) {
                message.push('Count is required');
            }

            if(data['count'] < 0){
                message.push('Quantity can not be a negative value');
            }

            if(data['measurement_unit_id'] === '' || data['measurement_unit_id'] == null){
                message.push('Measurement Unit field is required');
            }

            console.log(message.join());
            if(message.length !== 0){
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Line cannot be saved because: ' + message.join(' and '),
                });

                return;
            }

            const url = `/inventory_count/${this.$props.inventory_count.id}/findings/${finding.id}`

            if(data.upc == null){
                console.log("Failed")
                return;
            }

            if(data.count == null){
                console.log("Failed");
                return;
            }

            if(data.measurement_unit_id == null){
                console.log("Failed")
                return;
            }

            axios.post(url, data).then(response => {
                console.log("Saved");

                this.filtered_findings[index]['count_data']['user'] = response.data.user;
            })
        },
        deleteFinding(finding){
            let findingIndex = null;
            this.filtered_findings.forEach((filter_finding, index) => {
                if(filter_finding.id === finding.id){
                    findingIndex = index;

                    return;
                }
            });

            console.log("Delete: ", findingIndex)

            this.filtered_findings.splice(findingIndex, 1);
        }
    },
}
</script>

<style scoped>

</style>
