/**
 * First we will load all of this project's JavaScript dependencies which
 * includes React and other helpers. It's a great starting point while
 * building robust, powerful web applications using React + Laravel.
 */

global.$ = global.jQuery = require('jquery');


require('./bootstrap');

require('./forms-validation');
require('./product-warehouses');
require('./data-tables');
require('./tools-movement');

import 'select2';
import VoerroTagsInput from '@voerro/vue-tagsinput';
import Swal from 'sweetalert2';

// require('jquery-tags-input/src/jquery.tagsinput.css')

window.swal = require('sweetalert2')


$(function(){
    if ($(".select-box").length) {
        $(".select-box").select2({
            placeholder: "Select one",
        });
    }
});

$(function() {
    'use strict';

    if($('.date-picker').length) {
        var date = new Date();
        var today = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        $('.date-picker').datepicker({
            format: 'mm/dd/yyyy',
            useCurrent: false,
            todayHighlight: true,
            autoclose: true,
            orientation: 'bottom'
        });
    }
});
$(function() {
    'use strict';

    if($('.date-picker-limit').length) {
        var date = new Date();
        var today = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        var tomorrow = new Date(today)
        tomorrow.setDate(tomorrow.getDate() + 1)
        $('.date-picker-limit').datepicker({
            format: "mm/dd/yyyy",
            todayHighlight: true,
            autoclose: true,
            startDate: today,
            orientation: 'bottom'
        });
    }
});

$(function() {
    'use strict';

    if($('.date-picker-traffic').length) {
        $('.date-picker-traffic').datepicker({
            format: "mm/dd/yyyy",
            todayHighlight: true,
            autoclose: true,
        });
    }
});


//Todo:Poner en file aparte
import SignaturePad from "signature_pad";
$(function () {
    let wrapper = document.getElementById("signature-pad"),
        clearButton = wrapper.querySelector("[data-action=clear]"),
        saveButton = wrapper.querySelector("[data-action=save]"),
        canvas = wrapper.querySelector("canvas"),
        signaturePad;


    // Adjust canvas coordinate space taking into account pixel ratio,
    // to make it look crisp on mobile devices.
    // This also causes canvas to be cleared.
    window.resizeCanvas = function () {
        var ratio =  window.devicePixelRatio || 1;
        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio;
        canvas.getContext("2d").scale(ratio, ratio);
    }

    resizeCanvas();

    signaturePad = new SignaturePad(canvas);

    clearButton.addEventListener("click", function(event) {
        signaturePad.clear();
    });

    saveButton.addEventListener("click", function(event) {
        event.preventDefault();

        if (signaturePad.isEmpty()) {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Please provide a signature first.',
                showConfirmButton: false,
                timer: 3000,
                toast:true
            })
        } else {
            var dataUrl = signaturePad.toDataURL();
            var image_data = dataUrl.replace(/^data:image\/(png|jpg);base64,/, "");
            let store_order_id = document.getElementById("store_order_id");

            let attributes = {
                'image_data': image_data
            }

            axios.post("/store_orders/"+ store_order_id.value+"/installation/signatures",attributes).then(response =>{
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Signature was saved.',
                    showConfirmButton: false,
                    timer: 3000,
                    toast:true
                });

                location = response.data.message;
            });

        }
    });
});

window.Vue = require('vue');
import VModal from 'vue-js-modal'
import {CheckSquareIcon,InfoIcon,EditIcon,SearchIcon,ArrowUpIcon,ArrowDownIcon,ClipboardIcon} from "vue-feather-icons";
import Toasted from 'vue-toasted';


Vue.use(VModal)
Vue.use(Toasted)

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/TextEditor.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('project-assignment-form', require('./components/ProjectAssignmentForm').default);
Vue.component('distribution-order-component', require('./components/DistributionOrderComponent').default);
Vue.component('store-order-assignment-form', require('./components/StoreOrderAssignmentForm').default);
Vue.component('store-order-consolidated-assignment-form', require('./components/StoreOrderConsolidatedAssignmentForm').default);
Vue.component('select-user-multiple', require('./components/SelectUserMultiple').default);
Vue.component('warehouse-create-form', require('./components/WarehouseCreateForm.vue').default);
Vue.component('purchase-order-create-form', require('./components/PurchaseOrderCreateForm').default);
Vue.component('purchase-order-edit-form', require('./components/PurchaseOrderEditForm').default);
Vue.component('po-receive-product-form', require('./components/PurchaseOrderReceiveProductForm').default);
Vue.component('po-receive-tool-form', require('./components/POReceiveToolForm').default);
Vue.component('warehouse-edit-form', require('./components/WarehouseEditForm.vue').default);
Vue.component('select-box',require('./components/SelectBox').default)
Vue.component('select-sku',require('./components/SelectSKU').default)
Vue.component('select-tool-model',require('./components/SelectToolModel').default)
Vue.component('dropbox',require('./components/Dropbox').default)
Vue.component('dropbox-none',require('./components/DropboxNone').default)
Vue.component('serial-codes-modal',require('./components/SerialCodesModal').default)
Vue.component('checkout-component',require('./components/CheckOutComponent').default)
Vue.component('checkin-component',require('./components/CheckInComponent').default)
Vue.component('bom-create-form',require('./components/BomCreateForm').default)
Vue.component('bom-update-form',require('./components/BomUpdateForm').default)
Vue.component('project-return-component',require('./components/ProjectReturnComponent').default)
Vue.component('project-picking',require('./components/ProjectPickingComponent').default)
Vue.component('project-bom-update',require('./components/ProjectBomUpdate').default)
Vue.component('damaged-tool-component',require('./components/DamagedToolComponent').default)
Vue.component('damaged-product-component',require('./components/DamagedProductComponent').default)
Vue.component('warranty-tool-component',require('./components/WarrantyToolComponent').default)
Vue.component('rma-product-component',require('./components/RmaProductComponent').default)
Vue.component('admin-component',require('./components/AdminComponent').default)
Vue.component('dropdown-config-component',require('./components/DropdownConfigComponent').default)
Vue.component('checkout-modal',require('./components/CheckoutModal').default)
Vue.component('tool-inventory-box',require('./components/ToolInventoryBox').default)
Vue.component('project-return-modal',require('./components/ProjectReturnModal').default)
Vue.component('locations-modal',require('./components/LocationsModal').default)
Vue.component('select-record',require('./components/SelectSerialRecordMultiple').default)
Vue.component('store-order-picking',require('./components/StoreOrderPickingComponent').default)
Vue.component('store-order-consolidation-picking',require('./components/StoreOrderConsolidatedPickingComponent').default)
Vue.component('warehouse-allocation-component',require('./components/WarehouseAllocationComponent').default)
Vue.component('warehouse-serial-codes-modal',require('./components/MultipleWarehouseSerialCodesModal').default)
Vue.component('inventory-adjustment-component',require('./components/InventoryAdjustmentComponent').default)
Vue.component('project-installation-component',require('./components/ProjectInstallationComponent').default)
Vue.component('dispatch-products-modal',require('./components/DispatchProductsModal').default)
Vue.component('search-products-modal',require('./components/SearchProductsModal').default)
Vue.component('search-purchase-order-modal',require('./components/SearchPurchaseOrderModal').default)
Vue.component('change-store-order-date-modal',require('./components/ChangeStoreOrderDateModal').default)
Vue.component('edit-project-installation-component',require('./components/EditProjectInstallationComponent').default)
Vue.component('dropbox-bom',require('./components/DropboxBom').default)
Vue.component('validate-sku-scan',require('./components/ValidateSkuScan').default)
Vue.component('validate-sku',require('./components/ValidateSku').default)
Vue.component('inventory-count',require('./components/InventoryCount').default)
Vue.component('inventory-count-location-modal',require('./components/InventoryCountLocationModal').default)
Vue.component('dropbox-multiple',require('./components/DropboxMultiple').default)
Vue.component('inventory-count-execution', require('./components/InventoryCountExecution').default)
Vue.component('inventory-count-table', require('./components/InventoryCountTable').default)
Vue.component('inventory-count-add-finding', require('./components/InventoryCountAddFinding').default)
Vue.component('object-selection-modal', require('./components/ObjectSelectionModal').default)
Vue.component('findings-count', require('./components/FindingsCount').default)
Vue.component('findings-count-edit-modal', require('./components/FindingsCountEditModal').default)
Vue.component('inventory-count-table-edit-modal', require('./components/InventoryCountTableEditModal').default)
Vue.component('search-container-modal', require('./components/SearchContainerModal').default)
Vue.component('tags-input',VoerroTagsInput);
Vue.component('check-square-icon',CheckSquareIcon);
Vue.component('info-icon',InfoIcon);
Vue.component('edit-icon',EditIcon);
Vue.component('search-icon',SearchIcon);
Vue.component('arrow-up-icon',ArrowUpIcon);
Vue.component('arrow-down-icon',ArrowDownIcon);
Vue.component('clipboard-icon',ClipboardIcon);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */



const app = new Vue({
    el: '#app',
});


