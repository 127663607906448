<template>
    <div>
        <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
                <div class="card-header">
                    <div class="card-title">
                        <h6 class="mb-5">Creation</h6>
                        <input type="checkbox" v-model="form.all_selected" v-on:change="setAllInventoryCount($event)"> <span class="ml-2">All Inventory Count</span>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-4">
                            <h6 class="mb-2">Location</h6>
                            <div class="row">
                                <div class="col-12 row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="area">Area</label>
                                            <dropbox id="area" :options="$props.areas" v-model="form.selected_area" :disabled="form.all_selected"/>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="zone_type">Zone type</label>
                                            <dropbox id="zone_type" :options="zone_types" v-model="form.selected_zone_type" :disabled="form.all_selected"/>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label for="location_from">From</label>
                                        <input class="form-control" id="location_from" name="location_from"
                                               v-model="form.selected_location_from"
                                               :disabled="form.all_selected"
                                               list="selectedLocationFromList"/>
<!--                                        <datalist id="selectedLocationFromList">-->
<!--                                            <option v-for="level in $props.levels" :value="level.name"></option>-->
<!--                                        </datalist>-->
                                        <span type="button" style="color: blue" class="cursor-pinter my-2" v-on:click="openModal('from-location-modal')">Search Location From</span>
                                        <inventory-count-location-modal
                                            type="from"
                                            :key="form.selected_area + form.selected_zone_type + 'to'"
                                            :area="form.selected_area"
                                            :zone_type="form.selected_zone_type"
                                            @setLocation="setLocation"/>
                                    </div>
                                    <div class="col-md-6">
                                        <label for="location_to">To</label>
                                        <input class="form-control" id="location_to" name="location_to"
                                               v-model="form.selected_location_to"
                                               :disabled="form.all_selected"
                                               list="selectedLocationToList"/>
<!--                                        <datalist id="selectedLocationToList">-->
<!--                                            <option v-for="level in $props.levels" :value="level.name"></option>-->
<!--                                        </datalist>-->
                                        <span type="button" style="color: blue" class="cursor-pointer my-2" v-on:click="openModal('to-location-modal')">Search Location To</span>
                                        <inventory-count-location-modal
                                            type="to"
                                            :key="form.selected_area + form.selected_zone_type + 'to'"
                                            :level_lcoations="[]"
                                            :area="form.selected_area"
                                            :zone_type="form.selected_zone_type"
                                            @setLocation="setLocation"/>
                                    </div>

                                    <div class="col-12 mt-4">
                                        <div>
                                            <input type="checkbox" v-model="form.empty_location_only" :disabled="form.all_selected" > <span class="ml-2">Empty Location Only</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <h6 class="mb-2">Product Info</h6>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="category">Category</label>
                                        <dropbox-multiple id="category" :options="$props.categories" v-model="form.selected_categories" :disabled="form.all_selected" />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="sku">SKU</label>
                                        <dropbox-multiple id="sku" :options="$props.skus" v-model="form.selected_skus" :disabled="form.all_selected" />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="supplier">Supplier</label>
                                        <dropbox-multiple id="supplier" :options="$props.suppliers" v-model="form.selected_suppliers" :disabled="form.all_selected" />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="upc">UPC</label>
                                        <dropbox-multiple id="upc" :options="$props.upcs" v-model="form.selected_upcs" :disabled="form.all_selected" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <h6 class="mb-2">LPN Dates</h6>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>From:</label>
                                    <div class="input-group date datepicker dashboard-date mr-2 mb-2 mb-md-0 d-xl-flex">
                                        <span class="input-group-addon bg-transparent"><i data-feather="calendar" class="text-primary"></i></span>
                                        <datePicker  :style="[form.all_selected ? {'background': '#eeeeee'} : '']" autocomplete="off" name="dateFrom" :config="datePickerOptions" class="form-control" v-model="form.selected_lpn_from" :disabled="form.all_selected"/>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="date_completed">To:</label>

                                    <div class="input-group date datepicker dashboard-date mr-2 mb-2 mb-md-0 d-xl-flex">
                                        <span class="input-group-addon bg-transparent"><i data-feather="calendar" class="text-primary"></i></span>
                                        <datePicker :style="[form.all_selected ? {'background': '#eeeeee'} : '']"  autocomplete="off" name="dateTo" :config="datePickerOptions" class="form-control" v-model="form.selected_lpn_to" :disabled="form.all_selected" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="d-flex justify-content-end">
                                <button type="button" v-on:click="submit()" class="btn btn-primary">Create</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import datePicker from 'vue-bootstrap-datetimepicker';
export default {
    name: "InventoryCount",
    components:{
      datePicker
    },
    watch: {
        "form.selected_area":{
            handler (val){
                this.form.selected_location_from = "";
                this.form.selected_location_to = "";

            },
            deep:true
        },
        "form.selected_zone_type":{
            handler (val){
                this.form.selected_location_from = "";
                this.form.selected_location_to = "";

            },
            deep:true
        },
    },
    props: {
        categories: Array,
        skus: Array,
        suppliers: Array,
        upcs: Array,
        areas: Array,
        levels: Array
    },
    data(){
        return {
            zone_types: [{id:'null' ,text:'None'},{id:"Rack", name: "Rack"},{id:"Line", name: "Line"},{id:"Mezzanine", name: "Mezzanine"}],
            form:{
                all_selected: false,
                selected_categories: [],
                selected_skus: [],
                selected_suppliers: [],
                selected_upcs: [],
                selected_area: "",
                selected_zone_type: "",
                selected_location_from: "",
                selected_location_to: "",
                selected_lpn_to: "",
                selected_lpn_from: "",
                empty_location_only: false
            },
            datePickerOptions: {
                format: 'MM/DD/YYYY',
                useCurrent: false,
            }
        }
    },
    methods: {
        setLocation(type, level_label){
            if(type == 'from'){
                this.form.selected_location_from = level_label
            }else{
                this.form.selected_location_to = level_label
            }
        },
        submit(){
            const data = this.form;

            axios.post('/inventory_count',data).then((response) =>{
                console.log(response);
                location.reload();
            }).catch(error =>{
                console.log(error);
            })
        },
        openModal(name){
            if(!this.form.all_selected){
                this.$modal.show(name);
            }
        },
        setAllInventoryCount(event){
            const vm = this;
            if(event.target.checked){
                Swal.fire({
                    title: 'Are you sure?',
                    text: "This would generate an inventory count including all the locations and would ignore all the filters previously selected.",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Confirm'
                }).then((result) => {
                    console.log(result, !result.isConfirmed);
                    if (!result.isConfirmed) {
                        this.form.all_selected = false;
                    }
                })
            }
        }
    },

}
</script>

<style scoped>

</style>
