<template>
    <modal :name="$props.modal_name + '-object-selection-modal'" height="95%" width="90%">
        <div class="container modal-content border-bottom-0 h-100">
            <div class="modal-body">
                <div class="col-md-12">
                    <div>
                        <div class="card-body">
                            <div class="card-title">{{$props.title}}</div>
                            <div class="d-flex justify-content-between align-items-end mt-3">
                                <div>
                                    <label for="category">Show</label>
                                    <dropbox name="paginate"
                                             id="paginate"
                                             :options="pagination_options"
                                             v-model="pagination"
                                             class="custom-select custom-select-sm form-control"></dropbox>
                                </div>
                                <div class="d-flex align-self-end">
                                    <div class="mr-2">
                                        <label for="filter">Search</label>
                                        <input type="text"
                                               autocomplete="off"
                                               class="form-control"
                                               id="filter"
                                               name="filter"
                                               v-model="filter"
                                               placeholder="Search"
                                        >
                                    </div>
                                    <div class="form-group align-self-bottom">
                                        <button type="button" class="btn btn-primary" v-on:click="getData(path + '?' + getFilter())">Filter</button>
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive pt-3">
                                <table class="table table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th style="width: 10%">Select</th>
                                            <th>Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="data in filtered_data">
                                            <td>
                                                <chevrons-right-icon size="1.5x" v-on:click="select(data)" class="text-primary cursor-pointer"></chevrons-right-icon>
                                            </td>
                                            <td>
                                                {{data[$props.variable_name]}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="d-flex justify-content-between mt-3">
                                <div>
                                    Displaying {{filtered_data.length}} of {{total}}.
                                </div>
                                <div>
                                    <nav>
                                        <ul class="pagination">
                                            <li :class="prevButtonClass">
                                                <span v-if="prev_page_url == '' || prev_page_url == null" class="page-link">{{'<'}}</span>
                                                <button type="button" v-else class="page-link" v-on:click="getData(prev_page_url)">{{'<'}}</button>
                                            </li>
                                            <li v-for="(button,index) in page_buttons" :class="button.class">
                                                <span v-if="button.isActualPage" class="page-link">{{button.pageNumber}}</span>
                                                <span v-else-if="button.disabledButton" class="page-link">...</span>
                                                <button type="button" v-else class="page-link" v-on:click="getData(button.path)">{{button.pageNumber}}</button>
                                            </li>
                                            <li :class="nextButtonClass">
                                                <span v-if="next_page_url == '' || next_page_url == null" class="page-link">{{'>'}}</span>
                                                <button type="button" v-else class="page-link" v-on:click="getData(next_page_url)">{{'>'}}</button>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light" v-on:click="this.$modal.hide($props.modal_name + '-object-selection-modal')">Close</button>
            </div>
        </div>
    </modal>
</template>

<script>

import {ChevronsRightIcon} from 'vue-feather-icons'

export default {
    name: "ObjectSelectionModal",
    props:{
        path: String,
        filters: String,
        modal_name: String,
        variable_name: String,
        title: String
    },
    components: {
        ChevronsRightIcon
    },
    mounted(){
        this.getData(this.path + '?' + this.getFilter());
    },
    data(){
        return {
            pagination_options:[{id: "10", name:"10"}, {id: "30", name:"30"}, {id: "50", name:"50"}],
            filter: "",
            level_count: 0,
            current_page: 1,
            first_page_url: "",
            last_page: 1,
            last_page_url: "",
            next_page_url: "",
            path: this.$props.path,
            per_page: 1,
            to: 1,
            total: 1,
            pagination: 10,
            prevButtonClass: 'page-item ' + (this.prev_page_url !== null && this.prev_page_url !== '' ? '' : 'disabled'),
            nextButtonClass: 'page-item ' + (this.next_page_url !== null && this.next_page_url !== '' ? '' : 'disabled'),
            page_buttons: [],
            filtered_data: [],
            from: ''
        }
    },
    methods: {
        select(object){
            this.$emit('selectObject',object);
            this.$modal.hide(this.$props.modal_name + '-object-selection-modal')
        },
        getData(path){
            axios.get(path).then(response =>{
                this.setPaginationData(response.data);
            })
        },
        resetFilter(){
            this.filter = '';
            this.getData(this.path + '?' + this.getFilter())
        },
        setPaginationData(data){

            let filterPath = this.getFilter();

            if(filterPath !== ''){
                filterPath = filterPath + '&';
            }

            this.filtered_data = data.data.data;
            this.current_page = data.data.current_page;
            this.first_page_url = data.data.first_page_url;
            this.from = data.data.from;
            this.last_page = data.data.last_page;
            this.last_page_url = data.data.last_page_url == null ? null : this.path + '?' + filterPath + 'page=' + (this.current_page + 1);
            this.next_page_url = data.data.next_page_url;
            this.per_page = data.data.per_page;
            this.prev_page_url = data.data.prev_page_url == null ? null : this.path + '?' + filterPath + "page=" + (this.current_page - 1);
            this.to = data.data.to;
            this.total = data.data.total;
            this.filter = data.filter;
            this.paginate = data.paginate;
            this.prevButtonClass = 'page-item ' + (this.prev_page_url !== null && this.prev_page_url !== '' ? '' : 'disabled');
            this.nextButtonClass = 'page-item ' + (this.next_page_url !== null && this.next_page_url !== '' ? '' : 'disabled');

            this.setButtons();
        },
        getFilter(){
            const parentFilters = this.$props.filters;
            return (this.filter != null && this.filter !== '' ? 'filter=' + this.filter + parentFilters : '' + parentFilters)
        },
        setButtons(){
            this.page_buttons = [];
            if(this.last_page > 13){
                if(this.current_page < 8){
                    this.setLeftSideButtons();
                }else if(this.last_page - this.current_page < 7){
                    this.setRightSideButtons();
                }else{
                    this.setMiddleButtons();
                }
            }else{
                this.setNormalButtons();
            }
        },setNormalButtons(){
            for(let i = 1; i <= this.last_page; i++){
                let button = this.createButton(i);

                this.page_buttons.push(button);
            }
        },
        setLeftSideButtons(){
            for(let i = 1; i <= 10; i++){
                let button = this.createButton(i);

                this.page_buttons.push(button);
            }

            let button = this.createButton(0, false);
            this.page_buttons.push(button);

            button = this.createButton(this.last_page - 1);
            this.page_buttons.push(button);

            button = this.createButton(this.last_page);
            this.page_buttons.push(button);
        },
        setRightSideButtons(){
            let button = this.createButton(1);
            this.page_buttons.push(button);

            button = this.createButton(2);
            this.page_buttons.push(button);

            button = this.createButton(0, false);
            this.page_buttons.push(button);

            for(let i = this.last_page - 9; i <= this.last_page; i++){
                button = this.createButton(i);

                this.page_buttons.push(button);
            }
        },
        setMiddleButtons(){
            let button = this.createButton(1);
            this.page_buttons.push(button);

            button = this.createButton(2);
            this.page_buttons.push(button);

            button = this.createButton(0, false);

            for(let i = this.current_page -3; i <= this.current_page + 3; i++){
                button = this.createButton(i);

                this.page_buttons.push(button);
            }

            button = this.createButton(0, false);
            this.page_buttons.push(button);

            button = this.createButton(this.last_page - 1);
            this.page_buttons.push(button);

            button = this.createButton(this.last_page);
            this.page_buttons.push(button);
        },
        createButton(pageNumber, normalButton=true){
            let filterPath = this.getFilter();

            if(filterPath !== ''){
                filterPath = filterPath + '&'
            }

            return {
                'path': this.path + '?' + filterPath + 'page=' + pageNumber,
                'pageNumber': pageNumber,
                'isActualPage': this.current_page == pageNumber,
                'class': 'page-item' + (this.current_page == pageNumber ? ' active':'') + (!normalButton ? ' disabled' : ''),
                'disabledButton': !normalButton
            }
        }
    }
}
</script>

<style scoped>

</style>
