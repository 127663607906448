<template>
    <modal :name="index+'-tool-modal'" height="auto" :scrollable="true">
        <div class="modal-content border-bottom-0 h-100">
            <div class="modal-header">
                <div>
                    <div class="row justify-content-between">
                        <h5 class="modal-title">{{tool.description}}</h5>
                    </div>
                </div>
            </div>
            <div class="modal-body">
                <div class="mb-3">
                    <tool-inventory-box name="category" id="category" :options="this.$props.tool.items" v-model="inventory_id">
                        <option></option>
                    </tool-inventory-box>
                </div>
                <div class="form-group">
                    <label for="comments">Comments</label>
                    <input type="text" class="form-control" name="comments" placeholder="Comments" v-model="comment">
                </div>
                <div class="form-group">
                    <label for="crew">Crew<span class="required_asterisk">*</span></label>
                    <dropbox name="crew" id="crew" :options="crews" v-model="crew">
                        <option></option>
                    </dropbox>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light" @click="$modal.hide(index+'-tool-modal')">Close</button>
                <button type="button" class="btn btn-primary mr-2" v-on:click="addToOut">Check Out Tool</button>
            </div>
        </div>
    </modal>
</template>

<script>
    import Swal from "sweetalert2";

    export default {
        name: "CheckoutModal",
        props: ['tool','index','tool_out','crews'],
        mounted() {
            axios.get('/tools/'+this.$props.tool.id+'/checkout_inventory').then(response=>{
                this.$props.tool['items'] = response.data.inventory;
                this.inventory = this.$props.tool.items
            });
        },
        data(){
            return{
                inventory:[],
                inventory_id:'',
                comment: '',
                crew: ''

            }
        },
        methods:{
            addToOut(id,index,comment) {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2500
                });

                if (this.crew == "") {
                    Toast.fire({
                        icon: 'error',
                        title: 'Please select a crew.'
                    })
                } else {

                    let toolToOut = this.$props.tool.items.find(tool => tool.id == this.inventory_id);

                    toolToOut.comment = this.comment;
                    toolToOut.crew = this.crew;

                    this.$props.tool.items = this.$props.tool.items.filter(tool => tool.id != this.inventory_id);

                    this.$props.tool_out.push(toolToOut);

                    this.inventory_id = '';
                    this.comment = '';
                    this.crew = '';

                    Toast.fire({
                        icon: 'success',
                        title: 'Tool ' + toolToOut.tool_number + ' added to checkout list.'
                    })
                    this.$modal.hide(this.$props.index + '-tool-modal')
                }
            },
            deleteSerial(index){
                // this.$props.product.serial_codes.splice(index,1);
            }
        }
    }
</script>

<style scoped>

</style>

