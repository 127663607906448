import { render, staticRenderFns } from "./PurchaseOrderCreateForm.vue?vue&type=template&id=52688899&scoped=true&"
import script from "./PurchaseOrderCreateForm.vue?vue&type=script&lang=js&"
export * from "./PurchaseOrderCreateForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52688899",
  null
  
)

export default component.exports