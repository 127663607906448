<template>
    <modal :name="index+'-validate-sku-scan'" height="auto" :scrollable="true">
        <div class="modal-content border-bottom-0 h-100">
            <div class="modal-header">
                <div>
                    <div class="row justify-content-between">
                        <h6 class="mr-2 p-2">SKU Validation</h6>
                    </div>
                </div>
            </div>
            <div class="modal-body">
                <div>
                    <div class="p-0 m-0" style="height: 50vh; width: 50vm">
                        <v-quagga class="w-100 h-100" :onDetected="addSerialScan" :readerSize="readerSize" :readerTypes="readerTypes"></v-quagga>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light" @click="$modal.hide(index+'-validate-sku-scan')">Close</button>
<!--                <button type="button" class="btn btn-primary mr-2" v-if="screen === 'input'" v-on:click="addSerial">Add Serial Number</button>-->
            </div>
        </div>
    </modal>
</template>

<script>
    import Swal from "sweetalert2";
    import VueQuagga from 'vue-quaggajs';

    Vue.use(VueQuagga);

    export default {
        name: "ValidateSkuScan",
        props: ['sku','index',],
        mounted() {

        },
        components:{
            VueQuagga,
        },

        data(){
            return{
                readerSize: {
                    width: 100,
                    height: 100
                },
                readerTypes:['code_128_reader',
                    'ean_reader',
                    'ean_8_reader',
                    'code_39_reader',
                    'code_39_vin_reader',
                    'codabar_reader',
                    'upc_reader',
                    'upc_e_reader',
                    'i2of5_reader',
                    '2of5_reader',
                    'code_93_reader'],
                detecteds: [],
                screen:"input",
                isInput: true,
                isList: false,
                isScan: false,
                serial: ""
            }
        },
        methods:{
            logIt (data) {
                console.log('detected', data.codeResult.code)
            },
            addSerialScan(data){
                let component = this;
                this.serial = data.codeResult.code

                if((this.serial !== "" && this.serial !== null)){
                    this.$emit('validateScan', this.serial)
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'center',
                        showConfirmButton: false,
                        timer: 2500
                    });
                    Toast.fire({
                        icon: 'success',
                        title: 'Serial number '+ this.serial+ ' added to list.'
                    })
                }
                this.serial = "";
            },
            // addSerial(){
            //     let component = this;
            //     let serials = this.serial.split(',');
            //     serials.forEach(serial => {
            //         let repeated_serial = this.$props.product.serial_codes.find(code => code === serial);
            //         if (this.$props.quantity === '' || this.$props.product.serial_codes.length >= parseInt(this.$props.quantity)) {
            //             Swal.fire({
            //                 icon: 'error',
            //                 title: 'Oops...',
            //                 text: 'You have all the serial numbers for the product ' + component.$props.product.name + '. If you need to add more increase the field "Quantity Received".',
            //             });
            //         } else if (repeated_serial != null) {
            //             Swal.fire({
            //                 icon: 'error',
            //                 title: 'Oops...',
            //                 text: 'Serial Number already inserted.',
            //             });
            //         } else if ((serial !== "" && serial !== null)) {
            //             this.$props.product.serial_codes.push(serial);
            //             const Toast = Swal.mixin({
            //                 toast: true,
            //                 position: 'center',
            //                 showConfirmButton: false,
            //                 timer: 2500
            //             });
            //             Toast.fire({
            //                 icon: 'success',
            //                 title: 'Serial number added to list.'
            //             })
            //         }
            //
            //     })
            //     this.serial = "";
            // },

            // deleteSerial(index){
            //     this.$forceUpdate();
            //     this.$props.product.serial_codes.splice(index,1);
            // }
        }
    }
</script>

<style scoped>

</style>
