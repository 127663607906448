<template>
    <modal :name="index+ '-' + zone_index + '-location-modal'" height="80%" width="90%" :scrollable="true">
        <div class="modal-content border-bottom-0 h-100">
            <div class="modal-header">
                <div>
                    <div class="row justify-content-between">
                        <div class="col-md-12">
                            <h5 class="modal-title">{{zone.name + ' - ' + zone.type}}</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-body h-80">
                <div class="row justify-content-center" v-if="zone.locations.length < 1">
                    <h4>No Locations yet...</h4>
                </div>
                <div class="row h-100">
                    <div class="mb-3 col-md-4" style="overflow: auto; overflow-x: hidden; height: 100%">
                        <div style="overflow: auto; overflow-x: hidden; max-height: 100%">
                            <div v-for="(location,location_index) in zone.locations">
                                <div class="row card">
                                    <div class="form-group px-4">
                                        <label :for="'aisle'+location_index">Location {{location_index + 1}}</label>
                                        <div class="row align-items-center align-middle">
                                            <div class="col-sm-11">
                                                <label for="location_name">Location Name</label>
                                                <input type="text" class="form-control" :name="'location'+location_index" :id="'aisle'+location_index" placeholder="Location" v-model="location.name">
                                            </div>
                                        </div>
                                        <div class="col-12 mt-2">
                                            <div class="row justify-content-between w-100">
                                                <p style="color:blue; cursor: pointer"  v-on:click="editLevels(location_index)">Edit Levels</p>
                                                <p class="ml-2" style="color:red; cursor: pointer"  v-on:click="deleteLocation(location_index)">Delete Location</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-8" style="overflow: auto; overflow-x: hidden; max-height: 100%">
                        <div >
                            <div class="row" v-if="zone.locations.length > 0">
                                <div class="col-12">
                                    <h4>Location - {{zone.locations[selected_index].name}} - Levels</h4>
                                </div>
                                <div class="col-12 my-2">
                                    <button type="button" v-on:click="addLevel()" class="btn btn-primary">Add New Level</button>
                                </div>
                                <div class="row w-100">
                                    <div class="col-md-3 col-sm-4 col-xs-6 mb-1" v-for="(level,level_index) in zone.locations[selected_index].levels">
                                        <div class="card p-2 shadow">
                                            <div class="form-group p-4">
                                                <label :for="'aisle'+level_index">Level {{level_index + 1}}</label>
                                                <div class="row align-items-center align-middle">
                                                    <div class="col-sm-11">
                                                        <label for="level_name">Level Name</label>
                                                        <input type="text" class="form-control" :name="'location'+level_index" :id="'aisle'+level_index" placeholder="Location" v-model="level.name">
                                                    </div>
                                                    <div class="col-12 mt-2">
                                                        <div class="row justify-content-between w-100">
                                                            <p class="ml-2" style="color:red; cursor: pointer"  v-on:click="deleteLevel(selected_index,level_index)">Delete Location</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light" @click="$modal.hide(index + '-' + zone_index + '-location-modal')">Close</button>
                <button type="button" v-on:click="addLocation()" class="btn btn-primary">Add New Location</button>
            </div>
        </div>
    </modal>
</template>

<script>
    import Swal from "sweetalert2";

    export default {
        name: "LocationsModal",
        props: ['zone','index','zone_index'],
        mounted() {

        },
        data(){
            return{
                name_length: 2,
                selected_index: 0

            }
        },
        methods:{
            addLocation(){
                let name = '' + (this.zone.locations.length + 1);
                name = this.addZeros(name);
                this.$props.zone.locations.push({"name":name, levels:[]})
            },
            deleteLocation(index){

                const confirmSwal = Swal.mixin({
                    customClass: {
                        confirmButton: 'btn btn-success',
                        cancelButton: 'btn btn-danger'
                    }
                });

                confirmSwal.fire({
                    title: 'Are you sure?',
                    text: 'Do you want to delete this level?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonClass: 'ml-2',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'Cancel',
                    reverseButtons: true
                }).then((result) => {
                    if(result.value){
                        if(this.zone.locations[index].id != null){

                            let attributes = {
                                'id':this.zone.locations[index].id
                            }
                            axios.delete("/masterdata/locations/" + this.zone.locations[index].id, attributes).then(response =>{
                                if(response.data.message === "Deleted"){
                                    this.zone.locations.splice(index,1);
                                }else{
                                    Swal.fire({
                                        icon: 'error',
                                        title: 'Oops...',
                                        text: response.data.message
                                    })
                                }
                            }).catch(error =>{

                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: error
                                });
                            })
                        }else{
                            this.zone.locations.slice(index,1);
                        }
                    }
                })
            },
            deleteLevel(locationIndex,levelIndex){

                const confirmSwal = Swal.mixin({
                    customClass: {
                        confirmButton: 'btn btn-success',
                        cancelButton: 'btn btn-danger'
                    }
                });

                confirmSwal.fire({
                    title: 'Are you sure?',
                    text: 'Do you want to delete this location?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonClass: 'ml-2',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'Cancel',
                    reverseButtons: true
                }).then((result) => {
                    if(result.value){
                        if(this.zone.locations[locationIndex].levels[levelIndex].id != null){

                            let attributes = {
                                'id':this.zone.locations[locationIndex].levels[levelIndex].id
                            }
                            axios.delete("/masterdata/levels/" + this.zone.locations[locationIndex].levels[levelIndex].id, attributes).then(response =>{
                                if(response.data.message === "Deleted"){
                                    this.zone.locations[locationIndex].levels.splice(levelIndex,1);
                                }else{
                                    Swal.fire({
                                        icon: 'error',
                                        title: 'Oops...',
                                        text: response.data.message
                                    })
                                }
                            }).catch(error =>{

                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: error
                                });
                            })
                        }else{
                            this.zone.locations[locationIndex].levels.splice(levelIndex,1);
                        }
                    }
                })
            },
            addZeros(string){

                while(string.length < this.name_length){
                    string = '0' + string;
                }

                return string;
            },
            editLevels(index){
                this.selected_index = index;
            },
            addLevel(){
                let name = '' + (this.zone.locations[this.selected_index].levels.length + 1);
                name = this.addZeros(name);
                this.zone.locations[this.selected_index].levels.push({name:name})
            }
        }
    }
</script>

<style scoped>

</style>

