<template>
    <div>
        <form @submit.prevent="submit" id="tool-checkin-form" name="tool-checkin-form" :ref="'tool-checkin-form'">
            <div class="col-md-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h6 class="card-title">Damaged Tools Details</h6>

                        <div class="mb-4 row">
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-4">
                                        Category:
                                    </div>
                                    <div class="col-md-6">
                                        {{tool.category.name}}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        Description:
                                    </div>
                                    <div class="col-md-6">
                                        {{tool.description}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-4">
                                        Brand Name:
                                    </div>
                                    <div class="col-md-6">
                                        {{tool.brand.name}}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        Model:
                                    </div>
                                    <div class="col-md-6">
                                        {{tool.model}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="table-responsive pt-3">
                            <table class="table table-bordered table-hover data-table">
                                <thead>
                                <tr>
                                    <th>
                                        Add to Write-Off List
                                    </th>
                                    <th>
                                       Tool Number
                                    </th>
                                    <th>
                                        Serial Number
                                    </th>
                                    <th>
                                        Crew Supervisor
                                    </th>
                                    <th>
                                        Crew
                                    </th>
                                    <th>
                                        Check In Date
                                    </th>
                                    <th>
                                        Return Reason
                                    </th>
                                    <th>
                                        Quality Check
                                    </th>
                                    <th>
                                        Comment
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(item,index) in this.tool_out">
                                    <td>
                                        <button type="button" class="btn btn-primary btn-icon "  v-on:click="addModal(item.id,index)">
                                            <log-out-icon size="1.5x" style="color: white"></log-out-icon>
                                        </button>
                                    </td>
                                    <td>
                                        {{item.tool_number}}
                                    </td>
                                    <td>
                                        {{item.serial_code}}
                                    </td>
<!--                                    TODO: Esta forma de buscar el ultimo no deberia ser asi. -->
                                    <td>
                                        {{item.histories.length > 0 ? item.histories[item.histories.length - 1].supervisor.first_name + ' ' + item.histories[item.histories.length - 1].supervisor.last_name : ""}}
                                    </td>
                                    <td>
                                        {{item.histories.length > 0 ? item.histories[item.histories.length - 1].crew.name : ""}}
                                    </td>
                                    <td>
                                        {{item.histories.length > 0 ? item.histories[item.histories.length - 1].check_in_date : ""}}
                                    </td>
                                    <td>
                                        {{item.histories.length > 0 ? item.histories[item.histories.length - 1].return_reason : ""}}
                                    </td>
                                    <td>
                                        {{item.quality_check.name}}
                                    </td>
                                    <td>
                                        {{item.histories.length > 0 ? item.histories[item.histories.length - 1].comment : ""}}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <br>
                        <h6 class="card-subtitle mb-2 text-muted">Tools to Write-Off</h6>
                        <div class="table-responsive pt-3">
                            <table class="table table-bordered table-hover">
                                <thead>
                                <tr>
                                    <th>
                                        Remove from list
                                    </th>
                                    <th>
                                        Tool Number
                                    </th>
                                    <th>
                                        Serial Number
                                    </th>
                                    <th>
                                        Crew Supervisor
                                    </th>
                                    <th>
                                        Crew
                                    </th>
                                    <th>
                                        Write-off Date
                                    </th>
                                    <th>
                                        Write Off Reason
                                    </th>
                                    <th>
                                        Comment
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(item,index) in tool_in">
                                    <td>
                                        <button type="button" class="btn btn-primary btn-icon "  v-on:click="removeFromIn(item.id,index)">
                                            <log-in-icon size="1.5x" style="color: white"></log-in-icon>
                                        </button>
                                    </td>
                                    <td>
                                        {{item.tool_number}}
                                    </td>
                                    <td>
                                        {{item.serial_code}}
                                    </td>
                                    <td>
                                        {{item.histories.length > 0 ? item.histories[item.histories.length - 1].supervisor.first_name + ' ' + item.histories[item.histories.length - 1].supervisor.last_name : ""}}
                                    </td>
                                    <td>
                                        {{item.histories.length > 0 ? item.histories[item.histories.length - 1].crew.name : ""}}
                                    </td>
                                    <td>
                                        {{form.writeoff_date}}
                                    </td>
                                    <td>
                                        {{reasons.find(reason=>reason.id == item.write_off_reason).name}}
                                    </td>
                                    <td>
                                        {{item.comment}}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="mt-5">
                            <button type="submit" class="btn btn-primary mr-2">Submit</button>
                            <a href="/accounting/tools/damaged" class="btn btn-light">Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    import Swal from "sweetalert2";
    import 'jquery-validation';
    import {LogInIcon, LogOutIcon} from 'vue-feather-icons';
    import datePicker from 'vue-bootstrap-datetimepicker';
    import moment from 'moment';

    export default {
        name: "DamagedToolComponent",
        props: ['tool','inventory','reasons'],
        components:{
            LogOutIcon, LogInIcon,
            datePicker
        },
        data(){
            return{
                tool_in: [],
                tool_out:this.$props.inventory,
                form:{
                    tools:[],
                    writeoff_date: moment().format('DD/MM/YYYY')

                },
                datePickerOptions:{
                    format: 'DD/MM/YYYY'
                },
                moment: moment
            }
        },
        mounted() {
            $("form[name='tool-checkin-form']").validate({
                rules: {
                    check_in_date: {
                        required: true,
                    },
                },
                errorPlacement: function(label, element) {
                    if(['check_in_date'].includes(element[0].name)){
                        label.addClass('mt-2 text-danger');
                        label.insertAfter(element.next());
                    }else{
                        label.addClass('mt-2 text-danger');
                        label.insertAfter(element);
                    }
                },
                highlight: function(element, errorClass) {
                    $(element).parent().addClass('has-danger')
                    $(element).addClass('form-control-danger')
                },
                unhighlight: function(element, errorClass, validClass) {
                    $(element).parent().removeClass('has-danger')
                    $(element).removeClass('form-control-danger')
                },
                submitHandler: function(form){
                    this.submit()
                }
            });
        },
        methods:{
            submit(){
                this.form.tools = this.tool_in;
                let attributes = this.form;

                axios.post('/accounting/tools/1/damaged',attributes).then(response => {
                    location = response.data.message;
                });
            },
            addModal(id,index){
                let write_off_reason = '';
                let reasons_options = '';
                let comment = '';

                this.$props.reasons.forEach(reason => {
                    reasons_options += '<option value="'+reason.id+'">'+reason.name+'</option>'
                });

                Swal.fire({
                    title: 'Tool to Write-Off',
                    html:

                        '<label for="write_off_reason">Write-Off Reason<span class="required_asterisk">*</span></label>'+
                        '<select id="write_off_reason" class="swal2-input">'+
                        reasons_options +
                        '</select>' +
                        '<label for="comment">Comment<span class="required_asterisk">*</span></label>'+
                        '<input id="comment" class="swal2-input">'

                    ,
                    focusConfirm: false,
                    preConfirm: () => {
                        write_off_reason = document.getElementById('write_off_reason').value;
                        comment = document.getElementById('comment').value;

                        this.addToIn(id,index,write_off_reason,comment)
                    }
                });
            },
            addToIn(id,index,write_off_reason,comment){
                let toolToIn = this.tool_out.find(tool=> tool.id == id);

                toolToIn.write_off_reason = write_off_reason;
                toolToIn.comment = comment;

                this.tool_out.splice(index,1);

                this.tool_in.push(toolToIn);
            },
            removeFromIn(id,index){
                let toolToOut = this.tool_in.find(tool=> tool.id == id);

                this.tool_in.splice(index,1);
                toolToOut.return_reason = ''
                toolToOut.comment = ''
                this.tool_out.push(toolToOut);
            },
        }
    }
</script>

<style scoped>

</style>
