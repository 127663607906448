<template>
    <div>
        <form @submit.prevent="submit" id="po-form" name="po-form" :ref="'po-form'">
            <div class="row col-md-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title"><p>Picking List</p></div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="table-responsive pt-3">
                                <div class="overflow-auto">
                                    <table class="table table-bordered table-hover date-columns" id="new-projects">
                                        <thead>
                                        <tr>
                                            <th style="width: 10%;">
                                                Product ID
                                            </th>
                                            <th>
                                                Product
                                            </th>
                                            <th>
                                                Description
                                            </th>
                                            <th>
                                                Unit of Measurement
                                            </th>
                                            <th>
                                                Location
                                            </th>
                                            <th>
                                                LPN
                                            </th>
                                            <th style="width: 10%;">
                                                Amount
                                            </th>
                                            <th>
                                                Qty. Picked
                                            </th>
                                            <th>
                                                Scan Sku
                                            </th>
                                            <th>
                                                Comments
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(item,index) in form.picking_list">
                                            <td style="width: 10%;">
                                                {{item.product.sku}}
                                            </td>
                                            <td style="width: 15%">
                                                {{item.product.name}}
                                            </td>
                                            <td style="width: 15%">
                                                {{item.product.description}}
                                            </td>
                                            <td style="width: 10%">
                                                {{item.product.measure_unit}}
                                            </td>
                                            <td style="width: 10%">
                                                {{get_aisle(item)}}
                                            </td>
                                            <td>
                                                {{item.license_plate != null ? item.license_plate.lpn : 'Not enough inventory'}}
                                            </td>
                                            <td style="width: 10%;">
                                                {{item.quantity_to_pick}}
                                            </td>
                                            <td style="width: 10%;">
                                                <input type="number" oninput="validity.valid||(value='')" min="0" class="form-control" name="quantity"  placeholder="####" v-model="item.quantity_picked">
                                            </td>
                                            <td>
                                                <input v-model="item.sku2"  v-on:keydown.enter.prevent='validateSKU(item.sku2,index)'  @change="validateSKU(item.sku2,index)" type="text" class="form-control" name="sku" placeholder="sku">
                                            </td>
                                            <td style="width: 10%;">
                                                <input type="text" class="form-control" name="comment" placeholder="Comments" v-model="item.comments">
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="spinner-border text-primary my-3" v-if="submit_disabled" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="row mt-4">
                            <button :disabled="submit_disabled" type="submit" class="btn btn-primary mr-2">Submit</button>
                            <a href="/store_orders/picking" class="btn btn-light">Cancel</a>
                        </div>
                    </div>
                </div>
            </div>

        </form>
    </div>
</template>

<script>
    import Swal from "sweetalert2";

    export default {
        name: "ProjectPickingComponent",
        props: ['picking'],
        beforeMount(){
            console.log(this.$props.picking)
        },
        mounted(){
            this.form.picking_list.forEach(product =>{
                product.quantity_picked = product.quantity_to_pick;
            })
        },
        data(){
            return{
                form:{
                    picking_list: this.$props.picking.picking_list
                },
                submit_disabled: false
            }
        },
        methods:{
            validateSKU(sku, index) {
                if (this.form.picking_list[index].product.sku != '') {
                    if (sku === this.form.picking_list[index].product.sku) {
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 1500
                        });
                        Toast.fire({
                            icon: 'success',
                            title: 'Match'
                        })
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'SKU does not match',
                        })
                        this.form.picking_list[index].sku2 = '';
                    }

                }
            },
            submit(){
                let haveQuantity = true;
                let diffQuantityComment = false;
                let products = [];
                let serials_success = true;
                let bad_serials_list = '';

                this.submit_disabled = true;
                 console.log("Validations")


                this.form.picking_list.forEach(product => {
                    if(product.quantity_picked == null || product.quantity_picked == ""){

                        console.log(product.quantity_picked);
                        haveQuantity = false;
                    }
                    if((product.quantity_picked != product.quantity_to_pick) && (product.comments == null || product.comments == "")){
                        products.push(product.sku)
                        diffQuantityComment = true;
                    }
                })

                if(!haveQuantity){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Input the quantity for all the products.',
                    });
                    this.submit_disabled = false;

                } else if(diffQuantityComment){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: "Please provide comment for difference in Qty. Picked for these products: " + products.toString(),
                    });
                    this.submit_disabled = false;

                }else{
                    axios.post('/store_orders/'+this.$props.picking.id+'/picking/',this.form).then(response => {
                        if(response.data.error == 404){
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: response.data.message,
                            });
                            this.submit_disabled = false;
                        }else{
                            location = response.data.message;
                        }
                    }).catch(error =>{
                        if(error.response.status === 403){
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'There was a problem.',
                            });
                        }
                        this.submit_disabled = false;
                    });
                }
            },
            check_serials(product_id){
                axios.get('/products/'+product_id+'/serials/').then(response => {
                    let product = this.form.products.find(product=> product.id == product_id)
                    product.use_serials = response.data.value;
                    if(product.use_serials){
                        product['serial_codes'] = [];
                        product['qty_received'] = product.pivot.quantity;
                        product.quantity = product.pivot.quantity;
                    }
                }).catch(error =>{
                    this.form.products.find(product=> product.id == product_id).use_serials = false

                });
            },
            get_aisle(item){

                if(item.license_plate != null && item.license_plate.level != null){
                    return item.license_plate.level.location.zone.area.name +
                        this.getZoneName(item.license_plate.level.location.zone) + '-' +
                        item.license_plate.level.location.name + '-' +
                        item.license_plate.level.name;
                }

                return "N/A";
            },
            getZoneName(zone){
                if(zone.type === "Mezzanine"){
                    return "M" + zone.name
                }else if(zone.type === "Line"){
                    return "L" + zone.name
                }else{
                    return zone.name
                }
            }
        }
    }
</script>

<style scoped>

</style>
