<template>
    <div class="row">
        <div class="col-8">
            <div class="form-group">
                <label for="order_qty">SKU<span class="required_asterisk">*</span></label>
                <input type="text" v-on:keyup="validateSku(sku_text)" class="form-control" v-model="sku_text" name="product" id="order_qty" placeholder="SKU">
            </div>
            <p v-if="!same_sku && sku_text !== ''" style="color: red">The SKU is incorrect.</p>
            <p v-else-if="same_sku && sku_text !== ''" style="color: green">SKU validated.</p>
        </div>
        <div class="col-4 row align-items-center">
            <button type="button" @click="$modal.show(index+'-validate-sku-scan')" class="btn btn-primary">Scan</button>
        </div>
        <validate-sku-scan @validateSku="validateSku" :sku="sku_text" :index="index"></validate-sku-scan>
    </div>
</template>

<script>
    export default {
        name: "ValidateSku",
        props: ["sku","index"],
        data(){
            return {
                sku_text:"",
                same_sku: false,
            }
        },
        mounted() {
            // Livewire.emit('updateSku',8);
            console.dir()
        },
        methods:{
            validateSku(text){
                this.same_sku = text == this.$props.sku;
            },
            validateScan(text){
                this.sku_text = text;
                this.validateSku(text);
            }
        }
    }
</script>


<style scoped>

</style>
