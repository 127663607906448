import { render, staticRenderFns } from "./DamagedProductComponent.vue?vue&type=template&id=d462eaa8&scoped=true&"
import script from "./DamagedProductComponent.vue?vue&type=script&lang=js&"
export * from "./DamagedProductComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d462eaa8",
  null
  
)

export default component.exports