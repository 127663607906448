<template>
    <div>
        <form @submit.prevent="submit" id="product-return-form" name="product-return-form" :ref="'product-return-form'">
            <div class="col-md-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h6 class="card-title">Returns</h6>

                        <h6 class="card-subtitle mb-2 text-muted">Dispatched Inventory</h6>
                        <div class="table-responsive pt-3">
                            <table class="table table-bordered table-hover data-table">
                                <thead>
                                <tr>
                                    <th>
                                        Add to list
                                    </th>
                                    <th>
                                        Product ID
                                    </th>
                                    <th>
                                        Product
                                    </th>
                                    <th>
                                        Warehouse Name
                                    </th>
<!--                                    <th>-->
<!--                                        Location-->
<!--                                    </th>-->
                                    <th>
                                        Dispatched Qty.
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(item,index) in this.$props.dispatched_inventory">
                                    <td>
                                        <button type="button" class="btn btn-primary btn-icon "  @click="$modal.show(index+'-return-modal')">
                                            <log-out-icon size="1.5x" style="color: white"></log-out-icon>
                                        </button>
                                    </td>
                                    <td>
                                        {{item.sku}}
                                    </td>
                                    <td>
                                        {{item.name}}
                                    </td>
                                    <td>
                                        {{warehouses.find(warehouse => warehouse.id == item.pivot.warehouse_id).name}}
                                    </td>
<!--                                    <td>-->
<!--                                        {{item.location}}-->
<!--                                    </td>-->
                                    <td>
                                        {{item.pivot.quantity_picked}}
                                    </td>
                                    <project-return-modal
                                        :index="index"
                                        :qualities="quality"
                                        :product_returns="product_returns"
                                        :dispatched_inventory="dispatched_inventory"
                                        :dispatched_record="dispatched_record.filter(record => record.product_id == item.id)"
                                        :product="item"></project-return-modal>

                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <button type="button" class="btn btn-secondary mr-2 my-4" @click="return_all">Return All</button>
                        <br>
                        <h6 class="card-subtitle mb-2 text-muted">Returns</h6>
                        <div class="table-responsive pt-3">
                            <table class="table table-bordered table-hover">
                                <thead>
                                <tr>
                                    <th>
                                        Remove
                                    </th>
                                    <th>
                                        Product ID
                                    </th>
                                    <th>
                                        Product
                                    </th>
                                    <th>
                                        Warehouse Name
                                    </th>
<!--                                    <th>-->
<!--                                        Location-->
<!--                                    </th>-->
                                    <th>
                                        Dispatched Qty.
                                    </th>
                                    <th>
                                        Returned Qty.
                                    </th>
                                    <th>
                                        Quality Check
                                    </th>
                                    <th>
                                        Reason
                                    </th>
                                    <th>
                                        Serial Numbers
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(item,index) in product_returns">
                                    <td>
                                        <button type="button" class="btn btn-primary btn-icon "  v-on:click="removeFromIn(item.id,index)">
                                            <log-in-icon size="1.5x" style="color: white"></log-in-icon>
                                        </button>
                                    </td>
                                    <td>
                                        {{item.sku}}
                                    </td>
                                    <td>
                                        {{item.name}}
                                    </td>
                                    <td>
                                        {{warehouses.find(warehouse => warehouse.id == item.pivot.warehouse_id).name}}
                                    </td>
<!--                                    <td>-->
<!--                                        {{item.locations.first}}-->
<!--                                    </td>-->
                                    <td>
                                        {{item.pivot.quantity_picked}}
                                    </td>
                                    <td>
                                        {{item.returned_qty}}
                                    </td>
                                    <td>
                                        {{quality.find(q => q.id == item.quality_check).name}}
                                    </td>
                                    <td>
                                        {{item.return_reason}}
                                    </td>
                                    <td>
                                        <div v-for="(record,index) in item.serial_numbers" class="row">
                                            <p v-if="record.id == item.serial_numbers[0].id">{{dispatched_record.find(r => r.id == record).serial_number}}</p>
                                            <p v-else>{{', ' + dispatched_record.find(r => r.id == record).serial_number}}</p>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <!--                        <div class="form-group mt-5">-->
                        <!--                            <label for="check_in_date">Check In Date<span class="required_asterisk">*</span></label>-->
                        <!--                            <div class="input-group date datepicker dashboard-date mr-2 mb-2 mb-md-0 d-xl-flex">-->
                        <!--                                <span class="input-group-addon bg-transparent"><i data-feather="calendar" class=" text-primary"></i></span>-->
                        <!--                                <datePicker v-model="form.check_in_date" :config="datePickerOptions" name="check_out_date"></datePicker>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                        <div class="mt-5">
                            <button type="submit" class="btn btn-primary mr-2">Submit Inventory</button>
                            <!--                            <a href="/tools" class="btn btn-light">Cancel</a>-->
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
    import Swal from "sweetalert2";
    import 'jquery-validation';
    import {LogInIcon, LogOutIcon} from 'vue-feather-icons';
    import datePicker from 'vue-bootstrap-datetimepicker';

    export default {
        name: "ProjectReturnComponent",
        props: {
            dispatched_inventory: Array,
            dispatched_record: Array,
            project: Object,
            quality: Array,
            warehouses: Array
        },
        components:{
            LogOutIcon, LogInIcon,
            datePicker
        },
        data(){
            return{
                product_returns: [],
                form:{
                    products:[],
                    check_in_date:'',
                    project_cancelled: false
                },
                datePickerOptions:{
                    format: 'DD/MM/YYYY'
                }
            }
        },
        mounted() {

            this.$props.dispatched_record = Object.values(this.$props.dispatched_record)

            $("form[name='product-return-form']").validate({
                rules: {
                    check_in_date: {
                        required: true,
                    },
                },
                errorPlacement: function(label, element) {
                    if(['check_in_date'].includes(element[0].name)){
                        label.addClass('mt-2 text-danger');
                        label.insertAfter(element.next());
                    }else{
                        label.addClass('mt-2 text-danger');
                        label.insertAfter(element);
                    }
                },
                highlight: function(element, errorClass) {
                    $(element).parent().addClass('has-danger')
                    $(element).addClass('form-control-danger')
                },
                unhighlight: function(element, errorClass, validClass) {
                    $(element).parent().removeClass('has-danger')
                    $(element).removeClass('form-control-danger')
                },
                submitHandler: function(form){
                    this.submit()
                }
            });
        },
        methods:{
            submit(){
                this.form.products = this.product_returns;
                let attributes = this.form;

                axios.post('/projects/'+this.$props.project.id+'/return',attributes).then(response => {
                    location = response.data.message;
                });
            },
            // addModal(id,index){
            //     let return_reason = '';
            //     let quality_check = '';
            //     let returned_qty = '';
            //     let serial_number = '';
            //     let qualities = '';
            //
            //     this.$props.quality.forEach(quality => {
            //         qualities += '<option value="'+quality.name +'">' + quality.name +'</option>';
            //     })
            //
            //     let quantity_picked = this.$props.dispatched_inventory.find(tool=> tool.id == id).pivot.quantity_picked
            //     Swal.fire({
            //         title: 'Product to Return',
            //         html:
            //             '<label for="returned_qty">Returned Qty.<span class="required_asterisk">*</span></label>'+
            //             '<input type="number" oninput="validity.valid||(value=\'\')" min="1" max="'+ quantity_picked +'" id="returned_qty" class="swal2-input">'+
            //             '<label for="quality_check">Quality Check<span class="required_asterisk">*</span></label>'+
            //             '<select id="quality_check" class="swal2-input">'+
            //             qualities +
            //             '</select>'+
            //             '<label for="return_reason">Return Reason<span class="required_asterisk">*</span></label>'+
            //             '<input id="return_reason" class="swal2-input">'+
            //             '<label for="serial_number">Serial Number<span class="required_asterisk">*</span></label>'+
            //             '<input id="serial_number" class="swal2-input">'
            //
            //         ,
            //         focusConfirm: false,
            //         preConfirm: () => {
            //             return_reason = document.getElementById('return_reason').value;
            //             quality_check = document.getElementById('quality_check').value;
            //             returned_qty = document.getElementById('returned_qty').value;
            //             serial_number = document.getElementById('serial_number').value
            //             if(returned_qty > quantity_picked){
            //                 Swal.fire({
            //                     icon: 'error',
            //                     title: 'Oops...',
            //                     text: 'You cannot return more than what was dispatched, please try again.',
            //                 });
            //             }
            //             else if(returned_qty == 0){
            //                 Swal.fire({
            //                     icon: 'error',
            //                     title: 'Oops...',
            //                     text: 'You cannot return 0, try again.',
            //                 });
            //             }
            //             else {
            //                 this.addToIn(id, index, quality_check, return_reason, returned_qty, serial_number)
            //             }
            //         }
            //     });
            // },
            // addToIn(id,index,quality_check,return_reason,returned_qty,serial_number){
            //     let toolToIn = this.$props.dispatched_inventory.find(tool=> tool.id == id);
            //
            //     toolToIn.return_reason = return_reason;
            //     toolToIn.quality_check = quality_check;
            //     toolToIn.returned_qty = returned_qty;
            //     toolToIn.serial_number = serial_number;
            //     this.$props.dispatched_inventory.splice(index,1);
            //
            //     this.product_returns.push(toolToIn);
            // },
            removeFromIn(id,index){
                let toolToOut = this.product_returns.find(tool=> tool.id == id);

                this.product_returns.splice(index,1);
                toolToOut.return_reason = ''
                toolToOut.returned_qty = ''
                toolToOut.serial_number = ''
                toolToOut.quality_check = '';
                this.$props.dispatched_inventory.push(toolToOut);
            },
            loadTools(){
                this.product_returns = [];
                this.$props.dispatched_inventory = this.$props.supervisors.find(supervisor => supervisor.id == this.form.supervisor_id).tools.filter(tool => tool.in_use == 1);
            },
            return_all(){
                const swalWithBootstrapButtons = Swal.mixin({
                    customClass: {
                        confirmButton: 'btn btn-success',
                        cancelButton: 'btn btn-danger'
                    },
                    buttonsStyling: false,
                })

                swalWithBootstrapButtons.fire({
                    title: 'Are you sure?',
                    text: "This will return all the products to the inventory for this project.",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonClass: 'ml-2',
                    confirmButtonText: 'Yes, delete it!',
                    cancelButtonText: 'No, cancel!',
                    reverseButtons: true
                }).then((result) => {

                    let component = this;

                    if (result.value) {

                        axios.post('/projects/'+this.$props.project.id+'/return_all').then(response => {
                            location = response.data.message;
                        });

                    } else if (result.dismiss === Swal.DismissReason.cancel) {

                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>
