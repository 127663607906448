<template>
    <modal :name="'search-container-modal'" height="90%" width="50%" :scrollable="true">
        <div class="container modal-content border-bottom-0 h-100">
            <div class="modal-header">
                <div>
                    <div class="row justify-content-between">
                        <h5 class="modal-title">Load Purchase Order</h5>
                    </div>
                </div>
            </div>
            <div class="modal-body">
                <div class="d-flex justify-content-between mt-3">
                    <div class="row align-items-end">
                        <div class="form-group mr-2">
                            <label for="filter">Container</label>
                            <input type="text" class="form-control" id="filter" name="filter" placeholder="Search" v-model="containerSearch">
                        </div>
                        <div class="form-group mr-2">
                            <label for="supplier">PO</label>
                            <input type="text" class="form-control" id="supplier" name="supplier" placeholder="Search" v-model="poSearch">
                        </div>
                        <div class="form-group mr-2">
                            <label for="supplier">Port Release</label>
                            <input type="date" class="form-control" id="received" name="received" placeholder="Search" v-model="dateSearch" >
                        </div>
                        <div class="form-group">
                            <button type="button" class="btn btn-primary" v-on:click="getData(path + '?' + getFilter())">Filter</button>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-12 overflow-auto">
                        <table class="table table-bordered table-hover products-table">
                            <thead>
                            <tr>
                                <th>Select</th>
                                <th>Container</th>
                                <th>POs</th>
                                <th>Port Release</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(container,index) in filtered_containers">
                                <td class="text-center">
                                    <chevrons-right-icon size="1.5x" class="text-primary cursor-pointer" v-on:click="selectPo(index)"></chevrons-right-icon>
                                </td>
                                <td>{{container.container}}</td>
                                <td>{{container.purchase_order.reduce((poString, po) => poString + (poString == "" ? po.po_number : ", " + po.po_number), "")}}</td>
                                <td>{{container.port_release}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row justify-content-between mt-3">
                    <div></div>
                    <div>
                        <nav>
                            <ul class="pagination">
                                <li :class="prevButtonClass">
                                    <span v-if="prev_page_url == '' || prev_page_url == null" class="page-link">{{'<'}}</span>
                                    <button type="button" v-else class="page-link" v-on:click="getData(prev_page_url)">{{'<'}}</button>
                                </li>
                                <li v-for="(button,index) in page_buttons" :class="button.class">
                                    <span v-if="button.isActualPage" class="page-link">{{button.pageNumber}}</span>
                                    <span v-else-if="button.disabledButton" class="page-link">...</span>
                                    <button type="button" v-else class="page-link" v-on:click="getData(button.path)">{{button.pageNumber}}</button>
                                </li>
                                <li :class="nextButtonClass">
                                    <span v-if="next_page_url == '' || next_page_url == null" class="page-link">{{'>'}}</span>
                                    <button type="button" v-else class="page-link" v-on:click="getData(next_page_url)">{{'>'}}</button>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light" @click="$modal.hide('search-container-modal')">Close</button>
            </div>
        </div>
    </modal>
</template>

<script>
import {ChevronsRightIcon} from 'vue-feather-icons';

export default {
    name: "SearchContainerModal",
    components:{
        ChevronsRightIcon
    },
    props: [],
    mounted() {

    },
    data(){
        return{
            filtered_containers: [],
            poSearch: "",
            containerSearch: "",
            dateSearch: "",
            current_page: 1,
            first_page_url: "",
            from: 1,
            last_page: 1,
            last_page_url: "",
            next_page_url: "",
            path: "/containers/filter/port_released",
            per_page: 1,
            prev_page_url: "",
            to: 1,
            total: 1,
            filter:'',
            paginate: 10,
            prevButtonClass: 'page-item ' + (this.prev_page_url !== null && this.prev_page_url !== '' ? '' : 'disabled'),
            nextButtonClass: 'page-item ' + (this.next_page_url !== null && this.next_page_url !== '' ? '' : 'disabled'),
            page_buttons: [],
        }
    },
    beforeDestroy () {
        EventBus.$off('setContainer', this.$props.setContainer)
    },
    methods: {
        selectPo(index){
            let vm = this;
            vm.$emit('setContainer', this.filtered_containers[index])
            this.$modal.hide('search-container-modal');
        },
        getData(path){
            axios.get(path).then(response => {
                console.log(response.data);
                this.setOrdersPaginationData(response.data)
            })
        },
        resetFilter(){

            this.filter = '' ;
            this.containerSearch = '';
            this.dateSearch = null;
            this.poSearch = '';

            this.getData(this.path + '?' + this.getFilter());
        },
        setOrdersPaginationData(data){
            let filterPath = this.getFilter();

            if(filterPath !== ''){
                filterPath = filterPath + '&';
            }

            this.filtered_containers = data.containers.data;
            this.current_page = data.containers.current_page;
            this.first_page_url = data.containers.first_page_url;
            this.from = data.containers.from;
            this.last_page = data.containers.last_page;
            this.last_page_url = data.containers.last_page_url;
            this.next_page_url = data.containers.next_page_url == null ? null : this.path + '?' + filterPath + 'page=' + (this.current_page + 1);
            this.per_page = data.containers.per_page;
            this.prev_page_url = data.containers.prev_page_url == null ? null : this.path + '?' + filterPath + "page=" + (this.current_page - 1);
            this.to = data.containers.to;
            this.total = data.containers.total;
            this.filter = data.filter;
            this.paginate = data.paginate;
            this.prevButtonClass = 'page-item ' + (this.prev_page_url !== null && this.prev_page_url !== '' ? '' : 'disabled');
            this.nextButtonClass = 'page-item ' + (this.next_page_url !== null && this.next_page_url !== '' ? '' : 'disabled');

            this.setButtons();
        },
        getFilter(){

            let query = (this.containerSearch != null && this.containerSearch !== '' ? 'containerSearch=' + this.containerSearch : '');
            query = query !== '' && this.poSearch !== '' && this.poSearch != null ? query + '&' : query;
            query = query + (this.poSearch != null && this.poSearch !== '' ? 'poSearch=' + this.poSearch : '')
            query = query !== '' && this.dateSearch !== '' && this.dateSearch != null ? query + '&' : query;
            query = query + (this.dateSearch != null && this.dateSearch !== '' ? 'dateSearch=' + this.dateSearch : '')


            return query;
        },
        setButtons(){
            this.page_buttons = [];
            if(this.last_page > 13){
                if(this.current_page < 8){
                    this.setLeftSideButtons();
                }else if(this.last_page - this.current_page < 7){
                    this.setRightSideButtons();
                }else{
                    this.setMiddleButtons();
                }
            }else{
                this.setNormalButtons();
            }
        },
        setNormalButtons(){
            for(let i = 1; i <= this.last_page; i++){
                let button = this.createButton(i);

                this.page_buttons.push(button);
            }
        },
        setLeftSideButtons(){
            for(let i = 1; i <= 10; i++){
                let button = this.createButton(i)

                this.page_buttons.push(button)
            }

            let button = this.createButton(0, false);
            this.page_buttons.push(button);

            button = this.createButton(this.last_page - 1)
            this.page_buttons.push(button);

            button = this.createButton(this.last_page);
            this.page_buttons.push(button);

        },
        setRightSideButtons(){
            let button = this.createButton(1);
            this.page_buttons.push(button);

            button = this.createButton(2);
            this.page_buttons.push(button);

            button = this.createButton(0, false);
            this.page_buttons.push(button);

            for(let i = this.last_page - 9; i <= this.last_page; i++){
                button = this.createButton(i);

                this.page_buttons.push(button);
            }
        },
        setMiddleButtons(){
            let button = this.createButton(1);
            this.page_buttons.push(button);

            button = this.createButton(2);
            this.page_buttons.push(button);

            button = this.createButton(0, false);
            this.page_buttons.push(button);

            for(let i = this.current_page - 3; i <= this.current_page + 3; i++){
                button = this.createButton(i);

                this.page_buttons.push(button);
            }

            button = this.createButton(0, false);
            this.page_buttons.push(button);

            button = this.createButton(this.last_page - 1)
            this.page_buttons.push(button);

            button = this.createButton(this.last_page);
            this.page_buttons.push(button);
        },
        createButton(pageNumber, normalButton=true){
            let filterPath = this.getFilter();

            if(filterPath !== ''){
                filterPath = filterPath + '&';
            }

            return {
                'path': this.path + '?' + filterPath + 'page=' + pageNumber,
                'pageNumber': pageNumber,
                'isActualPage': this.current_page == pageNumber,
                'class': 'page-item' + (this.current_page == pageNumber ? ' active':'') + (!normalButton ? ' disabled' : ''),
                'disabledButton': !normalButton
            }
        }
    }
}
</script>

<style scoped>

</style>

