<template>
    <modal :name="$props.type + '-location-modal'" height="auto" width="100%" :scrollable="true">
        <div class="container modal-content border-bottom-0">
            <div class="modal-header">
                <div class="modal-title">
                    Select Location
                </div>
                <button type="button"
                        class="close"
                        @click="$modal.hide($props.type + '-location-modal')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="col-md-12">
                    <div>
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-end mt-3">
                                <div>
                                    <label for="category">Show</label>
                                    <dropbox name="paginate"
                                             id="paginate"
                                             :options="pagination_options"
                                             v-model="pagination"
                                             class="custom-select custom-select-sm form-control"></dropbox>
                                </div>
                                <div class="d-flex align-self-end">
                                    <div class="mr-2">
                                        <label for="filter">Search</label>
                                        <input type="text"
                                               autocomplete="off"
                                               class="form-control"
                                               id="filter"
                                               name="filter"
                                               v-model="filter"
                                               placeholder="Search"/>
                                    </div>
                                    <div class="form-group ">
                                        <button type="button" class="btn btn-primary" v-on:click="getData(path + '?' + getFilter())">Filter</button>
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive pt-3">
                                <table class="table table-bordered table-hover">
                                    <thead>
                                    <tr>
                                        <th>Select</th>
                                        <th>Location</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="level_location in filtered_locations">
                                        <td>
                                            <chevrons-right-icon size="1.5x" v-on:click="selectLocation(level_location)" class="text-primary cursor-pointer"></chevrons-right-icon>
                                        </td>
                                        <td>{{level_location.level_label}}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="d-flex justify-content-between mt-3">
                                <div>
                                    Displaying {{filtered_locations.length}} of {{total}} location(s).
                                </div>
                                <div>
                                    <nav>
                                        <ul class="pagination">
                                            <li :class="prevButtonClass">
                                                <span v-if="prev_page_url == '' || prev_page_url == null" class="page-link">{{'<'}}</span>
                                                <button type="button" v-else class="page-link" v-on:click="getData(prev_page_url)">{{'<'}}</button>
                                            </li>
                                            <li v-for="(button,index) in page_buttons" :class="button.class">
                                                <span v-if="button.isActualPage" class="page-link">{{button.pageNumber}}</span>
                                                <span v-else-if="button.disabledButton" class="page-link">...</span>
                                                <button type="button" v-else class="page-link" v-on:click="getData(button.path)">{{button.pageNumber}}</button>
                                            </li>
                                            <li :class="nextButtonClass">
                                                <span v-if="next_page_url == '' || next_page_url == null" class="page-link">{{'>'}}</span>
                                                <button type="button" v-else class="page-link" v-on:click="getData(next_page_url)">{{'>'}}</button>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light" v-on:click="$modal.hide($props.type + '-location-modal')">Close</button>
            </div>
        </div>
    </modal>
</template>

<script>
import {ChevronsRightIcon} from 'vue-feather-icons';

export default {
    name: "InventoryCountLocationModal",
    components:{
        ChevronsRightIcon
    },
    props:{
        type: String,
        level_locations: Array,
        area: String,
        zone_type: String,
    },
    mounted() {
        this.getData(this.path + '?' + this.getFilter());
    },
    data(){
        return{
            pagination_options:[{id: "10", name:"10"}, {id: "30", name:"30"}, {id: "50", name:"50"}],
            filter: "",
            level_count: 0,
            current_page: 1,
            first_page_url: "",
            last_page: 1,
            last_page_url: "",
            next_page_url: "",
            path: "/inventory_count/search_filter",
            per_page: 1,
            to: 1,
            total: 1,
            pagination: 10,
            prevButtonClass: 'page-item ' + (this.prev_page_url !== null && this.prev_page_url !== '' ? '' : 'disabled'),
            nextButtonClass: 'page-item ' + (this.next_page_url !== null && this.next_page_url !== '' ? '' : 'disabled'),
            page_buttons: [],
            filtered_locations: [],
            from: '',
        }
    },
    methods: {
        getParentFilters(){
            let filter = '';
            if(this.$props.zone_type != null){
                filter = filter + 'zone_type=' + this.$props.zone_type;
            }

            if(this.$props.area != null){
                filter = filter !== '' ? filter + '&area=' + this.$props.area : 'area=' + this.$props.area;
            }

            return filter;
        },
        selectLocation(level_location){
            let vm = this;
            vm.$emit('setLocation', this.$props.type, level_location.level_label);
            this.$modal.hide(this.$props.type + '-location-modal')
        },
        getData(path){

            axios.get(path).then(response => {
                this.setLocationsPaginationData(response.data);
            })
        },
        resetFilter(){
            this.filter = '';
            this.getData(this.path + '?' + this.getFilter());
        },
        setLocationsPaginationData(data){
            let filterPath = this.getFilter();

            if(filterPath !== ''){
                filterPath = filterPath + '&';
            }

            console.log(data.filter);

            this.filtered_locations = data.levels.data;
            this.current_page = data.levels.current_page;
            this.first_page_url = data.levels.first_page_url;
            this.from = data.levels.from;
            this.last_page = data.levels.last_page;
            this.last_page_url = data.levels.last_page_url  == null ? null : this.path + '?' + filterPath + 'page=' + (this.current_page + 1);
            this.next_page_url = data.levels.next_page_url;
            this.per_page = data.levels.per_page;
            this.prev_page_url = data.levels.prev_page_url == null ? null : this.path + '?' + filterPath + "page=" + (this.current_page - 1);
            this.to = data.levels.to;
            this.total = data.levels.total;
            this.filter = data.filter;
            this.paginate = data.paginate;
            this.prevButtonClass = 'page-item ' + (this.prev_page_url !== null && this.prev_page_url !== '' ? '' : 'disabled');
            this.nextButtonClass = 'page-item ' + (this.next_page_url !== null && this.next_page_url !== '' ? '' : 'disabled');

            this.setButtons();
        },
        getFilter(){
            const parentFilters = this.getParentFilters();
            let returnFilter = '';
            if(this.filter != null && this.filter !== ''){
                returnFilter += 'filter=' + this.filter;
            }

            if(parentFilters != null && parentFilters != ''){
               if(returnFilter !== ''){
                   returnFilter += '&'
               }

               returnFilter += parentFilters
            }

            return returnFilter;
        },
        setButtons(){
            this.page_buttons = [];
            if(this.last_page > 13){
                if(this.current_page < 8){
                    this.setLeftSideButtons();
                }else if(this.last_page - this.current_page < 7){
                    this.setRightSideButtons();
                }else{
                    this.setMiddleButtons();
                }
            }else{
                this.setNormalButtons();
            }

            //TODO: Try this algorithm later, more optimized no loops

            // const paginationArray = this.getPaginationArray();
            //
            // this.page_buttons = paginationArray.map(pa => {
            //     if (pa == this.current_page) {
            //         return this.createButton(page);
            //     }
            //
            //     let page = null;
            //
            //     if (pa == ">") {
            //         page = props.current_page + 1;
            //     } else if (pa == "<") {
            //         page = props.current_page - 1;
            //     } else {
            //         page = pa;
            //     }
            //
            //     if (page <= 0) {
            //         page = 1;
            //     }
            //
            //     if (page >= totalPages) {
            //         page = totalPages
            //     }
            //
            //     return this.createButton(page);
            // });
        },
        getPaginationArray(){
            const totalPages = this.total/this.paginate;

            if(this.total <= 1){
                return ["<","1",">"];
            }

            const center = [this.current_page - 2, this.current_page - 1, this.current_page, this.current_page + 1, this.current_page + 2];
            const filteredCenter = center.filter((p) => p > 1 && p < totalPages);
            const includeThreeLeft = this.current_page === 5;
            const includeThreeRigth = this.current_page === totalPages - 4;
            const includeLeftDots = this.current_page > 5;
            const includeRightDots = this.current_page < totalPages - 4;

            if(includeThreeLeft){
                filteredCenter.unshift(2);
            }

            if(includeThreeRigth){
                filteredCenter.push(totalPages - 2);
            }

            const filteredCenterString = filteredCenter.map(n => n.toString());

            if(includeLeftDots){
                filteredCenterString.unshift("...");
            }

            if(includeRightDots){
                filteredCenterString.push("...");
            }

            return ["1", ...filteredCenterString, totalPages];

        },
        setNormalButtons(){
            for(let i = 1; i <= this.last_page; i++){
                let button = this.createButton(i);

                this.page_buttons.push(button);
            }
        },
        setLeftSideButtons(){
            for(let i = 1; i <= 10; i++){
                let button = this.createButton(i);

                this.page_buttons.push(button);
            }

            let button = this.createButton(0, false);
            this.page_buttons.push(button);

            button = this.createButton(this.last_page - 1);
            this.page_buttons.push(button);

            button = this.createButton(this.last_page);
            this.page_buttons.push(button);
        },
        setRightSideButtons(){
            let button = this.createButton(1);
            this.page_buttons.push(button);

            button = this.createButton(2);
            this.page_buttons.push(button);

            button = this.createButton(0, false);
            this.page_buttons.push(button);

            for(let i = this.last_page - 9; i <= this.last_page; i++){
                button = this.createButton(i);

                this.page_buttons.push(button);
            }
        },
        setMiddleButtons(){
            let button = this.createButton(1);
            this.page_buttons.push(button);

            button = this.createButton(2);
            this.page_buttons.push(button);

            button = this.createButton(0, false);

            for(let i = this.current_page -3; i <= this.current_page + 3; i++){
                button = this.createButton(i);

                this.page_buttons.push(button);
            }

            button = this.createButton(0, false);
            this.page_buttons.push(button);

            button = this.createButton(this.last_page - 1);
            this.page_buttons.push(button);

            button = this.createButton(this.last_page);
            this.page_buttons.push(button);
        },
        createButton(pageNumber, normalButton=true){
            let filterPath = this.getFilter();

            if(filterPath !== ''){
                filterPath = filterPath + '&'
            }

            return {
                'path': this.path + '?' + filterPath + 'page=' + pageNumber,
                'pageNumber': pageNumber,
                'isActualPage': this.current_page == pageNumber,
                'class': 'page-item' + (this.current_page == pageNumber ? ' active':'') + (!normalButton ? ' disabled' : ''),
                'disabledButton': !normalButton
            }
        }
    }
}
</script>

<style scoped>

</style>
