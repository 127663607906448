<template>
    <modal :name="'search-products-modal'" height="90%" width="50%" :scrollable="true">
        <div class="container modal-content border-bottom-0 h-100">
            <div class="modal-header">
                <div>
                    <div class="row justify-content-between">
                        <h5 class="modal-title">Load Product</h5>
                    </div>
                </div>
            </div>
            <div class="modal-body">
                <div class="d-flex justify-content-between mt-3">
                    <div>
<!--                        <label for="category"> Show </label>-->
<!--                        <select name="paginate" id="paginate" class="custom-select custom-select-sm form-control">-->
<!--                            <option value="10">10</option>-->
<!--                            <option value="30">30</option>-->
<!--                            <option value="50">50</option>-->
<!--                        </select>-->
                    </div>
                    <div class="row align-items-end">
                        <div class="form-group mr-2">
                            <label for="filter">Product</label>
                            <input autocomplete="off" type="text" class="form-control" id="filter" name="filter" placeholder="Search" v-model="filter">
                        </div>
                        <div class="form-group mr-2">
                            <label for="supplier">Supplier</label>
                            <input autocomplete="off" type="text" class="form-control" id="supplier" name="supplier" placeholder="Search" v-model="supplierText">
                        </div>
                        <div class="form-group">
                            <button type="button" class="btn btn-primary" v-on:click="getData(path + '?' + getFilter())">Filter</button>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-12 overflow-auto">
                        <table class="table table-bordered table-hover products-table">
                            <thead>
                            <tr>
                                <th>Select</th>
                                <th>SKU</th>
                                <th>Description</th>
                                <th>Supplier</th>
                                <th>Category</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(product,index) in filtered_products">
                                <td class="text-center">
                                    <chevrons-right-icon size="1.5x" class="text-primary cursor-pointer" v-on:click="selectProduct(product.id)"></chevrons-right-icon>
                                </td>
                                <td>{{product.sku}}</td>
                                <td>{{product.description}}</td>
                                <td>{{product.supplier}}</td>
                                <td>{{product.category.name}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row justify-content-between mt-3">
                    <div>
                        <button class="btn btn-primary" v-on:click="getAllData">Load All Products</button>
                    </div>
                    <div>
                        <nav>
                            <ul class="pagination">
                                <li :class="prevButtonClass">
                                    <span v-if="prev_page_url == '' || prev_page_url == null" class="page-link">{{'<'}}</span>
                                    <button type="button" v-else class="page-link" v-on:click="getData(prev_page_url)">{{'<'}}</button>
                                </li>
                                <li v-for="(button,index) in page_buttons" :class="button.class">
                                    <span v-if="button.isActualPage" class="page-link">{{button.pageNumber}}</span>
                                    <span v-else-if="button.disabledButton" class="page-link">...</span>
                                    <button type="button" v-else class="page-link" v-on:click="getData(button.path)">{{button.pageNumber}}</button>
                                </li>
                                <li :class="nextButtonClass">
                                    <span v-if="next_page_url == '' || next_page_url == null" class="page-link">{{'>'}}</span>
                                    <button type="button" v-else class="page-link" v-on:click="getData(next_page_url)">{{'>'}}</button>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light" @click="$modal.hide('search-products-modal')">Close</button>
                <!--                <button :disabled="submit_disabled" type="button" class="btn btn-primary mr-2" v-on:click="submit">Dispatch</button>-->
            </div>
        </div>
    </modal>
</template>

<script>
import {ChevronsRightIcon} from 'vue-feather-icons';

export default {
    name: "SearchProductsModal",
    components:{
        ChevronsRightIcon
    },
    props: ["product","text","supplierText"],
    mounted() {

    },
    data(){
        return{
            filtered_products: [],
            current_page: 1,
            first_page_url: "",
            from: 1,
            last_page: 1,
            last_page_url: "",
            next_page_url: "",
            path: "/products/filter",
            per_page: 1,
            prev_page_url: "",
            to: 1,
            total: 1,
            filter: this.$props.text,
            paginate: 10,
            prevButtonClass: 'page-item ' + (this.prev_page_url !== null && this.prev_page_url !== '' ? '' : 'disabled'),
            nextButtonClass: 'page-item ' + (this.next_page_url !== null && this.next_page_url !== '' ? '' : 'disabled'),
            page_buttons: [],
        }
    },
    methods: {
        selectProduct(id){
            let vm = this;
            axios.get("/products/" + id + "/last_orders").then(reponse =>{
                vm.$emit('addProduct', reponse.data.product)
                // console.log(reponse.data.product)
                // this.$modal.hide('search-products-modal');
            })

        },
        getAllData(){
            axios.get(`/products/allFilter?filter=${this.filter}&supplierText=${this.supplierText}`)
                .then(response =>{
                    console.log("La respuesta", response.data.prodcuts)
                    this.$emit('selectProducts', response.data.products);
                    this.$modal.hide('search-products-modal')
                })

        },
        // selectAll(){
        //     console.log("products: ", this.filtered_products);
        //     this.filtered_products.forEach(product =>{
        //         this.selectProduct(product.id);
        //     })
        // },
        getData(path){
            console.log("Hello there", path);
            axios.get(path).then(response => {
                console.log(response.data);
                this.setProductsPaginationData(response.data)
            })
        },
        resetFilter(){
            console.log("reseteando")
            this.filter = this.$props.text;

            this.getData(this.path + '?' + this.getFilter());
        },
        setProductsPaginationData(data){
            let filterPath = this.getFilter();

            if(filterPath !== ''){
                filterPath = filterPath + '&';
            }

            this.filtered_products = data.products.data;
            this.current_page = data.products.current_page;
            this.first_page_url = data.products.first_page_url;
            this.from = data.products.from;
            this.last_page = data.products.last_page;
            this.last_page_url = data.products.last_page_url;
            this.next_page_url = data.products.next_page_url == null ? null : this.path + '?' + filterPath + 'page=' + (this.current_page + 1);
            this.per_page = data.products.per_page;
            this.prev_page_url = data.products.prev_page_url == null ? null : this.path + '?' + filterPath + "page=" + (this.current_page - 1);
            this.to = data.products.to;
            this.total = data.products.total;
            this.filter = data.filter;
            this.paginate = data.paginate;
            this.prevButtonClass = 'page-item ' + (this.prev_page_url !== null && this.prev_page_url !== '' ? '' : 'disabled');
            this.nextButtonClass = 'page-item ' + (this.next_page_url !== null && this.next_page_url !== '' ? '' : 'disabled');

            this.setButtons();
        },
        getFilter(){

            let query = (this.filter != null && this.filter !== '' ? 'filter=' + this.filter : '');
            query = query !== '' && this.supplierText !== '' && this.supplierText != null ? query + '&' : query;
            query = query + (this.supplierText != null && this.supplierText !== '' ? 'supplierText=' + this.supplierText : '')

            console.log(query);

            return query;
        },
        setButtons(){
            this.page_buttons = [];
            if(this.last_page > 13){
                if(this.current_page < 8){
                    this.setLeftSideButtons();
                }else if(this.last_page - this.current_page < 7){
                    this.setRightSideButtons();
                }else{
                    this.setMiddleButtons();
                }
            }else{
                this.setNormalButtons();
            }
        },
        setNormalButtons(){
            for(let i = 1; i <= this.last_page; i++){
                let button = this.createButton(i);

                this.page_buttons.push(button);
            }
        },
        setLeftSideButtons(){
            for(let i = 1; i <= 10; i++){
                let button = this.createButton(i)

                this.page_buttons.push(button)
            }

            let button = this.createButton(0, false);
            this.page_buttons.push(button);

            button = this.createButton(this.last_page - 1)
            this.page_buttons.push(button);

            button = this.createButton(this.last_page);
            this.page_buttons.push(button);

        },
        setRightSideButtons(){
            let button = this.createButton(1);
            this.page_buttons.push(button);

            button = this.createButton(2);
            this.page_buttons.push(button);

            button = this.createButton(0, false);
            this.page_buttons.push(button);

            for(let i = this.last_page - 9; i <= this.last_page; i++){
                button = this.createButton(i);

                this.page_buttons.push(button);
            }
        },
        setMiddleButtons(){
            let button = this.createButton(1);
            this.page_buttons.push(button);

            button = this.createButton(2);
            this.page_buttons.push(button);

            button = this.createButton(0, false);
            this.page_buttons.push(button);

            for(let i = this.current_page - 3; i <= this.current_page + 3; i++){
                button = this.createButton(i);

                this.page_buttons.push(button);
            }

            button = this.createButton(0, false);
            this.page_buttons.push(button);

            button = this.createButton(this.last_page - 1)
            this.page_buttons.push(button);

            button = this.createButton(this.last_page);
            this.page_buttons.push(button);
        },
        createButton(pageNumber, normalButton=true){
            let filterPath = this.getFilter();

            if(filterPath !== ''){
                filterPath = filterPath + '&';
            }

            return {
                'path': this.path + '?' + filterPath + 'page=' + pageNumber,
                'pageNumber': pageNumber,
                'isActualPage': this.current_page == pageNumber,
                'class': 'page-item' + (this.current_page == pageNumber ? ' active':'') + (!normalButton ? ' disabled' : ''),
                'disabledButton': !normalButton
            }
        }
    }
}
</script>

<style scoped>

</style>

