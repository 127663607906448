<template>
    <div>
        <form @submit.prevent="submit" name="project-installation-form">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="status">Project Status<span class="required_asterisk">*</span></label>

                        <dropbox name="status" id="status" :options="[{'id':'In Progress','name':'In Progress'},{'id':'Completed','name':'Completed'}]" v-model="form.status">
                            <option></option>
                        </dropbox>
                    </div>
                    <div v-if="form.status == 'Completed'" class="form-group mt-5">
                        <label>Date Completed<span class="required_asterisk">*</span></label>
                        <div class="input-group date datepicker dashboard-date mr-2 mb-2 mb-md-0 d-xl-flex">
                            <span class="input-group-addon bg-transparent"><i data-feather="calendar" class=" text-primary"></i></span>
                            <datePicker autocomplete="off" v-model="form.date_completed" :config="datePickerOptions" name="check_out_date"></datePicker>
                        </div>
                    </div>
                    <div class="form-group mb-3">
                        <label for="inventory">Returns: <span class="required_asterisk">*</span></label>
                        <div class="row ml-2">
                            <div class="form-check form-check-inline">
                                <label class="form-check-label">
                                    <input type="radio" class="form-check-input" name="optionsRadios5" id="optionsRadios5" value="yes" v-model="form.returns">
                                    Yes
                                </label>
                            </div>
                            <div class="form-check form-check-inline">
                                <label class="form-check-label">
                                    <input type="radio" class="form-check-input" name="product" id="optionsRadios6" value="no" v-model="form.returns">
                                    No
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="comments">Comments</label>
                        <textarea class="form-control" id="comments" name="comments" placeholder="Description" v-model="form.comments" rows="5"></textarea>
                    </div>
                    <button type="submit" class="btn btn-primary mr-2">Submit</button>
                    <a href="/projects/installations" class="btn btn-light">Cancel</a>
                </div>
            </div>
        </form>

    </div>
</template>

<script>
    import datePicker from 'vue-bootstrap-datetimepicker';

    export default {
        name: "ProjectInstallationComponent",
        props: ['project'],
        components:{
            datePicker
        },
        data(){
            return{
                form:{
                    date_completed:'',
                    comments: '',
                    returns: this.$props.project.returns ? 'yes' : 'no',
                    status: this.$props.project.status
                },
                datePickerOptions:{
                    format: 'DD/MM/YYYY',
                    useCurrent: false,
                },
            }
        },
        methods:{
            submit(){
                console.log("Hello")
                axios.post('/projects/' + this.$props.project.id + '/installation',this.form).then(response => {
                    console.log(response.data.message)
                    location = response.data.message;
                });
            }
        }
    }
</script>

<style scoped>

</style>
