import { render, staticRenderFns } from "./AdminComponent.vue?vue&type=template&id=4aacc6fc&scoped=true&"
import script from "./AdminComponent.vue?vue&type=script&lang=js&"
export * from "./AdminComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4aacc6fc",
  null
  
)

export default component.exports