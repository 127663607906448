<template>
    <modal :name="index+ '-' + product.name +'-serial-codes-modal'" height="auto" :scrollable="true">
        <div class="modal-content border-bottom-0 h-100">
            <div class="modal-header">
                <div>
                    <div class="row justify-content-between">
                        <h5 class="modal-title">{{product.name + ' ('+product.sku+')'}}</h5>
                        <p class="text-right">Serials: {{product.serial_codes.length}}/{{product.qty_received === '' ? '0' : product.qty_received}}</p>
                    </div>
                    <div class="mt-4 row">
                        <h6 class="mr-2 p-2" v-on:click="changeScreen('input')" v-bind:class="{'modal-serial-selected': isInput}" style="cursor: pointer">Input Serial Number</h6>
                        <h6 class="mr-2 p-2" v-on:click="changeScreen('scan')" v-bind:class="{'modal-serial-selected': isScan}" style="cursor: pointer">Scan Serial Number</h6>
                        <h6 class="p-2" v-on:click="changeScreen('list')" v-bind:class="{'modal-serial-selected':isList}" style="cursor: pointer">List of Serial Numbers</h6>
                    </div>
                </div>
            </div>
            <div class="modal-body">
                <div class="form-group" v-if="screen == 'input'">
                    <label for="serial_number">Serial Number<span class="required_asterisk">*</span></label>
                    <textarea autofocus autocomplete="off" v-on:keydown.enter.prevent='addSerial'  rows="5" type="text" class="form-control" name="serial_number" id="serial_number" placeholder="####" v-model="serial"></textarea>
                    <div class=" mt-4 alert alert-warning" role="alert">You can enter multiple serial numbers separated by comma (e.g: 12345, 56789)</div>
                </div>
                <div v-if="screen == 'scan'">
                    <div class="p-0 m-0" style="height: 50vh; width: 50vm">
                        <v-quagga class="w-100 h-100" :onDetected="addSerialScan" :readerSize="readerSize" :readerTypes="readerTypes"></v-quagga>
                    </div>
                </div>

                <div v-if="screen=='list'">
                    <div class="table-responsive pt-3">
                        <table class="table table-bordered table-hover date-columns">
                            <thead>
                            <tr>
                                <th>
                                    Serial Codes
                                </th>
                                <th>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(serial,index) in serials_list">
                                <td>{{serial}}</td>
                                <td style="color: red"><p style="cursor: pointer;" v-on:click="deleteSerial(index)">Delete</p></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light" @click="$modal.hide(index+ '-' + product.name +'-serial-codes-modal')">Close</button>
                <button type="button" class="btn btn-primary mr-2" v-if="screen === 'input'" v-on:click="addSerial">Add Serial Number</button>
            </div>
        </div>
    </modal>
</template>

<script>
    import Swal from "sweetalert2";
    import VueQuagga from 'vue-quaggajs';

    Vue.use(VueQuagga);

    export default {
        name: "SerialCodesModal",
        props: ['product','index','serials_list','warehouse'],
        mounted() {

        },
        components:{
            VueQuagga,
        },

        data(){
            return{
                readerSize: {
                    width: 100,
                    height: 100
                },
                readerTypes:['code_128_reader',
                    'ean_reader',
                    'ean_8_reader',
                    'code_39_reader',
                    'code_39_vin_reader',
                    'codabar_reader',
                    'upc_reader',
                    'upc_e_reader',
                    'i2of5_reader',
                    '2of5_reader',
                    'code_93_reader'],
                detecteds: [],
                screen:"input",
                isInput: true,
                isList: false,
                isScan: false,
                serial: ""
            }
        },
        methods:{
            logIt (data) {
                console.log('detected', data.codeResult.code)
            },
            changeScreen(value){
                if(value === 'list'){
                    this.screen = 'list';
                    this.isInput = false;
                    this.isList = true;
                    this.isScan = false;
                }else if(value === 'scan'){
                    this.screen = 'scan';
                    this.isInput = false;
                    this.isList = false;
                    this.isScan = true;
                }else if(value === 'input'){
                    this.screen = 'input';
                    this.isInput = true;
                    this.isList = false;
                    this.isScan = false;
                }
            },
            addSerialScan(data){
                let component = this;
                this.serial = data.codeResult.code
                let repeated_serial = this.$props.product.serial_codes.find(code => code === this.serial);
                if(this.$props.product.qty_received === '' ||this.$props.product.serial_codes.length >= parseInt(this.$props.product.qty_received)){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'You have all the serial numbers for the product ' + component.$props.product.name + '. If you need to add more increase the field "Quantity Received".',
                    });
                }else if(repeated_serial != null){
                    // Swal.fire({
                    //     icon: 'error',
                    //     title: 'Oops...',
                    //     text: 'Serial Number already inserted.',
                    // });
                } else if((this.serial !== "" && this.serial !== null)){
                    this.$props.product.serial_codes.push(...[this.serial]);
                    this.$props.serials_list.push(...[this.serial]);
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'center',
                        showConfirmButton: false,
                        timer: 2500
                    });
                    Toast.fire({
                        icon: 'success',
                        title: 'Serial number '+ this.serial+ ' added to list.'
                    })
                }
                this.serial = "";
            },
            addSerial(){
                let component = this;
                let serials = this.serial.split(',');
                serials.forEach(serial => {
                    let repeated_serial = this.$props.product.serial_codes.find(code => code === serial);
                    if (this.$props.product.qty_received === '' || this.$props.product.serial_codes.length >= parseInt(this.$props.product.qty_received)) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'You have all the serial numbers for the product ' + component.$props.product.name + '. If you need to add more increase the field "Quantity Received".',
                        });
                    } else if (repeated_serial != null) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Serial Number already inserted.',
                        });
                    } else if ((serial !== "" && serial !== null)) {
                        this.$props.product.serial_codes.push(serial);
                        this.$props.serials_list.push(...[this.serial]);
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'center',
                            showConfirmButton: false,
                            timer: 2500
                        });
                        Toast.fire({
                            icon: 'success',
                            title: 'Serial number added to list.'
                        })
                    }

                })
                this.serial = "";
            },
            deleteSerial(index){
                this.$forceUpdate();
                this.$props.product.serial_codes.splice(index,1);
                this.$props.serials_list.splice(index,1);

            }
        }
    }
</script>

<style scoped>

</style>
