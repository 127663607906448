<template>
    <div>
        <inventory-count-add-finding
            :inventory_count="$props.inventory_count"
            @findingSaved="addFinding"/>
        <inventory-count-table :inventory_count="$props.inventory_count" ref="inventoryCountTable"/>
        <findings-count :inventory_count="$props.inventory_count" />
        <div class="row">
            <div class="col-12">
                <button class="btn btn-light" v-on:click="cancel">Cancel</button>
                <a class="btn btn-light" v-on:click="save">Save</a>
                <button class="btn btn-primary" v-on:click="submit">Submit</button>
            </div>
        </div>
    </div>
</template>

<script>

import InventoryCountTable from "./InventoryCountTable";
import Swal from "sweetalert2";
export default {
    name: "InventoryCountExecution",
    components: {InventoryCountTable},
    props:{
        inventory_count: Object,
        locations: Array,
        skus: Array,
        license_plates: Array,
        products: Array,
        bus: new Vue()
    },
    data(){
        return {
            findings: []
        }
    },
    methods: {
        addFinding(){
            location.reload();
        },
        submit(){
            axios.post('/inventory_count/' + this.$props.inventory_count.id).then(response => {
                location = '/inventory_count';
            }).catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error.response.data.message,
                });
            })
        },
        cancel(){
            Swal.fire({
                title: 'Are you sure?',
                text: "Unsaved data would be lost.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm'
            }).then((result) => {
                console.log(result, !result.isConfirmed);
                if (result.isConfirmed) {
                    location = '/inventory_count'
                }
            })
        },
        save(){
            this.$refs.inventoryCountTable.saveAll();
        }
    }
}
</script>

<style scoped>

</style>
