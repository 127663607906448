<template>
    <div>
        <form @submit.prevent="submit" name="store_order">
            <div class="row justify-content-center">
                <div class="col-md-10 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-header">
                            <div class="card-title"><p>Warehouse Information</p></div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="col-md-8 ml-0">
                                        <div class="form-group">
                                            <label for="cargo_loader">Picker: <span class="required_asterisk">*</span></label>
                                            <select-box id="picker" name="picker" :options="pickers" v-model="form.picker_id" ></select-box>
                                        </div>
                                    </div>
                                    <div class="col-md-8 ml-0">
                                        <div class="form-group">
                                            <label for="cargo_loader">Cargo Loader: <span class="required_asterisk">*</span></label>
                                            <select-box id="cargo_loader" name="cargo_loader" :options="cargo_loaders" v-model="form.cargo_loader_id" ></select-box>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <!--                                    <div class="form-group">-->
                                    <!--                                        <label for="priority">Priority<span class="required_asterisk">*</span></label>-->
                                    <!--                                        <dropbox id="priority" name="priority" :options="priorities" v-model="form.priority_id" ></dropbox>-->
                                    <!--                                    </div>-->
                                    <div class="form-group">
                                        <label for="dock">Store Dock Location</label>
                                        <p>{{store_order.store.name}}</p>
<!--                                        <dropbox-none id="dock" name="dock" :options="docks" v-model="form.waiting_dock_id" ></dropbox-none>-->
<!--                                    </div>-->
                                    <!--                                    <div class="form-group">-->
                                    <!--                                        <label for="priority">Dispatch Door</label>-->
                                    <!--                                        <dropbox-none id="door" name="door" :options="doors" v-model="form.warehouse_door_id" ></dropbox-none>-->
                                    <!--                                    </div>-->
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <button class="btn btn-primary">Submit</button>
                                    <a href="/store_orders/assignment" class="btn btn-light">Cancel</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
    name: "ProjectAssignmentForm",
    props: ['store_order','cargo_loaders','pickers','has_picking_completed'],
    data(){
        return{
            form:{
                // warehouses: Object.values(this.$props.warehouses),
                // priority_id: this.$props.store_order.priority_id,
                // warehouse_door_id: this.$props.store_order.warehouse_door_id,
                // waiting_dock_id: this.$props.store_order.waiting_dock_id,
                cargo_loader_id: this.$props.store_order.cargo_loader_id,
                picker_id: this.$props.store_order.picker_id,
            }
        }
    },
    mounted(){
        $("form[name='store_order']").validate({
            ignore: [],
            rules: {
                // priority: {
                //     required: true,
                // },
                cargo_loader:{
                    required: true
                },
                picker:{
                    required: true
                }
            },
            errorPlacement: function(label, element) {

                if(['cargo_loader_id'].includes(element[0].name)){
                    label.addClass('mt-2 text-danger');
                    label.insertAfter(element.next());
                }else{
                    label.addClass('mt-2 text-danger');
                    label.insertAfter(element);
                }
            },
            highlight: function(element, errorClass) {
                $(element).parent().addClass('has-danger')
                $(element).addClass('form-control-danger')
            },
            unhighlight: function(element, errorClass, validClass) {
                $(element).parent().removeClass('has-danger')
                $(element).removeClass('form-control-danger')
            },
            submitHandler: function(form){
            }
        });
    },
    methods:{
        submit(){
            // let meetsPickerValidation = true;
            // let confirmDockandDoor = true;
            // let priorityConfirm = true;

            // this.form.warehouses.forEach(warehouse =>{
            //     if(warehouse.pickers.length <= 0){
            //         meetsPickerValidation = false;
            //     }
            // })

            // if(this.form.waiting_dock_id == null || this.form.waiting_dock_id == 'null') {
            //     confirmDockandDoor = false
            // }
            // if(this.form.waiting_dock_id == 'null' && this.form.warehouse_door_id == 'null') {
            //     confirmDockandDoor = false
            // }
            // if(this.form.waiting_dock_id == 'null' && this.form.warehouse_door_id == null) {
            //     confirmDockandDoor = false
            // }
            // if(this.form.waiting_dock_id == null && this.form.warehouse_door_id == 'null') {
            //     confirmDockandDoor = false
            // }
            // if(this.form.priority == 'null') {
            //     priorityConfirm = false
            // }

            // if(meetsPickerValidation && confirmDockandDoor){

                if(this.$props.has_picking_completed){
                    const swalWithBootstrapButtons = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn btn-success',
                            cancelButton: 'btn btn-danger'
                        },
                        buttonsStyling: false,
                    });

                    swalWithBootstrapButtons.fire({
                        title: 'Are you sure?',
                        text: "If any inventory was already picked will be returned to inventory and will have to be picked.",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonClass: 'ml-2',
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'Cancel',
                        reverseButtons: true
                    }).then((result) => {
                        if (result.value) {

                            axios.patch('/store_orders/' + this.$props.store_order.id + '/assignment',this.form).then(response =>{
                                if(response.data.error == 422){
                                    Swal.fire({
                                        icon: 'error',
                                        title: 'Oops...',
                                        text: response.data.message,
                                    });
                                }else{
                                    location = response.data.message;
                                }
                            }).catch(error =>{
                                if(error.response.status === 403){
                                    Swal.fire({
                                        icon: 'error',
                                        title: 'Oops...',
                                        text: 'There was a problem.',
                                    });
                                }
                            });
                        }
                    });
                }else{
                    axios.patch('/store_orders/' + this.$props.store_order.id + '/assignment',this.form).then(response =>{
                        if(response.data.error == 422){
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: response.data.message,
                            });
                        }else{
                            location = response.data.message;
                        }
                    }).catch(error =>{
                        if(error.response.status === 422){
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Fields required',
                            });
                        }
                    });
                }
            // }else if(!meetsPickerValidation){
            //     Swal.fire({
            //         icon: 'error',
            //         title: 'Oops...',
            //         text: 'You need to select at least one picker per warehouse.',
            //     });
            // }else if(!confirmDockandDoor){
            //     Swal.fire({
            //         icon: 'error',
            //         title: 'Oops...',
            //         text: 'You must select a Dispatch door or Dock to continue',
            //     });
            // }
            // else if(!priorityConfirm){
            //     Swal.fire({
            //         icon: 'error',
            //         title: 'Oops...',
            //         text: 'Select a priority',
            //     });
            // }
        }
    }
}
</script>

<style scoped>

</style>
