<template>
    <div>
        <form @submit.prevent="submit" id="receiving-form" name="receiving-form" :ref="'receiving-form'">
            <div class="col-md-8 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h6 class="card-title">Receiving PO</h6>
                        <div class="row">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-lg-4 col-xl-3">
                                        <p class="mr-4 mb-2"><b>PO #:</b></p>
                                    </div>
                                    <div class="col-lg-8 col-xl-9 col-md-3 ml-2 ml-lg-0">
                                        <p class="mb-2">{{purchase_order.po_number}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-4 col-xl-3">
                                        <p class="mr-4 mb-2"><b>Supplier:</b></p>
                                    </div>
                                    <div class="col-lg-8 col-xl-9 ml-2 ml-lg-0">
                                        <p class="mb-2">{{purchase_order.supplier.name}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-4 col-xl-3">
                                        <p class="mr-4 mb-2"><b>Quote #:</b></p>
                                    </div>
                                    <div class="col-lg-8 col-xl-9 ml-2 ml-lg-0">
                                        <p class="mb-2">{{purchase_order.quote}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-4 col-xl-3">
                                        <p class="mr-4 mb-2"><b>Comments:</b></p>
                                    </div>
                                    <div class="col-lg-8 col-xl-9 col-sm-3 ml-2 ml-lg-0">
                                        <p class="mb-2">{{purchase_order.comments}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-4 col-xl-3">
                                        <p class="mr-4 mb-2"><b>Documentation:</b></p>
                                    </div>
                                    <div class="col-lg-8 col-xl-9 ml-2 ml-lg-0">
                                        <div v-for="(document,index) in purchase_order.documents">
                                            <a :href="'/orders/'+purchase_order.id+'/documents/'+document.id" target="_blank">{{document.type}}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="col-md-6">
                                <h6 class="card-title">Order Details</h6>
                            </div>
                            <div class="col-md-6 pl-0">
                                <h6 class="card-title">Receiving Details</h6>
                            </div>
                        </div>
                        <div class="row mb-3 border-bottom" v-for="(tool,index) in this.form.tools">
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-xl-5">
                                        <p class="mr-4 mb-2"><b>Category: </b></p>
                                    </div>
                                    <div class="col-xl-7 ml-2 ml-xl-0">
                                        <p class="mb-2">{{tool.category.name}}</p>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-xl-5">
                                        <p class="mr-4 mb-2"><b>Description: </b></p>
                                    </div>
                                    <div class=" col-xl-7 ml-2 ml-xl-0">
                                        <p class="mb-2">{{tool.description}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-5">
                                        <p class="mr-4 mb-2"><b>Brand Name:  </b></p>
                                    </div>
                                    <div class="col-xl-7 ml-2 ml-xl-0">
                                        <p class="mb-2">{{tool.brand.name}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-5">
                                        <p class="mr-4 mb-2"><b>Model:  </b></p>
                                    </div>
                                    <div class="col-xl-7 ml-2 ml-xl-0">
                                        <p class="mb-2">{{tool.model}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-5">
                                        <p class="mr-4 mb-2"><b>Order Qty. </b></p>
                                    </div>
                                    <div class="col-xl-7 ml-2 ml-xl-0">
                                        <p class="mb-2">{{tool.order_qty}}</p>
                                    </div>
                                </div>
                                <div class="row" v-if="tool.remaining_qty > 0">
                                    <div class="col-xl-5">
                                        <p class="mr-4 mb-2"><b>Remaining Qty. </b></p>
                                    </div>
                                    <div class="col-xl-7 ml-2 ml-xl-0">
                                        <p class="mb-2">{{tool.remaining_qty}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="form-group">
                                        <label for="order_qty">Qty Received<span class="required_asterisk">*</span></label>
                                        <input type="number" oninput="validity.valid||(value='')" min="0" class="form-control" name="tool" id="order_qty" placeholder="####" v-model="tool.qty_received">
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="form-check form-check-flat form-check-primary">
                                        <label class="form-check-label">
                                            <input type="checkbox" class="form-check-input" v-model="tool.same_warehouse" >
                                            Same Warehouse?
                                        </label>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="form-check form-check-flat form-check-primary">
                                        <label class="form-check-label">
                                            <input type="checkbox" class="form-check-input" v-model="tool.scan_serials">
                                            Scan Serial Numbers?
                                        </label>
                                    </div>
                                </div>
                                <div class="row">
                                    <h6 class="card-title">Warehouse Allocation</h6>
                                </div>
                                <div class="mb-3">
                                    <warehouse-allocation-component :index="index" :parent="tool" :type="'tool'" :zones="zones" :locations="locations"></warehouse-allocation-component>
                                </div>
                            </div>
<!--                            <serial-codes-modal :product="tool" :index="index" :quantity="tool.qty_received"></serial-codes-modal>-->
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="row">
                                    <h6 class="card-title">Additional Information</h6>
                                </div>
                                <div class="form-group">
                                    <label for="received_date">Date Received<span class="required_asterisk">*</span></label>
                                    <div class="input-group date datepicker dashboard-date mr-2 mb-2 mb-md-0 d-xl-flex">
                                        <span class="input-group-addon bg-transparent"><i data-feather="calendar" class=" text-primary"></i></span>
                                        <datePicker autocomplete="off" v-model="form.received_date" :config="datePickerOptions" name="received_date"></datePicker>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="invoice_number">Invoice Number</label>
                                    <input type="text" class="form-control" name="invoice_number" id="invoice_number" placeholder="####" v-model="form.invoice_number">
                                </div>
                                <div class="form-group">
                                    <label for="comments">Comments</label>
                                    <textarea class="form-control" id="comments" name="comments" placeholder="Comments" rows="5" v-model="form.comments"></textarea>
                                </div>
                                <div class="form-group">
                                    <label for="documents">Documentation</label>
                                    <input type="file" multiple  class="border" name="documents" id="documents" ref="documents">
                                </div>
                            </div>
                        </div>

                        <button type="submit" class="btn btn-primary mr-2">Submit</button>
                        <a href="/orders" class="btn btn-light">Cancel</a>
                    </div>
                </div>
            </div>
        </form>

    </div>
</template>

<script>
    import 'bootstrap-datepicker';
    import datePicker from 'vue-bootstrap-datetimepicker';
    import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
    import Swal from 'sweetalert2';
    import { EditIcon} from 'vue-feather-icons';
    import 'dropify';
    import 'dropify/dist/css/dropify.min.css'
    import moment from 'moment';

    export default {
        name: "POReceiveToolForm",
        props:['order','warehouses','zones','locations'],
        components:{
            EditIcon,
            datePicker
        },
        mounted() {
            $('#documents').dropify();
            console.log(this.$props.order)
            this.$props.order.tools.forEach(tool =>{

                let zone_options = [];
                let locations_options = [];
                let zone_id = tool[0]

                // if(tool.warehouse_id !== "" && tool.warehouse_id !== null){
                //
                //     zone_options = this.$props.warehouses.find(warehouse => warehouse.id == tool.warehouse_id).zones
                //
                //     if(tool.zones.length > 0){
                //         location_options = this.$props.warehouses.find(warehouse => warehouse.id == tool.warehouse_id).zones.find(zone => zone.id == tool.zones[0].id).locations
                //     }
                // }
                let receiving = this.$props.order.receivings.find( receiving => tool.id == receiving.item_id);
                let remaining_qty = tool.pivot.quantity;

                if(receiving){
                    remaining_qty = tool.pivot.quantity - receiving.qty_received;
                }



                this.form.tools.push({
                    id: tool.id,
                    category: tool.category,
                    brand: tool.brand,
                    model: tool.model,
                    description: tool.description,
                    order_qty: tool.pivot.quantity,
                    remaining_qty: remaining_qty,
                    qty_received: "",
                    serial_codes: [],
                    zones_options: [],
                    locations_options: [],
                    name: tool.description,
                    sku: tool.brand.name,
                    warehouses_allocation:[],
                    warehouses: tool.warehouses,
                    warehouse_id:"",
                    zone_id: "",
                    location_id: "",
                    scan_serials:true,
                    same_warehouse:true
                })
            });


        },
        data(){
            return{
                form:{
                    tools: [],
                    received_date: moment().format('DD/MM/YYYY'),
                    invoice_number: "",
                    comments: this.$props.order.comments,
                    documents: []
                },
                purchase_order: this.$props.order,
                datePickerOptions:{
                    format: 'DD/MM/YYYY',
                    useCurrent: false,
                    maxDate: new Date()
                },
                moment: moment
            }
        },
        methods:{
            submit(){
                let validSerials = true;
                let validQtyReceived = true;
                let diffQty = false;
                let validWarehouseAmount = true;
                let validWarehouseFill = true;


                this.form.tools.forEach(tool=>{
                    if(tool.serial_codes.length > 0 && tool.qty_received !== tool.serial_codes.length.toString()){
                        validSerials = false;
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'The tool '+tool.model + ' (' + tool.brand + ') does not have enough serials.',
                        });
                    }else if(tool.qty_received === '' || tool.qty_received === null){
                        validQtyReceived = false;
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Input the quantity received for the tool '+tool.model + ' (' + tool.brand + ').',
                        });
                    }else if (tool.qty_received != tool.order_qty){
                        diffQty = true
                    }else{
                        let amount = 0;
                        if(tool.same_warehouse == false && tool.scan_serials == false){
                            tool.warehouses_allocation.forEach(warehouse =>{
                                amount += parseInt(warehouse.qty,10);

                                if(warehouse.warehouse_id == "" || warehouse.warehouse_id == null){
                                    validWarehouseFill = false;
                                    Swal.fire({
                                        icon: 'error',
                                        title: 'Oops...',
                                        text: 'You must select a warehouse for every allocation.',
                                    });
                                }
                            })

                            if(amount != tool.qty_received){
                                validWarehouseAmount = false;
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: 'Warehouse Allocation quantities does not match with Qty. Received '+tool.description + '.',
                                });
                            }
                        }else{
                            if(tool.warehouse_id == "" || tool.warehouse_id == null){
                                validWarehouseFill = false;
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: 'You must select a warehouse.',
                                });
                            }
                        }
                    }
                })
                if((this.form.comments === null || this.form.comments === '') &&  diffQty){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Comment is required if the received quantity is different from the ordered quantity',
                    });
                }

                else if(validSerials && validQtyReceived && validWarehouseAmount && validWarehouseFill){
                    let attributes = this.form
                    let formData = new FormData();
                    for(let i = 0; i < this.$refs.documents.files.length; i++){
                        console.log(this.$refs.documents.files[i]);
                        formData.append('files['+i+']',this.$refs.documents.files[i])
                    }

                    axios.post('/orders/' + this.purchase_order.id + '/receive/store',attributes).then(response => {
                        let id = response.data.message;
                        axios.post('/orders/'+id+'/documents',formData).then(response =>{
                            location = response.data.message
                        });
                    }).catch(error=>{
                        if(error.response.status === 403){
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'This PO was already added o the inventory',
                            });
                        }
                    });
                }
            },
            loadZones(warehouse_id,tool){
                tool.zones_options = this.$props.warehouses.find(warehouse => warehouse.id == warehouse_id).zones
            },
            loadLocations(warehouse_id,zone_id,tool){
                if(!!zone_id){
                    tool.location_options = this.$props.warehouses.find(warehouse => warehouse.id == warehouse_id).zones.find(zone => zone.id == zone_id).locations
                }
            }
        }
    }
</script>

<style scoped>

</style>
