<template>
    <div>
        <form @submit.prevent="submit" id="warehouse-form" name="warehouse-form" :ref="'warehouse-form'">
            <div class="row">
                <div class="col-md-6 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <h6 class="card-title">New Warehouse</h6>
                            <div class="form-group">
                                <label for="warehouse_id">Warehouse ID<span class="required_asterisk">*</span></label>
                                <input type="text" class="form-control" name="warehouse_id" id="warehouse_id" placeholder="Warehouse ID" value="id" v-model="form.warehouse_id">
                            </div>
                            <div class="form-group">
                                <label for="name">Warehouse Name<span class="required_asterisk">*</span></label>
                                <input type="text" class="form-control" name="name" id="name" placeholder="Warehouse Name" value="name" v-model="form.name">
                            </div>
                            <div class="form-group">
                                <label for="address">Address</label>
                                <input type="text" class="form-control" name="address" id="address" placeholder="Address" value="address" v-model="form.address">
                            </div>
                            <div class="form-group">
                                <label for="supervisor_id">Warehouse Supervisor</label>
                                <select-box name="supervisor_id" id="supervisor_id" :options="this.supervisors" v-model="form.supervisor_id">
                                    <option></option>
                                </select-box>
                            </div>
                            <div class="form-group">
                                <label for="description">Description</label>
                                <textarea class="form-control" id="description" name="description" placeholder="Description" rows="5" v-model="form.description">Description</textarea>
                            </div>
                            <br>
                            <br>
                            <div class="row align-items-center mb-2">
                                <h6 data-toggle="tooltip" data-placement="top" title="Click the plus sign to add doors" class="card-title mb-0">Add Doors</h6>
                                <div v-on:click="addDoor">
                                    <i class="link-icon ml-3" data-feather="plus-square" data-toggle="tooltip" data-placement="top" title="Click to add doors" style="cursor: pointer; width: 24px; height: 24px; fill: none; color:green"></i>
                                </div>

                            </div>

                            <div class="form-group">
                                <label for="doors">Doors</label>
                                <div class="row">
                                    <div v-for="(door,index) in form.doors" class="col-md-6 mb-3">
                                        <div class="align-items-center" style="display: flex; flex-wrap: wrap">
                                            <div class="mr-0 w-75">
                                                <input type="text" class="form-control" style="cursor: text;" v-model="door.name">
                                            </div>
                                            <div class="ml-1" style="height: 100%; cursor: pointer;" v-on:click="deleteDoor(index)">
                                                <x-circle-icon size="1.5x" style="color: red"></x-circle-icon>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-primary mr-2">Submit</button>
                            <a href="/masterdata/warehouses" class="btn btn-light">Cancel</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row align-items-center mb-3 justify-content-between">
                        <h6 class="card-title mb-0">Add Areas & Aisles</h6>
                        <button type="button" v-on:click="addArea" class="btn btn-primary ml-2 mt-0">Add New Area</button>
                    </div>
                    <div  style="overflow: auto; overflow-x: hidden; height: 80vh" :ref="'areasDiv'">
                        <div v-for="(area,index) in form.areas" >
                            <div class="row card" :ref="'areaInfo' + index" style="display: none">
                                <div class="card-body">
                                    <div v-on:click="hideAreaInfo(index)" class="row justify-content-end pr-3">
                                        <chevron-up-icon style="cursor: pointer" size="1.5x"></chevron-up-icon>
                                    </div>
                                    <div class="form-group">
                                        <label :for="'area'+index">Area</label>
                                        <input type="text" class="form-control" :name="'area'+index" :id="'area'+index" placeholder="Area" v-model="area.name">
                                    </div>
                                    <div v-for="(zone,zone_index) in area.zones">
                                        <hr>
                                        <div class="form-group px-4">
                                            <label :for="'aisle'+zone_index">Zone {{zone_index + 1}}</label>
                                            <div class="row align-items-center align-middle">
                                                <div class="col-sm-6">
                                                    <label for="zone_name">Zone Name</label>
                                                    <input type="text" class="form-control" :name="'aisle'+zone_index" :id="'aisle'+zone_index" placeholder="Aisle" v-model="zone.name">
                                                </div>
                                                <div class="col-sm-5">
                                                        <label for="zone_type">Zone Type</label>
                                                        <dropbox name="battery_type" id="battery_type" :options="zone_types" v-model="zone.type">
                                                            <option></option>
                                                        </dropbox>
                                                </div>
                                                <div class="col-sm-1 align-middle h-100" v-on:click="deleteZone(index,zone_index)" style="cursor: pointer">
                                                    <x-circle-icon size="1.5x" style="color: red"></x-circle-icon>
                                                </div>
                                            </div>
                                        </div>
                                        <p style="color:blue; cursor: pointer"  @click="$modal.show(index + '-' + zone_index +'-location-modal')">Edit Locations</p>
                                        <locations-modal :zone="zone" :zone_index="zone_index" :index="index"></locations-modal>
                                    </div>
                                    <hr>
                                    <button type="button" v-on:click="addZone(index)" class="btn btn-primary">Add New Zone</button>
                                    <button type="button" v-on:click="deleteArea(index)" class="btn btn-danger">Delete Area</button>
                                </div>
                            </div>
                            <div class="row" :ref="'areaCard' + index">
                                <div style="cursor: pointer" v-on:click="showAreaInfo(index)" class="card w-100">
                                    <div class="card-body">
                                        <div class="row justify-content-between align-items-center px-3">
                                            <div>
                                                <div class="card-title">{{area.name}}</div>
                                                <p>Zones: {{area.zones.length}}</p>
                                            </div>
                                            <chevron-down-icon size="1.5x"></chevron-down-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <!--    profile="{{$profile->id}}"-->
</template>

<script>
    import 'jquery-validation'
    import { XCircleIcon, ChevronDownIcon, ChevronUpIcon} from 'vue-feather-icons';
    import {LogInIcon, LogOutIcon} from 'vue-feather-icons';
    import Swal from "sweetalert2";
    export default {
        name: "WarehouseCreateForm",
        components:{
            XCircleIcon, ChevronDownIcon, ChevronUpIcon
        },
        mounted(){
            $("form[name='warehouse-form']").validate({
                rules: {
                    warehouse_id: {
                        required: true,
                    },
                    name: {
                        required: true,
                        minlength: 3
                    },
                },
                errorPlacement: function(label, element) {
                    label.addClass('mt-2 text-danger');
                    label.insertAfter(element);
                },
                highlight: function(element, errorClass) {
                    $(element).parent().addClass('has-danger')
                    $(element).addClass('form-control-danger')
                },
                unhighlight: function(element, errorClass, validClass) {
                    $(element).parent().removeClass('has-danger')
                    $(element).removeClass('form-control-danger')
                },
                submitHandler: function(form){
                    this.submit()
                }
            });
        },
        props: ['supervisors'],
        data(){
            return {
                form:{
                    warehouse_id: "",
                    name: "",
                    address: "",
                    supervisor_id: "",
                    description: "",
                    areas: [],
                    doors: [],
                },
                zone_types: [{id:'Rack',name:'Rack'}, {id:'Line',name:'Line'},{id:'Mezzanine',name:'Mezzanine'}]
            }
        },
        methods: {
            addArea(){
                this.form.areas.push({"name": 'Area ' + (this.form.areas.length + 1), "zones": []});
            },
            deleteArea(index){
                let area = this.form.areas[index];

                this.form.areas.splice(index,1);
            },
            addZone(index){
                this.form.areas[index].zones.push({"name":"",locations:[]});
            },
            deleteZone(areaIndex,zoneIndex){
                let area = this.form.areas[areaIndex];
                let zone = this.form.areas[areaIndex].zones[zoneIndex];

                this.form.areas[areaIndex].zones.splice(zoneIndex,1);
            },
            addDoor(){
                this.form.doors.push({"name":""});
            },
            deleteDoor(index){
                this.form.doors.splice(index,1);
            },
            hasRepeatedName(listOfNames){
                return listOfNames.some(
                    (val, i) => listOfNames.indexOf(val) !== i
                )
            },
            fireErrorAlert(title,message){
                Swal.fire({
                    icon: 'error',
                    title: title,
                    text: message,
                });
            },
            submit(){
                let attributes = this.form

                for(let areaIndex = 0; areaIndex < attributes.areas.length; areaIndex++){
                    let area = attributes.areas[areaIndex];
                    if(this.hasRepeatedName(area.zones.map(zone => zone.name))){
                        let title = "Same Zone Name";
                        let message = "Please change repeated zone name inside. Area: " +
                            area.name + ".";

                        this.fireErrorAlert(title, message)

                        return true;
                    }else{
                        for(let zoneIndex = 0; zoneIndex < area.zones.length; zoneIndex++){
                            let zone = area.zones[zoneIndex];

                            if(this.hasRepeatedName(zone.locations.map(location => location.name))){
                                let title = "Same Location Name";
                                let message = "Please change repeated location name inside. Area: " +
                                    area.name + ", Zone: " + zone.name +  ".";

                                this.fireErrorAlert(title, message)

                                return true;
                            }else if(zone.type == null || zone.type === ""){
                                let title = "Zone type is required."
                                let message = "Please select a zone type for Zone " + zone.name + " inside Area: " + area.name + "."

                                this.fireErrorAlert(title, message);

                                return true;
                            }else{
                                for(let locationIndex = 0; locationIndex < zone.locations.length; locationIndex++){
                                    let location = zone.locations[locationIndex]
                                    if(this.hasRepeatedName(location.levels.map(level => level.name))){
                                        let title = "Same Level Name";
                                        let message = "Please change repeated level name inside. Area: " +
                                            area.name + ", Zone: " + zone.name + ", Location: " + location.name + ".";

                                        this.fireErrorAlert(title, message);

                                        return true;
                                    }
                                }
                            }
                        }
                    }
                }

                let areaNames = attributes.areas.map(areas => areas.name);

                let doesHaveDuplicate = this.hasRepeatedName(areaNames)

                if (doesHaveDuplicate) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Same area name',
                        text: 'Please change repeated area name',
                    });

                } else {
                    axios.post('/masterdata/warehouses/',attributes).then(response => {
                        if(response.status === 200){
                            location = response.data.message
                        }
                    }).catch(error=>{
                        if(error.response){
                            console.log(error.response)
                            let validationErrors = [];

                            if(error.response.status === 422){
                                validationErrors = error.response.data.errors;
                                if(validationErrors['warehouse_id']){
                                    Swal.fire({
                                        icon: 'error',
                                        title: 'Oops...',
                                        text: validationErrors['warehouse_id'][0]
                                    })
                                }
                            }else{
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: error,
                                });
                            }
                        }
                    });
                }
            },
            setSupervisor(){
                console.log(this.$refs)
            },
            showAreaInfo(index){
                let areasDivChilds = this.$refs.areasDiv.children;

                for (let i = 0; i < areasDivChilds.length; i++){
                    areasDivChilds[i].children[0].style.display = 'none';
                    areasDivChilds[i].children[1].style.display = 'block';
                }

                this.$refs['areaCard'+index][0].style.display = 'none';
                this.$refs['areaInfo'+index][0].style.display = 'block';
            },
            hideAreaInfo(index){
                this.$refs['areaCard'+index][0].style.display = 'block';
                this.$refs['areaInfo'+index][0].style.display = 'none';
            },
            editLocations(index){

            }
        }
    }

</script>

<style scoped>

</style>
