<template>
    <div>
        <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
                <div class="card-body">
                    <div class="card-title">
                        New Inventory Count
                    </div>
                    <div class="my-2">
                        <div class="row justify-content-between align-items-end mt-3">
                            <div>
                                <label for="paginate">Show</label>
                                <dropbox name="paginate"
                                         id="paginate"
                                         :options="pagination_options"
                                         v-model="pagination"
                                         class="custom-select custom-select-sm form-control"/>
                            </div>
                            <div class="d-flex align-items-end">
                                <div class="mr-2">
                                    <label for="filter">Search</label>
                                    <input type="text"
                                           autocomplete="off"
                                           class="form-control"
                                           id="filter"
                                           name="filter"
                                           v-model="filter"
                                           placeholder="Search"
                                    />
                                </div>
                                <div>
                                    <button type="button" class="btn btn-primary " v-on:click="getData(path + '?' + getFilter())">Filter</button>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive pt-3">
                            <table class="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>Location</th>
                                        <th>LPN</th>
                                        <th>SKU</th>
                                        <th>UPC</th>
                                        <th>Description</th>
                                        <th>Count</th>
                                        <th>UOM</th>
                                        <th>Action</th>
                                        <th>User Id</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(license_plate, index) in filtered_license_plates" :class="!license_plate.count_data.id ? '' : 'bg-light'">
                                        <td>{{license_plate.level_label}}</td>
                                        <td>{{license_plate.lpn}}</td>
                                        <td>{{license_plate.sku}}</td>
                                        <td style="min-width: 180px; width: 180px;">
                                            <input  v-if="license_plate.sku != null"
                                                    style="min-width: 180px; width: 180px;"
                                                   type="text"
                                                   :disabled="license_plate.count_data.id"
                                                   class="form-control"
                                                   v-model="license_plate.count_data.upc"
                                                   v-on:change="() => delaySaveCountData(license_plate, index, 2000)"
                                                   placeholder="UPC"/>
                                        </td>
                                        <td>{{license_plate.description}}</td>
                                        <td style="min-width: 100px; width: 100px;">
                                            <input v-if="license_plate.sku != null"
                                                   style="min-width: 100px; width: 100px;"
                                                   type="number"
                                                   min="0"
                                                   :disabled="license_plate.count_data.id"
                                                   class="form-control"
                                                   v-on:change="() => delaySaveCountData(license_plate, index, 2000)"
                                                   v-model="license_plate.count_data.count"
                                                   placeholder="Count"/>
                                        </td>
                                        <td style="min-width: 80px; width: 80px;" v-if="license_plate.count_data.id == null">
                                            <div v-if="license_plate.count_data.id == null">
                                                {{license_plate.count_data.id}}
                                                <dropbox v-if="license_plate.sku != null "
                                                         style="min-width: 80px; width: 80px;"
                                                         :disabled="license_plate.count_data.id"
                                                         :options="license_plate.count_data.measurement_options"
                                                         :key="index"
                                                         v-on:change="() => delaySaveCountData(license_plate, index, 2000)"
                                                         @optionsCallback="setMeasurementUnit"
                                                         v-model="license_plate.count_data.measurement_unit_id">
                                                </dropbox>
                                            </div>
                                        </td>
                                        <td v-else>

<!--                                            Mejorar no hacer loop 2 veces-->
                                            {{license_plate.count_data.measurement_options.find(mo => mo.id == license_plate.count_data.temp_measurement_unit_id) != null ? license_plate.count_data.measurement_options.find(mo => mo.id == license_plate.count_data.temp_measurement_unit_id).name : ''}}
                                        </td>
                                        <td style="min-width: 80px; width: 80px;">
                                            <button v-if="license_plate.sku != null" :disabled="!license_plate.count_data.id" type="button" v-on:click="selectEdit(license_plate)" class="btn btn-primary">Edit</button>
                                            <button style="background-color: #b6eb42" :disabled="license_plate.count_data.id" v-if="license_plate.sku != null" type="button" class="btn btn-primary" v-on:click="saveCount(license_plate, index)">Save</button>
                                        </td>
                                        <td>
                                            {{license_plate.count_data.user != null ? license_plate.count_data.user.email : ''}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="d-flex justify-content-between mt-3">
                            <div>
                                Displaying {{filtered_license_plates.length}} of {{total}} location(s).
                            </div>
                            <div>
                                <nav>
                                    <ul class="pagination">
                                        <li :class="prevButtonClass">
                                            <span v-if="prev_page_url == '' || prev_page_url == null" class="page-link">{{'<'}}</span>
                                            <button type="button" v-else class="page-link" v-on:click="getData(prev_page_url)">{{'<'}}</button>
                                        </li>
                                        <li v-for="(button,index) in page_buttons" :class="button.class">
                                            <span v-if="button.isActualPage" class="page-link">{{button.pageNumber}}</span>
                                            <span v-else-if="button.disabledButton" class="page-link">...</span>
                                            <button type="button" v-else class="page-link" v-on:click="getData(button.path)">{{button.pageNumber}}</button>
                                        </li>
                                        <li :class="nextButtonClass">
                                            <span v-if="next_page_url == '' || next_page_url == null" class="page-link">{{'>'}}</span>
                                            <button type="button" v-else class="page-link" v-on:click="getData(next_page_url)">{{'>'}}</button>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <inventory-count-table-edit-modal :count="selected_edit" :inventory_count="inventory_count" @deleteCount="deleteCount"/>
    </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
    name: "InventoryCountTable",
    props:{
        inventory_count: Object
    },
    mounted(){
        console.log("Hello there", this.$props.inventory_count.last_page);

        if(this.$props.inventory_count.last_page != null){
            this.getData(this.path + '?page=' + this.$props.inventory_count.last_page);
        }else{
            this.getData(this.path);
        }

    },
    data(){
        return {
            count_temp_timers: {},
            pagination_options:[{id: "10", name:"10"}, {id: "30", name:"30"}, {id: "50", name:"50"}],
            filter: "",
            license_plate_count: 0,
            current_page: 1,
            first_page_url: "",
            last_page: 1,
            last_page_url: "",
            next_page_url: "",
            path: "/inventory_count/" + this.$props.inventory_count.id + "/license_plate_data",
            per_page: 1,
            to: 1,
            total: 1,
            pagination: 10,
            prevButtonClass: 'page-item ' + (this.prev_page_url !== null && this.prev_page_url !== '' ? '' : 'disabled'),
            nextButtonClass: 'page-item ' + (this.next_page_url !== null && this.next_page_url !== '' ? '' : 'disabled'),
            page_buttons: [],
            filtered_license_plates: [],
            from: '',
            selected_edit: {
                level_label: '',
                license_plate: {id: '', lpn: ''},
                product: {sku: '', upc: '', description: '', measurement_units: []},
                user: {email: ''},
                count_data: {
                    id: '',
                    upc: '',
                    count: '',
                    measurement_unit_id: '',
                    temp_measurement_unit_id: '',
                    user: '',
                    measurement_options: []
                }
            }
        }
    },
    methods: {
        setMeasurementUnit(){
            //TODO: This is running 10 times find a better way
            // this.filtered_license_plates.forEach((lp,index) =>{
            //     if(lp.measurement_unit_id == null){
            //         this.filtered_license_plates[index]['measurement_unit_id'] = lp.temp_measurement_unit_id;
            //     }
            //     console.log("Is this working", lp.measurement_unit_id, lp.temp_measurement_unit_id)
            // });
        },
        selectEdit(license_plate){
            this.selected_edit = license_plate;
            if(license_plate.count_data.id){
                this.$modal.show('count-edit-modal');
            }
        },
        getData(path) {
            axios.get(path).then(response => {
                console.log(response);
                this.setLicensePlatePaginationData(response.data)
            });
        },
        resetFilter() {
            this.filter = '';
            this.getData(this.path + '?' + this.getFilter());
        },
        setLicensePlatePaginationData(data) {
            let filterPath = this.getFilter();

            if (filterPath === '') {
                filterPath = filterPath + '&';
            }



            this.filtered_license_plates =  data.license_plates.data.map(lp => {
                lp['count_data'] = {
                    upc: null,
                    count: null,
                    measurement_unit_id: null,
                    user: null,
                    measurement_options: []
                }

                return lp;
            })
            this.current_page = data.license_plates.current_page;
            this.first_page_url = data.license_plates.first_page_url;
            this.from = data.license_plates.from;
            this.last_page = data.license_plates.last_page;
            this.last_page_url = data.license_plates.last_page_url == null ? null : this.path + '?' + filterPath + 'page=' + (this.current_page + 1);
            this.next_page_url = data.license_plates.next_page_url;
            this.per_page = data.license_plates.per_page;
            this.prev_page_url = data.license_plates.prev_page_url == null ? null : this.path + '?' + filterPath + "page=" + (this.current_page - 1);
            this.to = data.license_plates.to;
            this.total = data.license_plates.total;
            this.filter = data.filter;
            this.paginate = data.paginate;
            this.prevButtonClass = 'page-item ' + (this.prev_page_url !== null && this.prev_page_url !== '' ? '' : 'disabled');
            this.nextButtonClass = 'page-item ' + (this.next_page_url !== null && this.next_page_url !== '' ? '' : 'disabled');

            this.setButtons();
            this.getCountData();
        },
        getFilter() {
            return (this.filter != null && this.filter !== '' ? 'filter=' + this.filter : '');
        },
        setButtons() {
            this.page_buttons = [];

            console.log(this.last_page, this.current_page);

            if (this.last_page > 13) {
                if (this.current_page < 8) {
                    this.setLeftSideButtons();
                } else if (this.last_page - this.current_page < 7) {
                    this.setRightSideButtons();
                } else {
                    this.setMiddleButtons();
                }
            } else {
                this.setNormalButtons();
            }
        },
        setNormalButtons() {
            for (let i = 1; i <= this.last_page; i++) {
                let button = this.createButton(i);

                this.page_buttons.push(button);
            }
        },
        setLeftSideButtons() {
            for (let i = 1; i <= 10; i++) {
                let button = this.createButton(i);

                this.page_buttons.push(button)
            }

            let button = this.createButton(0, false);
            this.page_buttons.push(button);

            button = this.createButton(this.last_page - 1);
            this.page_buttons.push(button);

            button = this.createButton(this.last_page);
            this.page_buttons.push(button);
        },
        setRightSideButtons() {
            let button = this.createButton(1);
            this.page_buttons.push(button);

            button = this.createButton(2);
            this.page_buttons.push(button);

            button = this.createButton(0, false);
            this.page_buttons.push(button);

            for (let i = this.last_page - 9; i <= this.last_page; i++) {
                button = this.createButton(i);

                this.page_buttons.push(button);
            }
        },
        setMiddleButtons(){
            let button = this.createButton(1);
            this.page_buttons.push(button);

            button = this.createButton(2);
            this.page_buttons.push(button);

            button = this.createButton(0, false);
            this.page_buttons.push(button);

            for(let i = this.current_page -3; i <= this.current_page + 3; i++){
                button = this.createButton(i);

                this.page_buttons.push(button);
            }

            button = this.createButton(0, false);
            this.page_buttons.push(button);

            button = this.createButton(this.last_page - 1);
            this.page_buttons.push(button);

            button = this.createButton(this.last_page);
            this.page_buttons.push(button);
        },
        createButton(pageNumber, normalButton=true){
            let filterPath = this.getFilter();

            if(filterPath !== ''){
                filterPath = filterPath + '&';
            }

            return {
                'path': this.path + '?' + filterPath + 'page=' + pageNumber,
                'pageNumber': pageNumber,
                'isActualPage': this.current_page == pageNumber,
                'class': 'page-item' + (this.current_page == pageNumber ? ' active': '') + (!normalButton ? ' disabled' : ''),
                'disabledButton': !normalButton
            }
        },
        getCountData(){

            this.filtered_license_plates.forEach((license_plate, index) => {
                const license_plate_id = license_plate.license_plate_id;
                const product_id = license_plate.product_id;

                const url = `/inventory_count/${this.$props.inventory_count.id}/license_plate/${license_plate_id}/product/${product_id}/get_count_data`

                axios.get(url).then((response) => {
                    this.filtered_license_plates[index]['count_data'] = {
                        id: response.data.id,
                        upc: response.data.upc ?? license_plate.upc,
                        count: response.data.count,
                        measurement_options: response.data.measurement_options,
                        measurement_unit_id: response.data.measurement_unit_id,
                        temp_measurement_unit_id: response.data.measurement_unit_id,
                        user: response.data.user,
                    }

                    console.log("Count Data: ", this.filtered_license_plates[index]['count_data']);
                })
            });
        },
        saveCount(license_plate, index){
            const data = {
                upc: license_plate.count_data.upc ?? '',
                count: license_plate.count_data.count,
                measurement_unit_id: license_plate.count_data.measurement_unit_id
            }

            let message = [];

            if(data['upc'] != null && data['upc'].length > 16){
                message.push('UPC length maximum is 16 characters');
            }

            if(data['count'] === '' || data['count'] == null) {
                message.push('Count is required');
            }

            if(data['count'] < 0){
                message.push('Quantity can not be a negative value');
            }

            if(data['measurement_unit_id'] === '' || data['measurement_unit_id'] == null){
                message.push('Measurement Unit field is required');
            }

            console.log(message.join());
            if(message.length !== 0){
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Line cannot be saved because: ' + message.join(' and '),
                });

                return;
            }

            const url = `/inventory_count/${this.$props.inventory_count.id}/license_plate/${license_plate.license_plate_id}/product/${license_plate.product_id}/save_count_data`

            if(data.upc == null){
                console.log("Failed")
                return;
            }

            if(data.count == null){
                console.log("Failed");
                return;
            }

            if(data.measurement_unit_id == null){
                console.log("Failed")
                return;
            }

            this.filtered_license_plates[index]['count_data']['temp_measurement_unit_id'] =  this.filtered_license_plates[index]['count_data']['measurement_unit_id'];

            axios.post(url, data).then(response => {
                console.log("Saved");

                this.filtered_license_plates[index]['count_data']['user'] = response.data.user;
                this.filtered_license_plates[index]['count_data']['id'] = response.data.id;

            })
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error.message,
                });

            })
        },
        deleteCount(count){
            let countIndex = null;
            this.filtered_license_plates.forEach((filtered_lp, index) =>{
                if(filtered_lp.count_data.id === count.count_data.id){
                    countIndex = index;

                    return;
                }
            });

            console.log( this.filtered_license_plates[countIndex]);

            this.filtered_license_plates[countIndex]['count_data']['upc'] = '';
            this.filtered_license_plates[countIndex]['count_data']['count'] = '';
            this.filtered_license_plates[countIndex]['count_data']['measurement_unit_id'] = '';
            this.filtered_license_plates[countIndex]['count_data']['user'] = '';
            this.filtered_license_plates[countIndex]['count_data']['id'] = '';

            location.reload();
        },
        addTempCountDataCallback(license_plate, index){
            return () => {
                const data = {
                    upc: license_plate.count_data.upc ?? '',
                    count: license_plate.count_data.count,
                    measurement_unit_id: license_plate.count_data.measurement_unit_id
                }

                const url = `/inventory_count/${this.$props.inventory_count.id}/license_plate/${license_plate.license_plate_id}/product/${license_plate.product_id}/save_temp_count_data`

                this.filtered_license_plates[index]['count_data']['temp_measurement_unit_id'] =  this.filtered_license_plates[index]['count_data']['measurement_unit_id'];

                axios.post(url, data).then(response => {
                    console.log("Saved");
                })
                .catch(error => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.message,
                    });

                })
            }
        },
        delaySaveCountData(license_plate, index, ms){

            clearTimeout(this.count_temp_timers[license_plate.id]);

            console.log("Hey there");

            console.log('hey there', this.count_temp_timers[license_plate.id]);
            this.count_temp_timers[license_plate.id] = setTimeout(this.addTempCountDataCallback(license_plate, index), ms);

        },
        saveAll(){
            this.filtered_license_plates.forEach((lp, index) => {


                const data = lp.count_data;


                if(data.id != null){
                    return;
                }

                if(!data.count){
                    console.log("Failed", lp.license_plate_id, lp.product_id);
                    return;
                }

                if(!data.measurement_unit_id){
                    console.log("Failed", lp.license_plate_id, lp.product_id);
                    return;
                }

                this.filtered_license_plates[index]['count_data']['temp_measurement_unit_id'] =  this.filtered_license_plates[index]['count_data']['measurement_unit_id'];
                const url = `/inventory_count/${this.$props.inventory_count.id}/license_plate/${lp.license_plate_id}/product/${lp.product_id}/save_count_data`

                console.log("Worked", lp.license_plate_id, lp.product_id, !data.count, !data.measurement_unit_id);
                axios.post(url, data).then(response => {
                    console.log("Saved");

                    this.filtered_license_plates[index]['count_data']['user'] = response.data.user;
                    this.filtered_license_plates[index]['count_data']['id'] = response.data.id;

                    location = '/inventory_count'
                })
                .catch(error => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.message,
                    });

                });
            })
        }
    },
}
</script>

<style scoped>

</style>
